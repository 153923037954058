import { Component } from '@angular/core';
import { SiteRecoveryServiceService } from './site-recovery-service/site-recovery-service.service';
import { Subject, takeUntil, throwError } from 'rxjs';
import {PageType} from "../../common/models/user-role/page-type.enum";

export interface SiteData {
  fordSiteCode: string;
  branch: string;
  fordSiteCodeType: string;
  market: string;
  postalAddress: string;
  city: string;
  state: string;
  postalCode: string;
  telephoneNumber: string;
  facsimileTelephoneNumber: string;
  preferredLanguage: string;
  description: string;
  lastUpdatedDate: string;
  fordLastModifiedBy: string;
  structure: string;
  fordSalesCode: [];
  fordParentOrganization: string;
  fordCsdCode: string;
  fordstatus: string
  fmccRegion: string,
  fmccLessor: string,
  fmccBrand: string,
  fmccBCode: string,
  fmccFinLab: string,
  fmccSbCode: string,
  fmccMkt: string,
  fmccpGroup: string,
  fmccGroup: string,
  fmccSt: string,
  fmccClass: string,
  labeledURI:string,
  dealershipName:string
}

@Component({
  selector: 'site-recovery-tool',
  templateUrl: './site-recovery.component.html',
  styleUrls: ['./site-recovery.component.scss']
})

export class SiteRecoveryComponent {
  siteInput: string = '';
  sites: string[] = [];
  private destroy$ = new Subject<void>();
  loading: boolean = false;
  showNoRecords: boolean = false;
  deleteModal: boolean = false;
  selectedDataForDelete: any[] = [];
  sitesDataList: any[] = []
  triggerSearch: boolean = false;
  purgeButton: boolean = false;
  deleteLoading: boolean = false;
  deleteAPIResponse: any[] = [];

  constructor(private siteRecoveryServiceService: SiteRecoveryServiceService) {

  }

  ngOnInit() {
    this.getSelectedRows();
    this.checkSaveChangesStatus();
    this.siteRecoveryServiceService.setSitesData([]);
  }

  disableSearchButton() {
    this.sites = this.siteInput.split(',').map(code => code.trim()).filter(value => value);
    return this.sites.length>0 ? false : true;
  }

  search() {
    if (this.sites.length>0) {
      this.sitesDataList = [];
      this.siteRecoveryServiceService.setSelectedRows([]);
      this.siteRecoveryServiceService.setSitesData(this.sitesDataList);
      this.loading = true;
      const uniqueSiteCodes = Array.from(new Set(this.sites));
      this.siteRecoveryServiceService.searchSiteCode(uniqueSiteCodes).pipe(takeUntil(this.destroy$))
        .subscribe({
          next: data => {
            this.loading = false;
            if (data.siteCodes) {
              this.sitesDataList = data.siteCodes;
              this.siteRecoveryServiceService.setSitesData(this.sitesDataList);
              this.showNoRecords = this.sitesDataList.length === 0 ? true : false;
            } else {
              this.siteRecoveryServiceService.setSitesData([]);
              this.showNoRecords = true;
            }
            this.triggerSearch = false;

          }, error: error => {
            this.siteRecoveryServiceService.setSitesData([]);
            this.loading = false;
            this.showNoRecords = true;
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  getSelectedRows() {
    this.siteRecoveryServiceService.getSelectedRows().pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.selectedDataForDelete = data;
      if (this.selectedDataForDelete.length > 0) {
        this.deleteModal = true;
        this.purgeButton = true;
        this.deleteAPIResponse = [];
      }
    });
  }

  confirmDelete() {
    this.purgeButton = false;
    this.deleteLoading = true;
    const deleteRequestPayload = this.selectedDataForDelete.map(data => data.fordSiteCode);
    this.siteRecoveryServiceService.confirmDeleteRecords(deleteRequestPayload).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.deleteLoading = false;
          this.siteRecoveryServiceService.setSitesData([]);
          this.siteRecoveryServiceService.setSelectedRows([]);
          this.deleteAPIResponse = data;
          this.triggerSearch = true;
        }, error: error => {
          this.deleteAPIResponse = [];
          this.deleteLoading = false;
          this.siteRecoveryServiceService.setSelectedRows([]);
        }
      });

  }

  closeDeleteDialog(): void {
    this.selectedDataForDelete=[];
    this.siteRecoveryServiceService.setSelectedRows(this.selectedDataForDelete);
    this.deleteModal = false;
    if (this.triggerSearch) {
      this.search();
    }

  }


  checkSaveChangesStatus() {
    this.siteRecoveryServiceService.getCheckSaveChangesStatus().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        this.loading = false;
        this.search();
      }
    });
  }


    protected readonly PageType = PageType;
}
