export enum PageType {
	VIEW_MY_INFO = 'VIEW_MY_INFO',
	CHANGE_MY_PROFILE = 'CHANGE_MY_PROFILE',
	CHANGE_MY_PASSWORD = 'CHANGE_MY_PASSWORD',

	TREE_STRUCTURE = 'TREE_STRUCTURE',
	SCT_ADMINISTRATORS = 'SCT_ADMINISTRATORS',
	MARKET_ADMINISTRATORS = 'MARKET_ADMINISTRATORS',
	MARKET_AUDIT = 'MARKET_AUDIT',
	MARKET_SUPPORT_CENTER = 'MARKET_SUPPORT_CENTER',
	SC_MEMBERS = 'SC_MEMBERS',
	SC_ADMINISTRATORS = 'SC_ADMINISTRATORS',
	SC_AUDIT = 'SC_AUDIT',
	SC_SUPPORT_CENTER = 'SC_SUPPORT_CENTER',
	MEMBERS="MEMBERS",
	AUDIT="AUDIT",
	SUPPORT_CENTER="SUPPORT_CENTER",
	ADMINISTRATORS="ADMINISTRATORS",
	ACCESS_RIGHTS = 'ACCESS_RIGHTS',

	SEARCH_USER = 'SEARCH_USER',
	MANAGE_TEMP_IDS = 'MANAGE_TEMP_IDS',
	MANAGE_JOB_ROLES = 'MANAGE_JOB_ROLES',
	MANAGE_AUDIT_CONFIG = 'MANAGE_AUDIT_CONFIG',
	SITE_RECOVERY_TOOL = 'SITE_RECOVERY_TOOL',

	USER_REPORT = 'USER_REPORT',
	ADMIN_REPORT = 'ADMIN_REPORT',
	GROUP_AUDIT_REPORT = 'GROUP_AUDIT_REPORT',

	HELP_ACCESS = 'HELP_ACCESS',

	CREATE_TEMP_ID = 'CREATE_TEMP_ID',

	REPORT='REPORT', // this is for the report page module

	RETIREE_PASSWORD = 'RETIREE_PASSWORD'
}
