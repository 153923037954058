import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {MenubarService} from "../menubar/service/menubar.service";

@Component({
  selector: 'home',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

	isCollapsed = false;
	/** constructor for setting up DI in this component */
	constructor(private readonly router: Router,private readonly menubarService: MenubarService) {

	}

	/** this class requires this method to implement the OnInit interface */
	ngOnInit(): void {
		this.menubarService.isCollapsed$.subscribe((isCollapsed) => {
			this.isCollapsed = !isCollapsed;
		});
	}
}
