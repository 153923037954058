import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {AdminFlag, AdminUpdateRequest, AdminUser, AdminUserRequest, UserFlagRequest} from "./admin.model";
import {map} from "rxjs/operators";
import {finalize, Subject, takeUntil} from "rxjs";
import {OverlayPanel} from "primeng/overlaypanel";
import {NgForm} from "@angular/forms";
import {MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";
import {ConfirmDialogComponent} from "../../../common/confirm-dialog/confirm-dialog.component";
import { ManageTemporaryIdService } from '../../services/manage.temporary.id.service';
import {PageType} from "../../../common/models/user-role/page-type.enum";
import {BaseUserGroupModel} from "../../../user-groups/base-usergroup.model";

@Component({
  selector: 'admin-temp-groups',
  templateUrl: './admin-temp-groups.component.html',
  styleUrls: ['./admin-temp-groups.component.scss'],
	changeDetection:ChangeDetectionStrategy.OnPush
})
export class AdminTempGroupsComponent implements  OnInit,OnDestroy{
	@ViewChild('opadmin') opAdmin!: OverlayPanel;
  	@Input({required:true}) tempGroupName!:string;
	baseUserGroupModel:BaseUserGroupModel;
	adminUsers:AdminUser[];
	selectedUser:any[];
	updateUserRequest:AdminUpdateRequest;
	tableLoader:boolean;
	userId:string;
	isChecked:boolean;
	inlineLoader:boolean;
	disablebtn:boolean;
	inlineAdminMsg:string;
	loadingDelete:boolean;
	private destroy$ = new Subject<void>();
	constructor(
		private manageTempServices:ManageTemporaryIdService,private changeDetectorRef: ChangeDetectorRef,
		private messageService:MessageService,private dialogService:DialogService
				) {
	}
	ngOnInit(): void {
		this.adminUsers = [];
		this.disablebtn = false;
		this.selectedUser = [];
		this.getAdminUserList(this.tempGroupName);
		const [branch,siteCodeType] = this.tempGroupName.split("-");
		this.baseUserGroupModel = new BaseUserGroupModel(branch,siteCodeType,null,null);
	}

	confirmDialog(event: Event) {
		if (this.selectedUser?.length === 0) return;
		const userIds = this.selectedUser.map(user=>user.userId).join(",");

		const ref = this.dialogService.open(ConfirmDialogComponent,{
			width: "36vw",
			showHeader:false,
			styleClass:'custom-dialog',
			data: {
				message:'The Following admins will be removed',
				stringIds:userIds
			},
		});
		ref.onClose.subscribe((data) => {
			if (data) {
				this.changeDetectorRef.markForCheck();
				this.deleteAdminUser(userIds);
			}
		});
	}

	getAdminUserList(tempGroupName){
		this.tempGroupName = tempGroupName;
		this.adminUsers = [];
		this.selectedUser = [];
		this.tableLoader = true;
		this.changeDetectorRef.markForCheck();
		this.manageTempServices.getAdminUsers(this.tempGroupName)
			.pipe(
				map((data: any) =>
					data.memberDetailsList.map(adminUser => ({
						...adminUser,
						groupAdmin: adminUser.adminFlag === AdminFlag.GroupAdmin
					}))
				),
				takeUntil(this.destroy$),
				finalize(()=>this.tableLoader=false)
			)
			.subscribe((data:AdminUser[])=>{
				this.adminUsers = data;
				this.changeDetectorRef.markForCheck();
			})
	}

	onDeleteUser(event){
		this.selectedUser =event;
	}

	onSelectedUser(userFlagRequest:UserFlagRequest){
		const ref = this.dialogService.open(ConfirmDialogComponent,{
			width: "36vw",
			header:'Confirmation',
			showHeader:false,
			data: {
				message: userFlagRequest.userId.toUpperCase() +' will be converted to '+ (userFlagRequest.adminFlag == AdminFlag.GroupAdmin ? 'Group Admin' : 'User Admin'),
				iconBorder:true,
				iconMsg: userFlagRequest.adminFlag == AdminFlag.GroupAdmin ? 'pi pi-users' : 'pi pi-user'
			},
		});
		ref.onClose.subscribe((data) => {
			if (data) {
				this.changeDetectorRef.markForCheck();
				this.updateAdminUsers(userFlagRequest);
			}else {
				this.resetUserAdmin(userFlagRequest);
			}
		});
	}
	updateAdminUsers(updateUserFlagRequest:UserFlagRequest){
		const adminUserFlag:AdminUpdateRequest = {
			tempGroupName:this.tempGroupName,
			userFlagList: [updateUserFlagRequest]
		};
		this.manageTempServices.updateAdminUser(adminUserFlag)
			.pipe(takeUntil(this.destroy$))
			.subscribe((data)=>{
				this.messageService.add({ severity: 'success', summary: 'Admin(s) updated Successfully'});
				// this.getAdminUserList(this.baseUserGroupModel);
		},
				error => {
				this.resetUserAdmin(updateUserFlagRequest);
					// this.messageService.add({ severity: 'error', summary: 'Error in updating User(s)'});
				})
	}

	resetUserAdmin(userFlagRequest:UserFlagRequest){
		this.adminUsers = this.adminUsers.map(admin => {
			if (admin.userId === userFlagRequest.userId) {
				return {...admin, adminFlag: admin.groupAdmin ? AdminFlag.UserAdmin : AdminFlag.GroupAdmin,groupAdmin:!admin.groupAdmin}; // Update the price
			}
			return admin;
		});
		this.changeDetectorRef.markForCheck();
	}

	openAddUser(event){
		this.opAdmin.show(event);
	}

	addAdminUser(userId,adminCheck,adminAddFrom:NgForm){
		if(adminAddFrom?.invalid){
			return;
		}
		const adminUserRequest:AdminUserRequest = {
			userId: userId,
			adminFlag: adminCheck ? AdminFlag.GroupAdmin : AdminFlag.UserAdmin,
			tempGroupName:this.tempGroupName
		};
		this.inlineLoader = true;
		this.inlineAdminMsg = null;
		this.manageTempServices.addAdminUser(adminUserRequest)
			.pipe(takeUntil(this.destroy$),
				finalize(()=> {
					this.inlineLoader = false;
					this.changeDetectorRef.markForCheck();
				})
			)
			.subscribe((data)=>{
				this.messageService.add({ severity: 'success', summary: 'Admin Added Successfully'});
				this.opAdmin.hide();
				adminAddFrom.reset();
				setTimeout(()=>{this.getAdminUserList(this.tempGroupName);},2000)
		},
				error => {
					this.inlineAdminMsg  = error.message;
				});
	}

	deleteAdminUser(userIds:string){
		this.loadingDelete = true;
		this.disablebtn = true;
		this.manageTempServices.deleteAdminUser(this.tempGroupName,userIds)
			.pipe(takeUntil(this.destroy$),
				finalize(()=>{
					this.loadingDelete = false;
					this.disablebtn = false;
				}))
			.subscribe((data)=>{
				this.messageService.add({ severity: 'success', summary: 'Successfully deleted the  Admin(s) : '+userIds });
				setTimeout(()=>{this.getAdminUserList(this.tempGroupName);},2000)
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

    protected readonly PageType = PageType;
}
