<div class="card">
  <div class="flex justify-content-end">
    <span class="text-red-500">*</span>
    <span>{{"label.indicationRequiredField" | translate}}</span>
  </div>
	<form [formGroup]="createUserForm" (ngSubmit)="onSubmit()">
  <p-fieldset [toggleable]="true">
      <ng-template pTemplate="header">
          <div class="flex align-items-center">
              <span class="pi pi-user mr-2"></span>
              <span class="font-bold text-lg"> {{"header.basicInfo" | translate}}</span>
          </div>
      </ng-template>
      <div class="card">
        <div class="flex flex-wrap mb-4">
          <!-- <div class="flex-auto">
            <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> Title </label><span class="text-red-500"> *</span></div>
            <p-dropdown  styleClass="w-full" [options]="title" formControlName="title" optionLabel="title" [showClear]="true" placeholder="Select a Title"></p-dropdown>
            </div> -->
            <div class="col-3">
                <label for="alphabetic" class="font-bold block mb-2 required-label"> {{"label.firstName" | translate}}  </label>
                <div class="relative">
					<input type="text" autofocus pInputText id="firstName" pKeyFilter="alpha" formControlName="firstName" class="w-full" />
					<div class="absolute">
						<small class="text-red-400" *ngIf="createUserForm.get('firstName').hasError('required') && createUserForm.get('firstName').touched">
							{{"message.firstNameRequired" | translate}}
						</small>
						<small class="text-red-400" *ngIf="createUserForm.get('firstName').hasError('missingAlphanumeric')">
							 {{"message.firstNameOneLetter" | translate}}
						</small>
            <small class="text-red-400" *ngIf="createUserForm.get('firstName').hasError('maxlength')">
              {{"message.nameLengthMessage" | translate}}
           </small>
						<div *ngIf="createUserForm.get('firstName').hasError('invalidName')">
							<small class="text-red-400" *ngIf="createUserForm.get('firstName').getError('invalidName').illegalChars">
								{{"message.illegalCharacters" | translate}}: {{ createUserForm.get('firstName').getError('invalidName').illegalChars }}
							</small>
						</div>
					</div>
				</div>
              </div>
            <div class="col-2">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.middleName" | translate}} </label>
				<div class="relative">
					<input pInputText id="alpha" pKeyFilter="alpha" formControlName="middleName" class="w-full" />
					<div class="absolute">
						<small class="text-red-400" *ngIf="createUserForm.get('middleName').hasError('invalidMiddleInitial')">
              {{ createUserForm.get('middleName').getError('invalidMiddleInitial').message }}
						</small>
					</div>
				</div>
            </div>
            <div class="col-3">
              <label for="alphabetic" class="font-bold block mb-2 required-label"> {{"label.lastName" | translate}}  </label>
			<div class="relative">
				<input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="lastName" class="w-full" />
				<div class="absolute">
					<small class="text-red-400" *ngIf="createUserForm.get('lastName').hasError('required') && createUserForm.get('lastName').touched">
						 {{"message.lastNameRequired" | translate}}
					</small>
          <small class="text-red-400" *ngIf="createUserForm.get('lastName').hasError('maxlength')">
            {{"message.nameLengthMessage" | translate}}
         </small>
					<div *ngIf="createUserForm.get('lastName').hasError('invalidName')">
                <small class="text-red-400" *ngIf="createUserForm.get('lastName').getError('invalidName').illegalChars">
                  {{"message.illegalCharacters" | translate}}: {{ createUserForm.get('lastName').getError('invalidName').illegalChars }}
                </small>
					</div>
					<small class="text-red-400" *ngIf="createUserForm.get('lastName').hasError('missingAlphanumeric')">
						{{"message.lastNameOneLetter" | translate}}
					</small>
				</div>
			</div>

            </div>
            <div class="col-3">
              <label for="alphabetic" class="font-bold block mb-2 required-label"> {{"label.preferredLanguage" | translate}} </label>
              <p-dropdown  styleClass="w-full" [options]="language" formControlName="language" optionValue="languageCode" optionLabel="languageName" [filter]="true" filterBy="languageName" [showClear]="true" placeholder="{{'placeholder.selectLanguage' | translate}}"></p-dropdown>
              </div>
        </div>
        <div class="flex flex-wrap">
          <div class="col-4">
            <label for="alphabetic" class="font-bold block mb-2 required-label">  {{"label.userEmail" | translate}}  </label>
			  <div class="relative">
				  <input pInputText pKeyFilter="alpha" formControlName="email" class="w-full" />
				  <div class="absolute">
					  <small class="text-red-400" *ngIf="(createUserForm.get('email').hasError('email') || createUserForm.get('email').hasError('pattern')) && !createUserForm.get('email').hasError('required')">
						  {{"message.validEmail" | translate}}
					  </small>
					  <small class="text-red-400" *ngIf="createUserForm.get('email').hasError('required') && createUserForm.get('email').touched">
						  {{"message.emailRequired" | translate}}
					  </small>
            <small class="text-red-400" *ngIf="createUserForm.get('email').hasError('maxlength')">
              {{"message.emailLengthMessage" | translate}}
           </small>
				  </div>
			  </div>
			  <p class="highlight mt-3 mx-0"> {{"message.uniqueEmail" | translate}}
			  </p>
          </div>
            <div class="col-4">
              <label for="alphabetic" [class]="jobRoleRequired ? 'font-bold block mb-2 required-label'  : 'font-bold block mb-2' "> {{"label.jobRole" | translate}}   </label>
              <div class="relative">
              <p-multiSelect *ngIf="multiselect" styleClass="w-full mr-4" [options]="fordJobRoles" formControlName="jobroledata" optionLabel="jobRoleName" placeholder="{{'placeholder.selectJobRole' | translate }}" [showClear]="true"></p-multiSelect>
              <p-dropdown *ngIf="!multiselect" styleClass="w-full mr-4" formControlName="jobroledata" [options]="fordJobRoles" optionLabel="jobRoleName" optionValue="jobRoleCode" [filter]="true" filterBy="jobRoleName,jobRoleCode" [showClear]="true" placeholder="{{'placeholder.selectJobRole' | translate }}"></p-dropdown>
              <div *ngIf="jobRoleRequired" class="absolute">
                <small class="text-red-400" *ngIf="createUserForm.get('jobroledata').hasError('required') && createUserForm.get('jobroledata').touched">
                  {{"message.jobroleRequired" | translate}}
                </small>
            </div>
            </div>
              </div>
              <div id="createUsersSelect" class="col-4">
                <label for="alphabetic" class="font-bold block mb-2 required-label"> {{"label.status" | translate}} </label>
                <div class="relative">
                <p-selectButton  [options]="stateOptions" formControlName="value" optionLabel="label" optionValue="value"></p-selectButton>
                <div class="absolute">
                  <small class="text-red-400" *ngIf="createUserForm.get('value').hasError('required') && createUserForm.get('value').touched">
                    {{"message.statusRequired" | translate}}
                  </small>
                </div>
              </div>
              </div>

        </div>

    </div>

  </p-fieldset>
		<br>
  <p-fieldset [toggleable]="true">
    <ng-template pTemplate="header">
        <div class="flex align-items-center">
            <span class="pi pi-user mr-2"></span>
            <span class="font-bold text-lg">{{"header.contactInfo" | translate}}</span>
        </div>
    </ng-template>
    <div class="card">
      <div class="flex flex-wrap mb-4">
        <!-- <p class="text-primary">Important:  Ensure User Email address is unique. Failure to do so will prevent access to Ford SharePoint sites (SPO365: SharePoint Package)
        </p> -->
          <div class="col-4">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.telephone" | translate}} </label>
              <input pInputText pKeyFilter="alpha" formControlName="phone" class="w-full" />
              <small class="text-red-400" *ngIf="createUserForm.get('phone').hasError('invalidPhoneFax')">
                {{ createUserForm.get('phone').getError('invalidPhoneFax').message }}
              </small>
              <small class="text-red-400" *ngIf="createUserForm.get('phone').hasError('maxlength')">
                {{"message.phoneLengthMessage" | translate}}
             </small>
          </div>
          <div class="col-4">
              <label for="alphabetic" class="font-bold block mb-2"> {{"label.fax" | translate}} </label>
              <input pInputText pKeyFilter="alpha" formControlName="fax" class="w-full" />
              <small class="text-red-400" *ngIf="createUserForm.get('fax').hasError('invalidPhoneFax')">
                {{ createUserForm.get('fax').getError('invalidPhoneFax').message}}
              </small>
              <small class="text-red-400" *ngIf="createUserForm.get('fax').hasError('maxlength')">
                {{"message.faxLengthMessage" | translate}}
             </small>
          </div>
          <div class="col-4">
            <label for="alphabetic" class="font-bold block mb-2">  {{"label.mailingAddress" | translate}}</label>
            <input pInputText  pKeyFilter="alpha" formControlName="postalAddress" class="w-full" />
            <small class="text-red-400" *ngIf="createUserForm.get('postalAddress').hasError('invalidMailingAddress')">
              {{ createUserForm.get('postalAddress').getError('invalidMailingAddress').message }}
            </small>
            <small class="text-red-400" *ngIf="createUserForm.get('postalAddress').hasError('maxlength')">
              {{"message.postalAddressLengthMessage" | translate}}
           </small>
        </div>
      </div>
      <div class="flex flex-wrap">

        <div class="col-4">
            <label for="alphabetic" class="font-bold block mb-2"> {{"label.city" | translate}} </label>
            <input pInputText pKeyFilter="alpha" formControlName="city" class="w-full" />
            <small class="text-red-400" *ngIf="createUserForm.get('city').hasError('invalidCity')">
              {{ createUserForm.get('city').getError('invalidCity').message }}
            </small>
            <small class="text-red-400" *ngIf="createUserForm.get('city').hasError('maxlength')">
              {{"message.cityLengthMessage" | translate}}
           </small>
        </div>
        <div class="col-4">
            <label for="alphabetic" class="font-bold block mb-2"> {{"label.stateOrProvince" | translate}}</label>
            <input pInputText pKeyFilter="alpha" formControlName="state" class="w-full" />
            <small class="text-red-400" *ngIf="createUserForm.get('state').hasError('invalidState')">
              {{ createUserForm.get('state').getError('invalidState').message }}
            </small>
        </div>
        <div class="col-4">
          <label for="alphabetic" class="font-bold block mb-2"> {{"label.postalCode" | translate}}</label>
          <input pInputText pKeyFilter="alpha" formControlName="postalCode" class="w-full" />
          <small class="text-red-400" *ngIf="createUserForm.get('postalCode').hasError('invalidPostalCode')">
            {{ createUserForm.get('postalCode').getError('invalidPostalCode').message }}
          </small>
          <small class="text-red-400" *ngIf="createUserForm.get('postalCode').hasError('maxlength')">
            {{"message.postalCodeLengthMessage" | translate}}
         </small>
      </div>
      </div>
  </div>

</p-fieldset>

<div class="flex gap-3 justify-content-center mt-4">
  <p-button icon="pi pi-check" type="submit" label="{{'button.create' | translate}}" [disabled]="!createUserForm.valid" styleClass="p-button-text"></p-button>
  <p-button icon="pi pi-times" (click)="closeDialog()" label="{{'button.cancel' | translate}}" styleClass="p-button-text"></p-button>
</div>
</form>
<p-dialog header="Header" [(visible)]="enableDialog || loading " [modal]="true" [style]="{ width: '50vw' }" (onHide)="modelHide()" [closable]="false">
  <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{"header.createUserResponse" | translate}}</span>
  </ng-template>
 <div class="card">
    <div class="flex gap-5 flex-column align-items-center justify-content-center" *ngIf="loading">
      <p> {{"message.createTempIdResponse" | translate}}</p>
		<p-progressSpinner strokeWidth="5" styleClass="w-5rem h-5rem"
						   ariaLabel="loading" animationDuration=".8s"/>
    </div>
    <div *ngIf="!responseMessage && !loading">
      <p class="text-center text-black-alpha-90 font-bold mb-4"> {{"message.newUserId" | translate}}</p>
    </div>
    <div class="flex justify-content-center" *ngIf="!responseMessage && !loading">
      <div class="col-4">
        <label for="alphabetic" class="font-bold block mb-2"> {{"label.userID" | translate}} </label>
        <div id="buttonGroup" class="p-inputgroup">
        <input type="text" pInputText pKeyFilter="alpha" [(ngModel)]="createdUserId" placeholder="{{'label.userID' | translate }}"/>
        <button type="button" pButton [cdkCopyToClipboard]="createdUserId" [icon]="copyUserId ? 'pi pi-check' : 'pi pi-copy'" (click)="copyUser()" styleClass="p-button-warn"></button>
        </div>
      </div>
      <div class="col-4">
        <label for="alphabetic" class="font-bold block mb-2"> {{"label.temporaryPassword" | translate}} </label>
        <!-- <input pInputText id="alphabetic" pKeyFilter="alpha" [(ngModel)]="tempPwd" class="w-full mb-2" />
        <p-button [cdkCopyToClipboard]="tempPwd" (click)="copy()" label="{{copyMessage}}"></p-button> -->
        <div id="buttonGroup" class="p-inputgroup">
          <input type="text" pInputText [(ngModel)]="tempPwd" placeholder="{{'label.temporaryPassword' | translate }}" />
          <button type="button" pButton [cdkCopyToClipboard]="tempPwd" [icon]="copyPass ? 'pi pi-check' : 'pi pi-copy'" (click)="copyPassword()" styleClass="p-button-warn"></button>
      </div>

      </div>
    </div>
    <div *ngIf="!responseMessage && !loading">
      <p class="text-center text-red-400 font-bold mb-4">{{"message.waitNewAccount" | translate}}
      </p>
      <p class="text-center text-red-400">{{"message.noteIdPassword" | translate}}
      </p>
    </div>
    <p *ngIf="responseMessage && !loading">
      {{responseMessage}}
    </p>
 </div>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-danger" (click)="closeRef(true)" label="{{'button.close' | translate}}" styleClass="p-button-text"></p-button>
      <!-- <p-button icon="pi pi-danger" (click)="visible = false" label="No" styleClass="p-button-text"></p-button> -->
  </ng-template>
</p-dialog>

</div>

