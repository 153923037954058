import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SiteRecoveryServiceService } from '../site-recovery-service/site-recovery-service.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { SiteData } from '../site-recovery.component';
import { CommonConstants } from 'src/app/common/common.constants';

@Component({
  selector: 'modify-site-attributes',
  templateUrl: './modify-site-attributes.component.html',
  styleUrls: ['./modify-site-attributes.component.scss']
})
export class ModifySiteAttributesComponent {
  @Input() editableDataNotifier?: BehaviorSubject<SiteData | null> = new BehaviorSubject<SiteData | null>(null);
  private destroy$ = new Subject<void>();
  loading: boolean = false;
  editModal: boolean = false;
  editForm: FormGroup;
  siteData?: SiteData;
  saveChangesResponseModal: boolean = false;
  saveChangesAPIResponse: string;
  saveChangesStatus: boolean = false;
  statusOptions: any[] = [{ label: 'Active', value: 'on' }, { label: 'InActive', value: 'off' }];

  constructor(private siteRecoveryServiceService: SiteRecoveryServiceService, private fb: FormBuilder) {

    this.editForm = this.fb.group({
      branch: [''],
      fordSiteCodeType: [''],
      market: [''],
      fordSiteCode: [''],
      fordLastModifiedBy: [''],
      dealershipName: [''],
      postalAddress: [''],
      city: [''],
      state: [''],
      postalCode: [''],
      telephoneNumber: [''],
      facsimileTelephoneNumber: [''],
      preferredLanguage: [''],
      fordSalesCode: [[]],
      fordParentOrganization: [''],
      fordCsdCode: [''],
      fordstatus: [''],
      labeledURI: [''],
      description: [''],
      fmccRegion: [''],
      fmccLessor: [''],
      fmccBrand: [''],
      fmccBCode: [''],
      fmccFinLab: [''],
      fmccSbCode: [''],
      fmccMkt: [''],
      fmccpGroup: [''],
      fmccGroup: [''],
      fmccSt: [''],
      fmccClass: [''],
      modifyTimeStamp: ['']
    });

  }


  ngOnInit() {
    this.getEditableModalFlag();
    this.editableDataNotifier.pipe(takeUntil(this.destroy$)).subscribe((data: SiteData) => {
      if (data) {
        this.siteData = data;
        this.editForm.patchValue({
          fordSiteCode: data.fordSiteCode,
          branch: data.branch,
          fordSiteCodeType: data.fordSiteCodeType,
          market: data.market,
          description: data.description,
          fordLastModifiedBy: data.fordLastModifiedBy,
          dealershipName: data.dealershipName,
          postalAddress: data.postalAddress,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
          telephoneNumber: data.telephoneNumber,
          facsimileTelephoneNumber: data.facsimileTelephoneNumber,
          preferredLanguage: data.preferredLanguage,
          //fordSalesCode: data.fordSalesCode,
          fordSalesCode:data.fordSalesCode?.join(';'),
          fordParentOrganization: data.fordParentOrganization,
          fordCsdCode: data.fordCsdCode,
          fordstatus: data.fordstatus.toLowerCase(),
          labeledURI: data.labeledURI,
          fmccRegion: data.fmccRegion,
          fmccLessor: data.fmccLessor,
          fmccBrand: data.fmccBrand,
          fmccBCode: data.fmccBCode,
          fmccFinLab: data.fmccFinLab,
          fmccSbCode: data.fmccSbCode,
          fmccMkt: data.fmccMkt,
          fmccpGroup: data.fmccpGroup,
          fmccGroup: data.fmccGroup,
          fmccSt: data.fmccSt,
          fmccClass: data.fmccClass,
          modifyTimeStamp: new Date().toISOString(),
        });
      }
    });

  }

  closeEditDialog(): void {
    this.editModal = false;
    this.siteRecoveryServiceService.editableModal(this.editModal);
  }

  closeSaveChangeResponseDialog(): void {
    this.resetForm();
    this.saveChangesResponseModal = false;
    this.loading = false;
    this.siteRecoveryServiceService.setCheckSaveChangesStatus(this.saveChangesStatus);
  }

  getEditableModalFlag(): void {
    this.siteRecoveryServiceService.getEditableModal().pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.editModal = data;
      });
  }

  resetForm(): void {
    this.editForm.reset();
    this.siteData = null;
  }

  populateFordSalesCode(salesCode:string):string[]{
  return salesCode?salesCode.split(';').map(str => str.trim()).filter(str => str.length > 0):[];
  }

  onSubmit() {
    if (this.editForm.valid) {
      const { branch, ...dataToSubmit } = this.editForm.value;
      for (const key in dataToSubmit) {
        if (dataToSubmit[key] === '') {
          dataToSubmit[key] = null;
        }
        if(key === CommonConstants.FORDSALESCODE){
          dataToSubmit[key] = this.populateFordSalesCode(dataToSubmit[key]);
        }
      }
      const siteData: SiteData = dataToSubmit;
      this.editModal = false;
      this.loading = true;
      this.saveChangesAPIResponse = '';
      this.saveChangesResponseModal = true;
      var requestData: SiteData[] = [];
      requestData.push(siteData);
      this.siteRecoveryServiceService.modifySiteAttribute(requestData).pipe(takeUntil(this.destroy$))
        .subscribe({
          next: data => {
            this.loading = false;
            this.saveChangesAPIResponse = 'The site (' + siteData.fordSiteCode + ') have been updated successfully.';
            this.saveChangesStatus = true;
            this.resetForm();
          }, error: error => {
            this.loading = false;
            this.saveChangesAPIResponse = 'The site (' + siteData.fordSiteCode + ') have been failed to updated';
            this.resetForm();
          }
        });

    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
