<form [formGroup]="editTempForm" (ngSubmit)="onSubmit()">
  <p-dialog header="Header" [(visible)]="visible" [modal]="true" [breakpoints]="{ '960px': '75vw' }" [styleClass]=" 'w-9 h-40rem' " (onHide)="closeDialog()" [closable]="true">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{"header.editTemporaryUser" | translate}}</span>
    </ng-template>
    <ng-container>
  <div class="card h-27rem">
    <div class="flex justify-content-end mb-4">
      <span class="text-red-500">*</span>
      <span>{{"label.indicationRequiredField" | translate}}</span>
    </div>
    <!-- <p-fieldset [toggleable]="true" [styleClass]="'h-25rem'"> -->
      <ng-container>
        <!-- <ng-template pTemplate="header">
            <div class="flex align-items-center">
                <span class="pi pi-user mr-2"></span>
                <span class="font-bold text-lg">Basic Info</span>
            </div>
        </ng-template> -->
        <div class="card">
          <div class="flex flex-wrap mb-4">
            <div class="col-3">
              <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.userID" | translate}}  </label><span class="text-red-500"> *</span></div>
              <input type="text" pInputText id="userId" pKeyFilter="alpha" formControlName="userId" class="w-full" disabled/>
            </div>
              <div class="col-3">
                  <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.firstName" | translate}}  </label><span class="text-red-500"> *</span></div>
                  <input type="text" pInputText id="firstName" pKeyFilter="alpha" formControlName="firstName" class="w-full" />
              </div>
              <div class="col-3">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.lastName" | translate}}  </label><span class="text-red-500"> *</span></div>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="lastName" class="w-full" />
              </div>
              <div class="col-3">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.primaryLocation" | translate}} </label><span class="text-red-500"> *</span></div>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="primaryLocation" class="w-full" />
              </div>

          </div>
          <div class="flex flex-wrap">
                <div class="col-3">
                  <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.userEmail" | translate}} </label><span class="text-red-500"> *</span></div>
                  <div class="relative">
                    <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="email" class="w-full" />
                    <div class="absolute">
                      <small class="text-red-400" *ngIf="(editTempForm.get('email').hasError('email') || editTempForm.get('email').hasError('pattern')) && !editTempForm.get('email').hasError('required')">
                        {{"message.validEmail" | translate}}
                      </small>
                      <small class="text-red-400" *ngIf="editTempForm.get('email').hasError('required') && editTempForm.get('email').touched">
                        {{"message.emailRequired" | translate}}
                      </small>
						<small class="text-red-400" *ngIf="editTempForm.get('email').hasError('maxlength')">
							{{"message.emailLengthMessage" | translate}}
						</small>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.jobRole" | translate}}  </label></div>
                  <p-dropdown styleClass="w-full mr-4" [options]="jobRoleList" formControlName="jobrole" placeholder="{{'placeholder.selectJobRole' | translate }}" [showClear]="true" optionLabel="jobRoleName" ></p-dropdown>
                </div>
              <div class="col-3">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.comments" | translate}}  </label>
                <textarea rows="5" pInputTextarea formControlName="comments" class="w-full"></textarea>
              </div>
          </div>
      </div>
    </ng-container>
    <!-- </p-fieldset> -->
  </div>
  </ng-container>

  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" type="submit" label="{{'button.save' | translate}}" [disabled]="!editTempForm.valid" styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-times" (click)="closeDialog()" label="{{'button.cancel' | translate}}" styleClass="p-button-text"></p-button>
  </ng-template>
  </p-dialog>
  <p-dialog header="Header" [(visible)]="enableDialog" [modal]="true" >
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{"label.response" | translate}}</span>
    </ng-template>
    <div class="flex align-items-center justify-content-center" *ngIf="loading">
      <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
    <p class="card flex" *ngIf="responseMessage && !loading">
     {{responseMessage}}
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-danger" (click)="disableandRefresh()" label="{{'button.close' | translate}}" styleClass="p-button-text"></p-button>
        <!-- <p-button icon="pi pi-danger" (click)="visible = false" label="No" styleClass="p-button-text"></p-button> -->
    </ng-template>
  </p-dialog>
  </form>
