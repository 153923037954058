import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit} from '@angular/core';
import { UserGroupsService } from '../services/user-groups.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import * as languageData from '../../../assets/data/language.json';
import {UserModel} from "./user.model";
import {postalCodeValidator} from "../../core/validator";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {BaseUserGroupModel} from "../base-usergroup.model";
import { Branch } from '../search-tree-node/search-node.model';
import { UserIdService } from 'src/app/core/ngx-adfs';
import {map, Subject, takeUntil, tap, timer} from 'rxjs';
import { CommonConstants } from 'src/app/common/common.constants';

interface Id{
  languageCode: string,
  tranLanguageCode: string
}

interface JobRole {
  name: string,
  code: string
}

interface Language {
  id: Id,
  languageName: string
}
interface SiteCodeType {
  sitecodetype: string,
}
@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent implements OnInit{

  loading: boolean = false;
  visible:boolean = false;
  enableDialog: boolean = false;
  createdUserId: string = '';
  tempPwd: string = '';
  responseMessage: string = '';
  copied: boolean = false;
  copyUserId: boolean = false;
  copyPass: boolean = false;
  fordJobRoles: JobRole[]=[];
  multiselect: boolean;
  baseGroupModel:BaseUserGroupModel;
  userModel:UserModel;
  headerLabel:string;
  branchList:Branch[] = [];
  loggedinUser: string;
  jobRoleRequired: boolean = false;
  private unsubscribe = new Subject<void>();
  constructor(private userGroupService: UserGroupsService,private fb: FormBuilder,private changeDetectorRef: ChangeDetectorRef,
			  public ref: DynamicDialogRef, public configDialog: DynamicDialogConfig,
			  private authService: UserIdService) {
	  this.userModel = configDialog.data?.userModel || {};
	  this.baseGroupModel = configDialog.data?.baseUserGroupModel;
	  this.fordJobRoles = configDialog.data?.jobroleData;
	  this.multiselect = configDialog.data?.multiselect;
    this.language = configDialog.data?.language;
    this.jobRoleRequired = configDialog.data?.jobRoleRequired

    this.userGroupService.fetchBranchList()
				.pipe(
					tap((response: any[]) => console.log("TOP GROUP", response)),
					map((responseArray: any[]) => responseArray.map((response) => ({
						name: response?.branchName,
						code: response?.branchCode
					})))
				)
				.subscribe((data: Branch[]) => {
				this.branchList = data;
				this.changeDetectorRef.markForCheck();
			})
	}

  stateOptions: any[] = [{label: 'Active', value: 'on'}, {label: 'Inactive', value: 'off'}];

    jobrole!: JobRole[];

    language!: Language[];

    site!: SiteCodeType[];
    createUserForm:FormGroup;
    ngOnInit() {
    this.authService.getUserId().pipe(takeUntil(this.unsubscribe)).subscribe((data:string) => {
      this.loggedinUser = data;
    });
    const defaultLanguage = "en-US";
		this.createUserForm = this.fb.group({
			title: new FormControl(''),
			firstName: new FormControl(this.userModel?.firstName, [Validators.required, this.nameValidator,Validators.maxLength(31)]),
			lastName: new FormControl(this.userModel?.lastName, [Validators.required, this.nameValidator,Validators.maxLength(31)]),
			middleName: new FormControl(this.userModel?.middleName,[this.middleInitialValidator]),
			email: new FormControl(this.userModel?.email, [Validators.required,Validators.email,Validators.maxLength(256),Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]),
			phone: new FormControl(this.userModel?.phone,[this.phoneFaxValidator,Validators.maxLength(64)]),
			postalAddress: new FormControl(this.userModel?.postalAddress,[this.mailingAddressValidator,Validators.maxLength(1024)]),
			city: new FormControl(this.userModel?.city,[this.cityValidator,Validators.maxLength(129)]),
			state: new FormControl(this.userModel?.state,[this.stateValidator]),
			postalCode: new FormControl(this.userModel?.postalCode,[this.postalCodeValidator,Validators.maxLength(40)]),
			fax: new FormControl(this.userModel?.fax,[this.phoneFaxValidator,Validators.maxLength(64)]),
			jobroledata: new FormControl(this.userModel?.fordJobRoles,this.jobRoleRequired ? [Validators.required]:[]),
			value: new FormControl('on', Validators.required),
			language: new FormControl(defaultLanguage, Validators.required)

		});
		this.headerLabel = this.userModel ? 'Update User' : 'Create new user';
        // this.getLanguageData();
    }

    nameValidator(control: FormControl): { [key: string]: any } | null {
      const nameRegex = /^(?=.*[a-zA-Z0-9])[^<>&"()#%;+]{1,64}$/;
      const value = control.value;
      if (!value) {
        return null; // No value, no validation
      }
      const isValid = nameRegex.test(value);
      if (!isValid) {
        // Find illegal characters
        const illegalChars = value.match(/[\<>&"()#%;+]/g);
        if (illegalChars) {
          return { 'invalidName': { 'illegalChars': illegalChars.join(', ') } };
        }
      }
      // Check for alphanumeric characters
      if (!/[a-zA-Z0-9]/.test(value)) {
        return { 'missingAlphanumeric': true };
      }
      return null; // Valid
    }
    middleInitialValidator(control: FormControl): { [key: string]: any } | null {
      const value = control.value;

      if (!value) {
        return null; // No value, no validation
      }

      const middleInitialRegex = /^[a-zA-Z0-9]{3}$/;
      const isValid = middleInitialRegex.test(value);

      if (!isValid) {
        if (value.length >3) {
          return { 'invalidMiddleInitial': { 'message': 'The middle initial can be 3 characters long.' } };
        } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
          return { 'invalidMiddleInitial': { 'message': 'Please enter only alphanumeric characters.' } };
        }
      }

      return null; // Valid
    }
phoneFaxValidator(control: FormControl): { [key: string]: any } | null {
  const value = control.value;

  if (!value) {
    return null; // No value, no validation
  }
  const phoneFaxRegex = /^[-A-Za-z0-9()\+ \/.#]{1,64}$/;
  const isValid = phoneFaxRegex.test(value);

  if (!isValid) {
    // Find illegal characters
    const illegalChars = value.match(/[^A-Za-z0-9()\+ \/.#]/g);
    if (illegalChars) {
      return { 'invalidPhoneFax': { 'message': `Please use only alphanumeric characters and these symbols: -\+ /.#` } };
    }
  }

  return null; // Valid
}
mailingAddressValidator(control: FormControl): { [key: string]: any } | null {
  const value = control.value;

  if (!value) {
    return null; // No value, no validation
  }

  const mailingAddressRegex = /^[^<>&"()#%;+]{1,1024}$/;
  const isValid = mailingAddressRegex.test(value);

  if (!isValid) {
    // Find illegal characters
    const illegalChars = value.match(/[\<>&"()#%;+]/g);
    if (illegalChars) {
      return { 'invalidMailingAddress': { 'message': `Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+` } };
    }
  }

  return null; // Valid
}
cityValidator(control: FormControl): { [key: string]: any } | null {
  const value = control.value;

  if (!value) {
    return null; // No value, no validation
  }

  const cityRegex = /^[^<>&"()#%;+]{1,129}$/;
  const isValid = cityRegex.test(value);

  if (!isValid) {
    // Find illegal characters
    const illegalChars = value.match(/[\<>&"()#%;+]/g);
    if (illegalChars) {
      return { 'invalidCity': { 'message': `Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+` } };
    }
  }

  return null; // Valid
}
stateValidator(control: FormControl): { [key: string]: any } | null {
  const value = control.value;

  if (!value) {
    return null; // No value, no validation
  }

  const stateRegex = /^[A-Za-z]{2}$/;
  const isValid = stateRegex.test(value);

  if (!isValid) {
    return { 'invalidState': { 'message': 'Please enter a 2-letter state abbreviation.' } };
  }

  return null; // Valid
}
postalCodeValidator(control: FormControl): { [key: string]: any } | null {
  const value = control.value;

  if (!value) {
    return null; // No value, no validation
  }

  const postalCodeRegex = /^[^<>&"()#%;+\-]{1,40}$/;
  const isValid = postalCodeRegex.test(value);

  if (!isValid) {
    // Find illegal characters
    const illegalChars = value.match(/[\<>&"()#%;+\-]/g);
    if (illegalChars) {
      return { 'invalidPostalCode': { 'message': `Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+-` } };
    }
  }

  return null; // Valid
}
  copyPassword(){
    this.copyPass=true;
  }
  copyUser(){
    this.copyUserId=true;
  }
  closeDialog(){
		this.closeRef(false);
    this.createUserForm.reset({
      title: '',
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      phone: '',
      mailAddress: '',
      city: '',
      state: '',
      postalCode: '',
      fax: '',
      value: 'on',
    });
   }
   disableDialog(){
    this.enableDialog=false;
    this.tempPwd='';
    this.createdUserId='';
    this.copyUserId=false;
    this.copyPass=false;
   }

   closeRef(close:boolean=false){
    let data = {
      'close':close,
    }
    this.disableDialog();
    this.ref.close(data);
  }

   getBranchCode(branchName: string) {
		return this.branchList.find((branch: Branch) => branch.name === branchName)?.code;
	}

  onSubmit(){
    this.loading=true;
    let request:UserModel={
      firstName: this.createUserForm.value.firstName,
      lastName: this.createUserForm.value.lastName,
      middleName: this.createUserForm.value.middleName,
      email: this.createUserForm.value.email,
      phone: this.createUserForm.value.phone,
      postalAddress: this.createUserForm.value.mailAddress,
      city: this.createUserForm.value.city,
      state: this.createUserForm.value.state,
      postalCode: this.createUserForm.value.postalCode,
      fax: this.createUserForm.value.fax,
      fordJobRoles: this.multiselect?this.createUserForm.value.jobroledata.map(item => item.jobRoleCode).join(CommonConstants.SEMICOLONSEPARATOR):this.createUserForm.value.jobroledata,
      language: this.createUserForm.value.language,
      fordSiteCode: this.baseGroupModel?.siteCode,
      fordStatus: this.createUserForm.value.value,
      siteCodeType:this.baseGroupModel?.siteCodeType,
      country: this.baseGroupModel?.market,
      branch: this.getBranchCode(this.baseGroupModel?.branch),
      createdBy: this.loggedinUser
    }
	const memberLoadingDelay = this.userGroupService.getLoadingDelay();
    this.userGroupService.createNewUserService(request).subscribe({
      next: data => {
       this.createdUserId=data.userId;
       this.tempPwd=data.tempPwd;
	   timer(memberLoadingDelay).subscribe(() => {
		  this.loading = false;
		  this.enableDialog = true;
		  this.createUserForm.reset();
	   });
      },
      error: error => {
        this.loading = false;
		this.enableDialog=true;
        this.responseMessage=error?.message;
      }
    });
  }

	modelHide(){
		this.closeRef(true);
	}


}

