import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
	AuditExtendRequest,
	AuditRequest,
	BaseModelRequest
} from "../manage-audit-configuration/audit-extend-request.model";
import {HttpMethods} from "../../common/http-methods.constants";
import {ClientConstants} from "../../common/client.constants";


@Injectable({
	providedIn: 'root'
})
export class AuditService {
	// baseUrl: string = environment.baseUrl;
	baseUrlGateway: string = environment.baseUrlGateway

	constructor(private _httpClient: HttpClient) { }

	updateAuditExtendDate(auditExtend:AuditExtendRequest){

		let requestBody: any = {
			"endpoint": environment.baseUrlPcf + `DealerAudit/v2/audit-config/extend-audit`,
			"configName": ClientConstants.CLIENT_3,
			"jsonPayload":auditExtend,
			"httpMethod":HttpMethods.PUT
		}
		return this._httpClient.post(this.baseUrlGateway + '/gateway/for-array', requestBody);
	}

	fetchAuditConfiguration(baseRequest:BaseModelRequest){
		let requestBody: any = {
			"endpoint": environment.baseUrlPcf + `DealerAudit/v2/audit-config/next-audit?branch=${baseRequest.branch}&ford-site-code-type=${baseRequest.fordSiteCodeType}&country=${baseRequest.country}`,
			"configName": ClientConstants.CLIENT_3,
			"jsonPayload":baseRequest,
			"httpMethod":HttpMethods.GET
		}
		return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);
	}

	createOrUpdateAudit(auditRequest:AuditRequest){
		let requestBody: any = {
			"endpoint": environment.baseUrlPcf + `DealerAudit/v2/audit-config`,
			"configName": ClientConstants.CLIENT_3,
			"jsonPayload":auditRequest,
			"httpMethod":HttpMethods.POST
		}
		return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);
	}
}
