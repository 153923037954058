export interface UserFlagRequest {
	userId: string;
	adminFlag: AdminFlag;
}

export interface AdminUser{
	firstName: string;
	lastName: string;
	adminFlag: AdminFlag;
	groupAdmin?:boolean;
	userId: string;
}

export enum AdminFlag {
	GroupAdmin = 'GroupAdmin',
	UserAdmin = 'UserAdmin'
}

export interface AdminUpdateRequest {
	branch: string;
	siteCodeType: string;
	market: string;
	siteCode: string;
	userFlagList: UserFlagRequest[];
}

export interface AdminUserRequest{
	branch: string;
	siteCodeType: string;
	market: string;
	siteCode: string;
	adminFlag: AdminFlag;
	userId: string;
}
