import {Component, Input} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {MenubarService} from '../menubar/service/menubar.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {PageType} from '../models/user-role/page-type.enum';

@Component({
	selector: 'app-title',
	templateUrl: './title.component.html',
	styleUrls: ['./title.component.scss']
})
export class TitleComponent {
	items: MenuItem[] | undefined;
	title: any;
	activeItem: MenuItem;
	@Input() userTitle: string;
	constructor(
		private menubarService: MenubarService,
		private readonly route: ActivatedRoute,
		private translate: TranslateService,
		private readonly router: Router
	) {
		this.menubarService.getData().subscribe((data) => {
			this.title = data;
		});
	}
	ngOnInit() {
		this.title = this.route.snapshot.routeConfig?.data?.title;
		this.getTranslate();
		// this.items = [
		//     {
		//         label: 'Help',
		//         items: [
		//             {
		//               label: 'SPS Users Manuals',
		//               routerLink: '/sps-users-manuals',
		//               icon: 'pi pi-book'
		//             },
		//             {
		//               label: 'Help In Other Languages',
		//               routerLink: '/help-in-other-languages',
		//               icon: 'pi pi-question-circle'
		//             }
		//         ]
		//     }
		// ];
	}

	translations: {[key: string]: string} = {};

	keys = [
		'tooltip.help',
		'item.sPSUsersManuals',
		'item.helpInOtherLanguages'
	];

	getTranslate() {
		this.translate.get(this.keys).subscribe((translatedTitles) => {
			this.translations = translatedTitles; // Assign the translations to the object

			const menuList: MenuItem[] = [
				{
					label: this.translations['item.sPSUsersManuals'],
					routerLink: ['/sps-users-manuals'],
					icon: 'pi pi-book'
				},
				{
					label: this.translations['item.helpInOtherLanguages'],
					routerLink: ['/help-in-other-languages'],
					icon: 'pi pi-question-circle'
				}
			];
			const currentUrl = this.router.url;
			this.updateActiveItem(currentUrl, menuList);
		});
	}

	updateActiveItem(url: string, menuList: MenuItem[]) {
		this.activeItem = menuList.find((item) => item.routerLink[0] === url);
		// Update the items array to reflect the active state
		this.items = menuList.map((item) => ({
			...item,
			styleClass: item === this.activeItem ? 'active-menu-item' : ''
		}));
	}

	protected readonly PageType = PageType;
}
