<div>
  <app-title></app-title>
  <div class="card flex border-round-xl p-3 mx-2 px-6 bg-color-2  shadow-3 input-container justify-content-center mb-3">
    <div class="flex flex-column col-3 justify-content-center">
      <label class="font-bold ">{{ "label.parent" | translate}} :</label>
      <input type="text" pInputText  [(ngModel)]="parent" pKeyFilter="alpha" class="w-full" [disabled]="true" />
    </div>
  </div>
  <form [formGroup]="createTempGroupForm" (ngSubmit)="onSubmit()">
  <ng-container>
    <p-fieldset styleClass="h-25rem shadow-3 custom-field">
      <ng-template pTemplate="header" class="fieldsetHeader">
          <div class="flex align-items-center">
              <span class="pi pi-plus mr-2"></span>
              <span class="font-bold text-xl">{{ "label.newGroup" | translate }}</span>
          </div>
      </ng-template>
      <div class="card">
        <div class="flex flex-wrap mb-4 pt-4">
            <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{ "label.name" | translate }}  </label><span class="text-red-500"> *</span></div>
                <input type="text" autofocus pInputText formControlName="groupName" pKeyFilter="alpha"  class="w-full" />
                <div class="absolute">
                  <small class="text-red-400" *ngIf="createTempGroupForm.get('groupName').hasError('required') && createTempGroupForm.get('groupName').touched">
                    {{"message.groupNameRequired" | translate}}
                  </small>
                  <div *ngIf="createTempGroupForm.get('groupName').hasError('invalidName')">
                    <small class="text-red-400" *ngIf="createTempGroupForm.get('groupName').getError('invalidName').illegalChars">
                      {{"message.illegalCharacters" | translate}}: {{ createTempGroupForm.get('groupName').getError('invalidName').illegalChars }}
                    </small>
                  </div>
                </div>
              </div>
            <div class="col-4">
              <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.description" | translate}}  </label><span class="text-red-500"> *</span></div>
              <input pInputText formControlName="groupDescription" pKeyFilter="alpha"  class="w-full" />
              <div class="absolute">
                <small class="text-red-400" *ngIf="createTempGroupForm.get('groupDescription').hasError('required') && createTempGroupForm.get('groupDescription').touched">
                  {{"message.groupDescRequired" | translate}}
                </small>
                <small class="text-red-400" *ngIf="createTempGroupForm.get('groupDescription').hasError('missingAlphanumeric')">
                  {{"message.groupDescMissingAlpha" | translate}}
               </small>
                <div *ngIf="createTempGroupForm.get('groupDescription').hasError('invalidName')">
                  <small class="text-red-400" *ngIf="createTempGroupForm.get('groupDescription').getError('invalidName').illegalChars">
                    {{"message.illegalCharacters" | translate}}: {{ createTempGroupForm.get('groupDescription').getError('invalidName').illegalChars }}
                  </small>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.groupOwner" | translate}} </label><span class="text-red-500"> *</span></div>
              <input pInputText id="alphabetic" formControlName="groupOwner" pKeyFilter="alpha" class="w-full" />
              <div class="absolute">
                <small class="text-red-400" *ngIf="createTempGroupForm.get('groupOwner').hasError('required') && createTempGroupForm.get('groupOwner').touched">
                  {{"message.groupOwnerRequired" | translate}}
                </small>
                <small class="text-red-400" *ngIf="createTempGroupForm.get('groupOwner').hasError('missingAlphanumeric')">
                  {{"message.groupOwnerOneLetter" | translate}}
               </small>
               <small class="text-red-400" *ngIf="createTempGroupForm.get('groupOwner').hasError('invalidNameExceedsLength')">
                {{"message.groupOwnerExceedsLength" | translate}}
              </small>
                <div *ngIf="createTempGroupForm.get('groupOwner').hasError('invalidName')">
                  <small class="text-red-400" *ngIf="createTempGroupForm.get('groupOwner').getError('invalidName').illegalChars">
                    {{"message.illegalCharacters" | translate}}: {{ createTempGroupForm.get('groupOwner').getError('invalidName').illegalChars }}
                  </small>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="flex gap-2 mt-8 justify-content-center">
        <p-button icon="pi pi-check" type="submit" label="{{'button.create' | translate}}" [disabled]="!createTempGroupForm.valid"  styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-times" label="{{'button.cancel' | translate}}" styleClass="p-button-text"></p-button>
      </div>
    </p-fieldset>

    </ng-container>
  </form>
  <p-dialog header="Header" [(visible)]="enableDialog" [modal]="true">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{"label.response" | translate}}</span>
    </ng-template>
    <div *ngIf="loadingDialog" class="flex justify-content-center">
      <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="5" animationDuration=".7s"></p-progressSpinner>
    </div>
    <p *ngIf="!loadingDialog">
     {{responseMessage}}
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-danger" (click)="closeDialog()" label="{{'button.close' | translate}}" styleClass="p-button-text"></p-button>
        <!-- <p-button icon="pi pi-danger" (click)="visible = false" label="No" styleClass="p-button-text"></p-button> -->
    </ng-template>
  </p-dialog>
</div>
