<div>
	<p-table #admindt [value]="adminUsers" [(selection)]="selectedAdminUsers" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowSelect($event)" [scrollable]="true" [paginator]="true" [rows]="EnvConstants.PAGINATION_LENGTH" [customSort]="true"
			 (sortFunction)="onSorting($event)" (onFilter)="onFilter($event)" sortField="firstName" selectionMode="multiple"
			 (onHeaderCheckboxToggle)="onRowSelect($event)" sortMode="single" dataKey="userId" rowGroupMode="subheader" groupRowsBy="status" styleClass="p-datatable-striped"
			 [rowsPerPageOptions]="[5, 10, 20]" [tableStyle]="{ 'width': '100%' }">
		<ng-template pTemplate="header" class="table-members">
			<tr>
				<th class="theader-checkbox">
					<div class="text-center">
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</div>
				</th>
				<th class="theader" pSortableColumn="userId">
					<p class="m-0 mb-2">{{"label.userID" | translate }}<p-sortIcon field="userId"></p-sortIcon></p>
					<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"

						   (input)="admindt.filter($event.target.value, 'userId', 'contains')" class="p-column-filter"
						   [value]="admindt.filters['userId']?.value">
				</th>

				<th class="theader" pSortableColumn="lastName">
					<p class="m-0 mb-2">{{"label.lastName" | translate }}<p-sortIcon field="lastName"></p-sortIcon></p>
					<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"
						   (input)="admindt.filter($event.target.value, 'lastName', 'startsWith')" class="p-column-filter"
						   [value]="admindt.filters['lastName']?.value">
				</th>
				<th class="theader" pSortableColumn="firstName">
					<p class="m-0 mb-2">{{"label.firstName" | translate }}<p-sortIcon field="firstName"></p-sortIcon></p>
					<input *ngIf="showFilter" pInputText type="text" (click)="$event.stopPropagation()"

						   (input)="admindt.filter($event.target.value, 'firstName', 'contains')" class="p-column-filter"
						   [value]="admindt.filters['firstName']?.value">
				</th>
				<th style="width: 20%" pSortableColumn="audit">
					<p class="m-0 mb-2"> {{"label.groupAdmins" | translate }} </p>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-adminUser>
			<tr>
				<td class="text-center">
					<p-tableCheckbox [value]="adminUser" ></p-tableCheckbox>
				</td>
				<td>{{adminUser.userId}}</td>
				<td>{{ adminUser.lastName }}</td>
				<td>{{ adminUser.firstName }}</td>
				<td class="text-center">
					<p-inputSwitch  styleClass="scale-down-2x" [(ngModel)]="adminUser.groupAdmin" (onChange)="onGroupAdminChange(adminUser)"></p-inputSwitch>
				</td>
			</tr>
		</ng-template>
	</p-table>
</div>

