<div [adminCheck]="PageType.SITE_RECOVERY_TOOL">
  <app-title></app-title>

  <ng-container>
    <div class="card border-round-xl p-5 mx-2 px-6 bg-color-2  shadow-3 input-containe">
      <div class="divBtn">
        <div class="col-1">
          <span for="alphabetic" class="font-bold block mb-2"> {{"label.sites" | translate}} </span>
        </div>
      </div>
      <div class="flex gap-5">
        <div class="col-5">
          <input autofocus (keyup.enter)="search()" pInputText pKeyFilter="alpha" [(ngModel)]="siteInput" class="w-full" />
          <p class="highlight">{{"message.sitesCodeMessage" | translate}} </p>
        </div>
        <div class="col-3">
          <p-button label="{{'button.search' | translate}}" (onClick)="search()" [disabled]="disableSearchButton()"></p-button>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="flex align-items-center justify-content-center" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div id="tabmenu" class="card overflow-y-auto tableStyle p-2 search-table" *ngIf="!loading && !showNoRecords">
    <site-recovery-table [adminCheck]="PageType.SITE_RECOVERY_TOOL"></site-recovery-table>
  </div>

  <p-dialog header="Header" [(visible)]="showNoRecords" [modal]="true" [style]="{ width: '50vw' }">
    <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{"label.response" | translate}}</span>
    </ng-template>
    <p>
      {{"message.noSitesRecordFound" | translate}}
    </p>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-danger" (click)="showNoRecords = false" label="{{'button.close' | translate}}" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>

  <p-dialog header="Header" [(visible)]="deleteModal" [modal]="true" [style]="{ width: '50vw' }" class="dialoghw"
    (onHide)="closeDeleteDialog()" [closable]="true">
    <ng-template pTemplate="header">
      <span class="text-xl font-bold" *ngIf="purgeButton">{{"message.purgeConfirmMessage" | translate}}</span>
      <span class="text-xl font-bold" *ngIf="!purgeButton">{{"label.response" | translate}}</span>
    </ng-template>
    <div class="deletecard">
      <ul class="custom-list" *ngIf="purgeButton">
        <li *ngFor="let data of selectedDataForDelete" class="custom-list-item">
          {{ data.fordSiteCode }}
        </li>
      </ul>
      <div class="flex align-items-center justify-content-center" *ngIf="deleteLoading">
        <p-progressSpinner></p-progressSpinner>
      </div>

      <ul class="custom-list" *ngIf="!deleteLoading && deleteAPIResponse.length > 0">
        <li *ngFor="let data of deleteAPIResponse" class="custom-list-item">
          {{ data }}
        </li>
        <li *ngIf="deleteAPIResponse.length === 0 && !deleteLoading">
          <p>{{"message.sitesError" | translate}}</p>
        </li>
      </ul>
      <ul class="custom-list" *ngIf="!purgeButton && !deleteLoading && deleteAPIResponse.length === 0">
        <li>
          <p>{{"message.sitesError" | translate}}</p>
        </li>
      </ul>
    </div>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" severity="danger" label="{{'button.purge' | translate}}" *ngIf="purgeButton" (click)="confirmDelete()"
        styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-times" label="{{'button.cancel' | translate}}" (click)="closeDeleteDialog()" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>

</div>
