import {AfterViewInit, Directive, ElementRef, inject, Input, Renderer2} from "@angular/core";
import {UserRoleService} from "./services/user-role/user-role.service";
import {PageType} from "./models/user-role/page-type.enum";
import {BaseUserGroupModel} from "../user-groups/base-usergroup.model";
import {PageAccessType} from "./models/user-role/page-access-type.enum";
import {TabModel} from "../user-groups/manage-user-groups/manage-user-groups.model";

@Directive({
	selector:'[adminCheck]'
})
export class AdminCheckDirective implements AfterViewInit{

	@Input({required:true,alias:'adminCheck'}) pageTiles: PageType | null;
	@Input() skipPageAppend:boolean;
	@Input() baseUserGroupModel:BaseUserGroupModel;
	@Input() buttonGroup:boolean
	@Input() excludeChange:boolean;
	@Input() excludeLinkChange:boolean;
	@Input() excludeFormChange:boolean;
	private element = inject(ElementRef);

	constructor(private userRoleService:UserRoleService,private renderer: Renderer2) {
		console.log(this.element);
	}

	ngAfterViewInit(): void {
		if (this.userRoleService.userRole) {
			this.checkAdminPermission();
		} else {
			this.userRoleService.ensureInitialized().then(() => {
				this.checkAdminPermission();
			});
		}
	}

	checkAdminPermission() {
		console.log(this.baseUserGroupModel);
		if(!this.pageTiles) return;
		let pageTitle: PageType = this.userRoleService.getPageTileIfBaseModel(this.pageTiles,this.baseUserGroupModel,this.skipPageAppend);
		const pageAccessType:PageAccessType = this.userRoleService.getPageAccessType(pageTitle,this.baseUserGroupModel?.branch,
			this.baseUserGroupModel?.siteCodeType,this.baseUserGroupModel?.market,
			this.baseUserGroupModel?.siteCode);
		switch (pageAccessType) {
			case PageAccessType.NO_ACCESS:
				this.renderer.removeChild(this.element.nativeElement.parentNode, this.element.nativeElement);
				break;
			case PageAccessType.READ:
				this.modifyElements(this.element);
				break;
			case PageAccessType.WRITE:
				// Currently NOT ACTION
				break;
			default:
				console.warn("Unexpected page access type:", pageAccessType);
				break;
		}
		console.log(pageAccessType,"-", pageTitle, "page access type");
	}

	modifyElements(element:ElementRef) {
		if (this.excludeChange) return;
		const nativeElement = element.nativeElement;
		if(this.buttonGroup){
			this.renderer.removeChild(this.element.nativeElement.parentNode, this.element.nativeElement);
			return;
		}
		this.applyReadOnly(nativeElement,this.excludeFormChange,this.excludeLinkChange)
	}

	private applyReadOnly(nativeElement: HTMLElement,excludeForm=false,excludeLink=false) {
		this.modifyPrimengButtons(nativeElement);
		this.modifyPrimengElements(nativeElement);
		if(!excludeForm)
			this.modifyForms(nativeElement);
		if (!excludeLink) {
			this.modifyAnchors(nativeElement);
		}
	}

	private modifyPrimengButtons(nativeElement: HTMLElement) {
		const primengButtons = nativeElement.querySelectorAll('p-button');
		primengButtons.forEach((button: any) => {
			this.modifyElement(button, {addClasses: ['p-disabled']})
			if (button.children.length > 0) {
				const firstChild = button.firstElementChild as HTMLElement;
				this.modifyElement(firstChild, {
					addClasses: ['p-disabled'],
					setProperties: { disabled: true }
				});
				if (firstChild.classList.contains('p-button-link')) {
					this.modifyElement(button, {addClasses: ['opacity-100'],})
					this.modifyElement(firstChild, {
						addClasses: ['opacity-100', 'text-color-4']
					});
				}
			}
		});
	}

	private modifyPrimengElements(nativeElement: HTMLElement) {
		const primengElementList = 'p-inputswitch,p-checkbox,p-tablecheckbox,p-tableheadercheckbox';
		const primengElements = nativeElement.querySelectorAll(primengElementList);
		primengElements.forEach((element: any) => {
			if (element.children.length > 0) {
				const firstChild = element.firstElementChild as HTMLElement;
				this.modifyElement(firstChild, {
					addClasses: ['p-disabled']
				});
			}
			this.modifyElement(element, {
				addClasses: ['p-disabled']
			});
		});
	}

	private modifyForms(nativeElement: HTMLElement) {
		const forms = nativeElement.querySelectorAll('form');
		forms.forEach((form: HTMLFormElement) => {
			this.modifyElement(form, {
				setAttributes: { readonly: 'true' }
			});
			const formControls = form.querySelectorAll('input, textarea, select');
			formControls.forEach((control: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement) => {
				this.modifyElement(control, {
					setProperties: { readOnly: true, disabled: true }
				});
			});
		});
	}

	private modifyAnchors(nativeElement: HTMLElement) {
		const anchors = nativeElement.querySelectorAll('a');
		anchors.forEach((anchor: HTMLAnchorElement) => {
			this.modifyElement(anchor, {
				setAttributes: { disabled: 'true' },
				addClasses: ['p-disabled']
			});
		});
	}

	private modifyElement(element: HTMLElement, options: { addClasses?: string[], setProperties?: { [key: string]: any }, setAttributes?: { [key: string]: any } }) {
		if (options.addClasses) {
			options.addClasses.forEach(className => this.renderer.addClass(element, className));
		}
		if (options.setProperties) {
			for (const [property, value] of Object.entries(options.setProperties)) {
				this.renderer.setProperty(element, property, value);
			}
		}
		if (options.setAttributes) {
			for (const [attribute, value] of Object.entries(options.setAttributes)) {
				this.renderer.setAttribute(element, attribute, value);
			}
		}
	}

}
