<div [adminCheck]="PageType.MANAGE_TEMP_IDS" >
  <app-title></app-title>

  <ng-container>
    <div class="card border-round-xl flex justify-content-center p-5 mx-2 px-6 gap-8 bg-color-2  shadow-3 input-container">
      <span>
          <label for="alphabetic" class="font-bold block mb-2"> {{"label.temporaryGroups" | translate}} </label>
          <p-dropdown [style]="{width:'300px'}" [options]="tempIdList"  [(ngModel)]="selectedTempGroup" (onChange)="getMembersForTempGroup()" placeholder="{{'placeholder.selectTemporaryGroup' | translate }}" optionLabel="tempName" [filter]="true" filterBy="tempName" inputId="float-label" [disabled]="loading" ></p-dropdown>
        </span>
</div>
<div class="card flex justify-content-center gap-3 mt-4" [adminCheck]="PageType.MANAGE_TEMP_IDS" [buttonGroup]="true">
  <p-button label="{{'button.getTemporaryIds' | translate}}" *ngIf="selectedTempGroup" (onClick)="getMembersForTempGroup()"></p-button>
  <p-button *ngIf="selectedTempGroup" label="{{'button.createTemporaryId' | translate}}" (click)="createTemporaryId()"></p-button>
</div>
<create-temp-id [selectedTempGroup]="selectedTempGroup?.tempName" [jobRoleList]="jobRoleList"></create-temp-id>
<edit-temp-id [selectedTempGroup]="selectedTempGroup?.tempName" [jobRoleList]="jobRoleList"></edit-temp-id>
  <div class="flex flex-column align-items-center justify-content-center" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
    <!-- <p>{{loadDescription}}</p> -->
  </div>
  <div *ngIf="!loading && members?.length>0 && activeItem==0" class="text-right relative">
    <p-button
        icon="pi pi-trash" class="deleteButton"
        label="{{'button.deleteTemporaryId' | translate}}" (click)="deleteConfirmation()" [disabled]="!selectedIdsforDelete?.length"
         />
  </div>
<div *ngIf="!loading && selectedTempGroup" class="flex flex-column">
  <p-tabView activeIndex="0" (onChange)="onTabChange($event)">

    <p-tabPanel *ngFor="let tab of tabs" [header]="tab.title">
      <ng-container *ngIf="tab.title == 'Members'">
      <p-accordion [multiple]="true" *ngFor="let c of cust; let i='index'" [activeIndex]="[0]">
        <p-accordionTab [header]="(c.title | translate) + ' ('+members?.length+')'">
          <h4 *ngIf="members?.length == 0; else membertable" class="text-center">
            {{"label.no" | translate}} {{ "title.members" | translate  }} {{"message.foundSelectedSubGroup" | translate}}  </h4>
          <ng-template #membertable>
            <temp-members-table [members]="members" (recordsCount)="getUpdatedRecordsCount($event)"  (removeTempUser)="removeTempUser($event)" ></temp-members-table>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </ng-container>
    <ng-container *ngIf="tab.title == 'Admin'">
      <admin-temp-groups  [tempGroupName]="selectedTempGroup?.tempName"/>
    </ng-container>
    </p-tabPanel>
  </p-tabView>
</div>

  </ng-container>
  <p-dialog header="Header" [(visible)]="enableDialog" [modal]="true">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{translatedResponseTitle | translate}}</span>
    </ng-template>
    <p *ngIf="!loadingDialog" class="card flex">
      <span *ngIf="!apiResponse">{{responseMessage | translate}}</span>
      <span *ngIf="apiResponse">{{apiResponse}}</span>
    </p>
    <div class="flex align-items-center justify-content-center" *ngIf="loadingDialog">
      <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
    <ng-template pTemplate="footer" class="flex justify-content-center">
        <p-button icon="pi pi-check" (click)="deleteTemporaryId()" label="{{'button.delete' | translate}}" styleClass="p-button-text" [hidden]="showDelete()"></p-button>
        <p-button icon="pi pi-danger" (click)="closeDialog()" label="{{'button.close' | translate}}" styleClass="p-button-text"></p-button>
        <!-- <p-button icon="pi pi-danger" (click)="visible = false" label="No" styleClass="p-button-text"></p-button> -->
    </ng-template>
  </p-dialog>
</div>
