import {
	AbstractControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn
} from '@angular/forms';

export function genericValidator(
	pattern: RegExp,
	errorKey: string,
	errorMessage: string,
	length?: number,
	lengthErrorMessage?: string,
	alphanumericPattern?: RegExp,
	alphanumericErrorMessage?: string
): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null;
		}

		if (length && value.length !== length) {
			return {
				[errorKey]: {message: lengthErrorMessage || 'Invalid length'}
			};
		}

		if (alphanumericPattern && !alphanumericPattern.test(value)) {
			return {
				[errorKey]: {
					message: alphanumericErrorMessage || 'Invalid characters'
				}
			};
		}

		const isValid = pattern.test(value);

		if (!isValid) {
			const illegalChars = value.match(pattern);
			if (illegalChars) {
				return {[errorKey]: {message: errorMessage}};
			}
		}

		return null;
	};
}

// Middle Initial Validator
export const middleInitialValidator = genericValidator(
	/^[a-zA-Z0-9]{3}$/, // This regex is a bit redundant now, but kept for structure
	'invalidMiddleInitial',
	'Please enter only alphanumeric characters.',
	3,
	'The middle initial can be 3 characters long.',
	/^[a-zA-Z0-9]+$/,
	'Please enter only alphanumeric characters.'
);

// Mailing Address Validator
export function mailingAddressValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			return null;
		}
		const valid = /^[^<>&"()#%;+]{1,1024}$/.test(value);
		return valid ? null : { invalidMailingAddress: 'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+.' };
	};
}

// City Validator
export function cityValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			return null;
		}
		const valid = /^[^<>&"()#%;+]{1,129}$/.test(value);
		return valid ? null : { invalidCity: 'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+.' };
	};
}
// State Validator
export function stateValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			return null;
		}
		const valid = /^[A-Za-z]{2}$/.test(value);
		return valid ? null : { invalidState: 'Please enter a 2-letter state abbreviation.' };
	};
}

// Postal Code Validator
export const postalCodeValidator = genericValidator(
	/^[^<>&"()#%;+\-]{1,40}$/,
	'invalidPostalCode',
	'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+-'
);

export function auditIntervalValidator(
	group: FormGroup
): {[key: string]: any} | null {
	const initInterval = group.get('auditInitInterval').value;
	const auditIntervalControl = group.get('auditInterval');
	const auditInterval = auditIntervalControl.value;
	if (auditInterval <= initInterval) {
		auditIntervalControl.setErrors({
			...auditIntervalControl.errors,
			auditIntervalInvalid: true
		});
		return { auditIntervalInvalid: true }; // Return null since we're setting the error directly on the control
	} else {
		if (auditIntervalControl.hasError('auditIntervalInvalid')) {
			const errors = {...auditIntervalControl.errors};
			delete errors.auditIntervalInvalid; // Remove the specific error
			auditIntervalControl.setErrors(
				Object.keys(errors).length ? errors : null
			); // Set remaining errors or null if none
			return { auditIntervalInvalid: false };
		}
		return null;
	}
}

export function notificationValidator(
	group: FormGroup
): {[key: string]: any} | null {
	const initInterval = group.get('auditInitInterval').value;
	const notificationFreq = group.get('notificationFreq');
	const notificationFreqVal = notificationFreq.value;
	if (notificationFreqVal < initInterval) {
		if (notificationFreq.hasError('notificationFreqInvalid')) {
			const errors = {...notificationFreq.errors};
			delete errors.notificationFreqInvalid; // Remove the specific error
			notificationFreq.setErrors(
				Object.keys(errors).length ? errors : null
			); // Set remaining errors or null if none
			return { notificationFreqInvalid: false };
		}
		return null;
	} else {
		notificationFreq.setErrors({
			...notificationFreq.errors,
			notificationFreqInvalid: true
		});
		return { notificationFreqInvalid: true };
	}
}

// Name Validator
export const nameValidator = genericValidator(
	/^(?=.*[a-zA-Z0-9])[^<>&"()#%;+]{1,64}$/,
	'invalidName',
	'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+',
	undefined,
	undefined,
	/[a-zA-Z0-9]/,
	'Please include at least one alphanumeric character.'
);

// Phone/Fax Validator
export function phoneFaxValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const valid = /^[a-zA-Z0-9()+./#]*$/.test(control.value);
		return valid ? null : { invalidFax: 'Please use only alphanumeric characters and these symbols: ()+ ./#' };
	};
}

export function postalValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			return null;
		}
		const valid = /^[^<>&"()#%;+\-]{1,40}$/.test(value);
		return valid ? null : { invalidPostalCode: 'Please use only alphanumeric characters and spaces. These symbols are not allowed: <>&"()#%;+-.' };
	};
}

// Telephone Number Validator
export function telephoneNumberValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			// Allow empty values
			return null;
		}
		const valid = /^[a-zA-Z0-9()+./#]*$/.test(value);
		return valid ? null : { invalidTelephoneNumber: 'Please use only alphanumeric characters and these symbols: ()+ ./#' };
	};
}

// Fax Validator
export function faxValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;
		if (!value) {
			// Allow empty values
			return null;
		}
		const valid = /^[a-zA-Z0-9()+./#]*$/.test(value);
		return valid ? null : { invalidFax: 'Please use only alphanumeric characters and these symbols: ()+ ./#' };
	};
}
