import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ManageTemporaryIdService } from '../services/manage.temporary.id.service';

interface SupportCenter {
  name: string,
  code: string
}

@Component({
  selector: 'create-temporary-group',
  templateUrl: './create-temporary-group.component.html',
  styleUrls: ['./create-temporary-group.component.scss']
})
export class CreateTemporaryGroupComponent {


  supportCenter!: SupportCenter[];
  parent:string = '';
  createTempGroupForm:FormGroup;
  enableDialog:boolean=false;
  responseMessage:string='';
  loadingDialog:boolean=false;
  ngOnInit() {
      this.supportCenter = [
          {name: 'Support-Centers-1', code: 'VANTEC'},
          {name: 'Support-Centers-2', code: 'QLNTEC'},
          {name: 'Support-Centers-3', code: 'SVCTEC'},
          {name: 'Support-Centers-4', code: 'CRGTEC'},
          {name: 'Support-Centers-5', code: 'APPTEC'}
      ];

      this.translate.get('label.temporary').subscribe((translatedValue: string) => {
        this.parent = translatedValue; // Set the translated value to ngModel
      });
  }

  constructor(private translate: TranslateService,private fb: FormBuilder, private manageTempService:ManageTemporaryIdService) {
    this.createTempGroupForm = this.fb.group({
      groupOwner: new FormControl('',[ Validators.required,this.ownerValidator]),
      groupName: new FormControl('', [Validators.required,this.nameValidator]),
      groupDescription: new FormControl('', [Validators.required,this.nameValidator]),
    });
  }
  nameValidator(control: FormControl): { [key: string]: any } | null {
    const nameRegex = /^(?=.*[a-zA-Z0-9])[^<>&"()#%;+]{1,64}$/;
    const value = control.value;
    if (!value) {
      return null; // No value, no validation
    }
    const isValid = nameRegex.test(value);
    if (!isValid) {
      // Find illegal characters
      const illegalChars = value.match(/[\<>&"()#%;+]/g);
      if (illegalChars) {
        return { 'invalidName': { 'illegalChars': illegalChars.join(', ') } };
      }
    }
    // Check for alphanumeric characters
    if (!/[a-zA-Z0-9]/.test(value)) {
      return { 'missingAlphanumeric': true };
    }
    return null; // Valid
  }
  ownerValidator(control: FormControl): { [key: string]: any } | null {
    const nameRegex = /^[a-zA-Z0-9]{8}$/;
    const value = control.value;
    if (!value) {
      return null; // No value, no validation
    }
    const isValid = nameRegex.test(value);
    if (!isValid) {
      // Find illegal characters
      const illegalChars = value.match(/[\<>&"()#%;+]/g);
      if (illegalChars) {
        return { 'invalidName': { 'illegalChars': illegalChars.join(', ') } };
      }
      const exceedsLength=value.length>8;
      if(exceedsLength){
        return { 'invalidNameExceedsLength': true };
      }
    }
    // Check for alphanumeric characters
    if (!/[a-zA-Z0-9]/.test(value)) {
      return { 'missingAlphanumeric': true };
    }
    return null; // Valid
  }
  onSubmit(){
    this.enableDialog=true;
    this.loadingDialog=true;
    const formData=this.createTempGroupForm.value;
    const request={
      groupName:formData.groupName,
      groupDesc:formData.groupDescription,
      groupOwner:formData.groupOwner
    }
    this.manageTempService.createTempGroup(request).subscribe({
      next: (response) => {
        this.responseMessage=response.value;
        this.loadingDialog=false;
      },
      error: (error) => {
        console.error(error);
        this.responseMessage=error?.message;
        this.loadingDialog=false;
      }
    });
  }
  closeDialog(){
    this.enableDialog=false;
    this.createTempGroupForm.reset();
  }
}
