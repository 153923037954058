import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { MenuModule } from "primeng/menu";
import { BadgeModule } from "primeng/badge";
import { RippleModule } from "primeng/ripple";
import { AvatarModule } from "primeng/avatar";
import { StyleClassModule } from "primeng/styleclass";
import { TreeSelectModule } from "primeng/treeselect";
import { TabViewModule } from "primeng/tabview";
import { TableModule } from "primeng/table";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { AccordionModule } from "primeng/accordion"
import { CheckboxModule } from "primeng/checkbox"
import{SplitButtonModule} from 'primeng/splitbutton';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {TreeModule} from "primeng/tree";
import {RadioButtonModule} from "primeng/radiobutton";
import {CalendarModule} from "primeng/calendar";
import {FieldsetModule} from "primeng/fieldset";
import { DividerModule } from 'primeng/divider';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitterModule } from 'primeng/splitter';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { MessagesModule } from 'primeng/messages';
import {MessageModule} from "primeng/message";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {InputSwitchModule} from "primeng/inputswitch";
import {TooltipModule} from 'primeng/tooltip';
import {DialogService, DynamicDialogModule} from "primeng/dynamicdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {CommonModule} from "@angular/common";
import {TitleComponent} from "./title/title.component";
import {BrowserModule} from "@angular/platform-browser";
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { LanguageTranslationService } from './service/language.translation.service';

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }

// export function initializeApp(translationService: LanguageTranslationService) {
//   return () => translationService.initializeTranslations();
// }
import {ChipModule} from "primeng/chip";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {AdminCheckDirective} from "./admin-check.directive";
import {AdminFilterPipe} from "./admin-filter.pipe";
import { CardModule } from 'primeng/card';
import {EnterKeyFilterDirective} from "./force-enter.directive";

@NgModule({
	imports: [
		CommonModule,
		ButtonModule,
		MenubarModule,
		MenuModule,
		CardModule,
		BadgeModule,
		RippleModule,
		AvatarModule,
		PanelMenuModule,
		TreeSelectModule,
		SidebarModule,
		StyleClassModule,
		ToastModule,
		TabViewModule,
		TableModule,
		BreadcrumbModule,
		AccordionModule,
		CheckboxModule,
		SplitButtonModule,
		DropdownModule,
		DialogModule,
		InputTextModule,
		ProgressSpinnerModule,
		TreeModule,
		RadioButtonModule,
		CalendarModule,
		FieldsetModule,
		DividerModule,
		MultiSelectModule,
		SelectButtonModule,
		SplitterModule,
		ClipboardModule,
		MessagesModule,
		MessageModule,
		OverlayPanelModule,
		InputSwitchModule,
		TooltipModule,
		DynamicDialogModule,
		TranslateModule,
		// TranslateModule.forRoot({
		// 	loader: {
		// 		provide: TranslateLoader,
		// 		useFactory: HttpLoaderFactory,
		// 		deps: [HttpClient]
		// 	}
		// }),
		DynamicDialogModule,
		ChipModule,
		ConfirmDialogModule
	],
	declarations:[
		TitleComponent,
  ConfirmDialogComponent,
		AdminCheckDirective,
		AdminFilterPipe,
		EnterKeyFilterDirective
	],
	providers:[
		DialogService,
		MessageService,
		ConfirmationService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeApp,
    //   deps: [LanguageTranslationService],
    //   multi: true
    // },
	AdminFilterPipe
	],
	exports:[
		CommonModule,
		ButtonModule,
		MenubarModule,
		MenuModule,
		BadgeModule,
		RippleModule,
		AvatarModule,
		PanelMenuModule,
		TreeSelectModule,
		SidebarModule,
		StyleClassModule,
		ToastModule,
		TabViewModule,
		TableModule,
		BreadcrumbModule,
		AccordionModule,
		CheckboxModule,
		SplitButtonModule,
		DropdownModule,
		DialogModule,
		InputTextModule,
		ProgressSpinnerModule,
		TreeModule,
		RadioButtonModule,
		CalendarModule,
		FieldsetModule,
		DividerModule,
		MultiSelectModule,
		SelectButtonModule,
		SplitterModule,
		ClipboardModule,
		MessagesModule,
		MessageModule,
		OverlayPanelModule,
		InputSwitchModule,
		TooltipModule,
		DynamicDialogModule,
		TitleComponent,
		TranslateModule,
		ChipModule,
		ConfirmDialogModule,
		AdminCheckDirective,
		AdminFilterPipe,
		EnterKeyFilterDirective,
		CardModule
	]
})
export class SharedCommonModule { }
