import { Component } from '@angular/core';
import { OTHER_LANGUAGE_COLUMNS, OTHER_LANGUAGE_MANUAL } from '../help-manual-constants';
import {AdministrationService} from "../../services/administration.service";
import {finalize} from "rxjs";

@Component({
  selector: 'help-other-languages',
  templateUrl: './help-other-languages.component.html',
  styleUrls: ['./help-other-languages.component.scss']
})
export class HelpOtherLanguagesComponent {

	isLoading = false;

	constructor(private adminService: AdministrationService) {
	}

  otherLanguageColumns = OTHER_LANGUAGE_COLUMNS;
  otherLanguageManual = OTHER_LANGUAGE_MANUAL;

  downloadManual(data) {
	  const fileName = data?.link;
	  data.isLoading = true;
	  if (fileName) {
		  this.adminService.downloadManual(fileName).pipe(
			  finalize(() => data.isLoading = false)
		  ).subscribe({
			  next : (response:any) => {
				  // const blob = new Blob([response?.message], { type: 'application/pdf' });
				  const blobUrl = window.URL.createObjectURL(response);
				  const link = document.createElement('a');
				  link.href = blobUrl;
				  link.download = fileName;
				  link.click();
				  window.URL.revokeObjectURL(blobUrl);
				  // throw new Error("data is not valid");
			  },
			  error: (error) => {
				  console.error('Error downloading file:', error);
			  }
		  });
	  }
  }
}
