/**This file contains a constant object that has properties that are necessary
 * for the build found in the `dev` configuration in `angular.json`.
 */

export const environment = {
	production: true,
    baseUrlGateway: 'https://spsedu-angular-caas.apps.pd105.caas.gcp.ford.com/api/v1',
	baseUrlPcf: 'https://api.stg01i.gcp.ford.com/', //need to check url
	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2',
	openidClientId: 'urn:sps:edu',
	openidResource: 'urn:sps:weblogin:edu',
	baseUrlSPSUtil: 'https://api.stg01i.gcp.ford.com/spsUtil/api/v1/',
	baseUrlPcfSiteStructure: 'https://api.stg01i.gcp.ford.com/sitestructure',
	client_1_scope: '136c9f6a-f453-4214-a5bd-a04979ec1104/.default', 
	client_2_scope: 'b217e53c-20ac-4ab3-abc1-4e518430d964/.default', //User management
	client_3_scope: 'bf9b057f-dd75-4928-8768-5acb7fffc163/.default', //Dealer audit
	client_4_scope: '82c25c6f-de80-4d6c-aca3-074c5607e009/.default', //Sitecodecreation
	client_5_scope: 'fb4966ca-c4ac-40ee-9e9d-995a49a04fa8/.default', //SPSUtils - Supportcenter
	client_6_scope: 'a8eb3c7d-4563-4693-a885-94310b7b7c6e/.default', //Site structure-fds
	client_7_scope: 'b0359e09-4b21-4ee2-b168-0c591f931c9d/.default', //transfer
	client_8_scope: '5b9b3ba3-f9d5-462a-99fb-ef55d14f37c3/.default', //retiree
	projectId: 'ford-d2e87a01f6dcc2a137c2fc06',
	adfs_issuer_uri: 'https://corpqa.sts.ford.com/adfs/services/trust',
	adfs_audience: 'urn:sps:weblogin:edu',
	adfs_jwk_set_uri: 'https://corpqa.sts.ford.com/adfs/discovery/keys',
	changePasswordUrl: 'https://wwwqa.changepassword.ford.com/',
	aga_url: 'https://wwwedu.accessmgmt.dealerconnection.com/CspsWebJSF/xhtml/requestAccess/requestAccess.faces',
	memberLoadingDelay:45000,
	tableDefaultSize:10,
	updateConfig(updates: Partial<Omit<any, 'updateConfig'>>) {
		console.log('updates........', JSON.parse(JSON.stringify(updates)));
		Object.assign(this, updates);
		console.log('this........', JSON.parse(JSON.stringify(this)));
		return this;
	}
};
