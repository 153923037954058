import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { SupportCenterService } from './service/support-center-service';


export interface SupportCenter {
  siteCodeType: string,
  marketCode: string,
  email: string,
  phone: string,
  fax: string,
}

export interface SupportCenterData {
  siteCodeType: string,
  marketCode: string,
  dealerCode: boolean,
}


@Component({
  selector: 'support-center',
  templateUrl: './support-center.component.html',
  styleUrls: ['./support-center.component.scss']
})
export class SupportCenterComponent {

  @Input() notifier: BehaviorSubject<SupportCenterData | null> = new BehaviorSubject<SupportCenterData | null>(null);
  supportCenterForm: FormGroup;
  supportCenterModal: boolean = false;
  loading: boolean = false;
  private destroy$ = new Subject<void>();
  backendApiResponse: string;

  siteCodeType: string;
  marketCode: string;
  dealerCode: boolean;

  constructor(private fb: FormBuilder, private supportCenterService: SupportCenterService,private changeDetectorRef: ChangeDetectorRef,) {
    this.supportCenterForm = this.fb.group({
      email: [''],
      phone: [''],
      fax: [''],
    });
  }

  ngOnInit() {
    this.notifier.pipe(takeUntil(this.destroy$)).subscribe((supportCenterData: SupportCenterData) => {
      this.siteCodeType = supportCenterData.siteCodeType;
      this.marketCode = supportCenterData.marketCode;
      this.dealerCode = supportCenterData.dealerCode;
    });

    this.getSupportCenterDetails();
  }

  onSubmitSupportCenter() {
    this.supportCenterModal = true;
    this.loading = true;
    var supportCenter: SupportCenter = this.supportCenterForm.value;
    supportCenter.siteCodeType = this.siteCodeType;
    supportCenter.marketCode = this.marketCode;
    this.supportCenterService.updateSupportCenter(supportCenter).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.backendApiResponse = data.message;
          this.loading = false;
          this.changeDetectorRef.markForCheck();
          this.getSupportCenterDetails();

        }, error: error => {
          this.loading = false;
          this.changeDetectorRef.markForCheck();
          this.backendApiResponse = 'The backend API have been failed to update support center details.';
          this.getSupportCenterDetails();
        }
      });
  }

  getSupportCenterDetails() {
    this.resetForm();
    this.supportCenterService.getSupportCenterDetails(this.siteCodeType, this.marketCode).pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          if (data) {
            this.supportCenterForm.patchValue({
              siteCodeType: data.siteCodeType,
              marketCode: data.marketCode,
              supportCenterCode: data.supportCenterCode,
              email: data.email,
              phone: data.phone,
              fax: data.fax,
            });
          }
        }, error: error => {
        }
      });
  }

  closeSupportCenterDialog() {
    this.loading = false;
    this.supportCenterModal = false;
  }

  resetForm(): void {
    this.supportCenterForm.reset();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
