import { Component } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { SiteRecoveryServiceService } from '../site-recovery-service/site-recovery-service.service';
import { SiteData } from '../site-recovery.component';
import {EnvConstants} from "../../../common/env.constants";

@Component({
  selector: 'site-recovery-table',
  templateUrl: './site-recovery-table.component.html',
  styleUrls: ['./site-recovery-table.component.scss']
})

export class SiteRecoveryTableComponent {
  siteDataList?: SiteData[] = [];
  selectedRows?: SiteData[] = [];
  private destroy$ = new Subject<void>();
  editModal: boolean = false;
  editableDataNotifier: BehaviorSubject<SiteData | null> = new BehaviorSubject<SiteData | null>(null);
  constructor(private siteRecoveryServiceService: SiteRecoveryServiceService) {

  }

  ngOnInit() {
    this.getSitesData();
    this.getSelectedRows();
  }


  getSitesData(): void {
    this.selectedRows = [];
    this.siteDataList = [];
    this.siteRecoveryServiceService.getSitesData().pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.siteDataList = data;
    });
  }


  public onClickEdit(data: SiteData): void {
    this.selectedRows = [];
    this.siteRecoveryServiceService.setSelectedRows([]);
    this.editModal = true;
    this.siteRecoveryServiceService.editableModal(true);
    this.editableDataNotifier.next({
      fordSiteCode: data.fordSiteCode,
      branch: data.branch,
      fordSiteCodeType: data.fordSiteCodeType,
      market: data.market,
      description: data.description,
      fordLastModifiedBy: data.fordLastModifiedBy,
      postalAddress: data.postalAddress,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      telephoneNumber: data.telephoneNumber,
      facsimileTelephoneNumber: data.facsimileTelephoneNumber,
      preferredLanguage: data.preferredLanguage,
      fordSalesCode: data.fordSalesCode,
      fordParentOrganization: data.fordParentOrganization,
      fordCsdCode: data.fordCsdCode,
      fordstatus: data.fordstatus.toLowerCase(),
      labeledURI: data.labeledURI,
      fmccRegion: data.fmccRegion,
      fmccLessor: data.fmccLessor,
      fmccBrand: data.fmccBrand,
      fmccBCode: data.fmccBCode,
      fmccFinLab: data.fmccFinLab,
      fmccSbCode: data.fmccSbCode,
      fmccMkt: data.fmccMkt,
      fmccpGroup: data.fmccpGroup,
      fmccGroup: data.fmccGroup,
      fmccSt: data.fmccSt,
      fmccClass: data.fmccClass,
      lastUpdatedDate: data.lastUpdatedDate,
      structure: data.structure,
      dealershipName: data.dealershipName
    	});
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  delete() {
    this.siteRecoveryServiceService.setSelectedRows(this.selectedRows);
  }

  getSelectedRows() {
    this.siteRecoveryServiceService.getSelectedRows().pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.selectedRows = data;
    });
  }

    protected readonly EnvConstants = EnvConstants;
}
