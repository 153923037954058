import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, take} from 'rxjs';
import {ClientConstants} from 'src/app/common/client.constants';
import {HttpMethods} from 'src/app/common/http-methods.constants';
import {environment} from 'src/environments/environment';
import {BaseUserGroupModel} from "../../user-groups/base-usergroup.model";
import {HeaderUtilityService} from "../../common/service/header-utility.service";
import {PageType} from "../../common/models/user-role/page-type.enum";
import {IGatewayRequest} from "../../../../appUtil/interface/GatewayRequest";

@Injectable({
	providedIn: 'root'
})
export class AdministrationService {
  // baseUrl:string = environment.baseUrl;
  baseUrlGateway:string = environment.baseUrlGateway

	constructor(private _httpClient: HttpClient,private headerUtility:HeaderUtilityService) {}

	private visible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);
	public visible$ = this.visible.asObservable();

	private edit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false
	);
	public edit$ = this.edit.asObservable();

	private jobrole: BehaviorSubject<any> = new BehaviorSubject<any>([]);
	public jobrole$ = this.jobrole.asObservable();

	private dialogTitle: BehaviorSubject<string> = new BehaviorSubject<string>(
		''
	);
	public dialogTitle$ = this.visible.asObservable();

	private roleList: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
	public roleList$ = this.roleList.asObservable();

	private branch: BehaviorSubject<any> = new BehaviorSubject<any>([]);
	public branch$ = this.branch.asObservable();

	private siteCodeType: BehaviorSubject<any> = new BehaviorSubject<any>([]);
	public siteCodeType$ = this.siteCodeType.asObservable();

	private refreshJobroles: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	public refreshJobroles$ = this.refreshJobroles.asObservable();

	private market: Subject<any> = new Subject<any>();
	public market$ = this.market.asObservable();

	updateJobRefresh(data: boolean) {
		this.refreshJobroles.next(data);
	}
	getJobRefresh() {
		return this.refreshJobroles.asObservable();
	}

	updateSiteCodeType(data: any) {
		this.siteCodeType.next(data);
	}
	getSiteCodeType() {
		return this.siteCodeType.asObservable();
	}

	updateBranch(data: any) {
		this.branch.next(data);
	}
	getBranch() {
		return this.branch.asObservable();
	}

	updateMarket(data: any) {
		this.market.next(data);
	}
	getMarket() {
		return this.market.asObservable();
	}
	updateJobroleList(data: any) {
		this.roleList.next(data);
	}
	getJobroleList() {
		return this.roleList.asObservable();
	}
	editModal(data: boolean) {
		this.edit.next(data);
	}

	getEditableModal() {
		return this.edit.asObservable();
	}
	updateData(data: boolean) {
		this.visible.next(data);
	}

	getData() {
		return this.visible.asObservable();
	}

	updateEditData(data: boolean) {
		this.jobrole.next(data);
	}

	getEditData() {
		return this.jobrole.asObservable();
	}

	updateDialogTitle(data: string) {
		this.dialogTitle.next(data);
	}

	getDialogTitle() {
		return this.dialogTitle.asObservable();
	}
	// getBranches(): Observable<any> {
	//   return this._httpClient.get<any>(this.baseUrl + '/getBranches');
	// }
	getSiteCodeTypes(branch: any,excludeSiteCodeAdmins=false): Observable<any> {
		const requestBody: any = {
			endpoint:
				environment.baseUrlPcf +
				'sitestructure/api/v1/fds/site-code-types/' +
				branch.branchName,
			configName: ClientConstants.CLIENT_6
		};
		if(excludeSiteCodeAdmins){
			requestBody.headers = {
				'X-ExcludeSiteCodeAdmins':'Yes'
			}
		}
		return this._httpClient
			.post(this.baseUrlGateway + '/gateway/for-map', requestBody)
			.pipe(take(1));
		// return this._httpClient.get<any>(this.baseUrl + '/fds/site-code-types/' + branch.branchName).pipe(take(1));
	}
	getMarkets(branch: any, siteCodeType: any,excludeSiteCodeAdmins=false): Observable<any> {
		const requestBody: any = {
			endpoint:
				environment.baseUrlPcf +
				'sitestructure/api/v1/fds/markets/' +
				branch.branchName +
				'/' +
				siteCodeType.siteCodeType,
			configName: ClientConstants.CLIENT_6
		};
		if(excludeSiteCodeAdmins){
			requestBody.headers = {
				'X-ExcludeSiteCodeAdmins':'Yes'
			}
		}
		return this._httpClient.post(
			this.baseUrlGateway + '/gateway/for-map',
			requestBody
		);
		// return this._httpClient.get<any>(this.baseUrl + '/fds/markets/'+branch.branchName+'/'+siteCodeType.siteCodeType);
	}

	getSiteCodes({branch,siteCodeType, market}:BaseUserGroupModel,pageTitle:PageType): Observable<any> {
		const requestBody: any = {
			endpoint:
				environment.baseUrlPcf +
				'sitestructure/api/v1/fds/site-codes/' +
				branch +
				'/' +
				siteCodeType +
				'/' + market,
			"headers": this.headerUtility.buildPageTitleHeader(pageTitle),
			configName: ClientConstants.CLIENT_6
		};

		return this._httpClient.post(
			this.baseUrlGateway + '/gateway/for-map',
			requestBody
		);
		// return this._httpClient.get<any>(this.baseUrl + '/fds/markets/'+branch.branchName+'/'+siteCodeType.siteCodeType);
	}
	getJobRoles(requestBody: any): Observable<any> {
		const requestBodyGateway: any = {
			endpoint:
				environment.baseUrlPcf +
				'sitestructure/api/v1/job-role/manage-job-role?branch=' +
				requestBody.branchCode +
				'&ford-site-code-type=' +
				requestBody.siteCodeType +
				'&country=' +
				requestBody.marketName,
			configName: ClientConstants.CLIENT_6
		};
		return this._httpClient.post(
			this.baseUrlGateway + '/gateway/for-map',
			requestBodyGateway
		);
		// return this._httpClient.get<any>(this.baseUrl + '/job-role/manage-job-role/'+requestBody.branchCode+'/'+requestBody.siteCodeType+'/'+requestBody.marketName);
	}
	saveJobRole(requestBody: any): Observable<any> {
		const requestBodyGateway: any = {
			endpoint:
				environment.baseUrlPcf +
				'sitestructure/api/v1/job-role/manage-job-role',
			configName: ClientConstants.CLIENT_6,
			httpMethod: HttpMethods.POST,
			jsonPayload: requestBody
		};
		return this._httpClient.post(
			this.baseUrlGateway + '/gateway/for-map',
			requestBodyGateway
		);
		// return this._httpClient.post<any>(this.baseUrl + '/job-role/manage-job-role',requestBody);
	}
	updateJobRole(requestBody: any): Observable<any> {
		const requestBodyGateway: any = {
			endpoint:
				environment.baseUrlPcf +
				'sitestructure/api/v1/job-role/manage-job-role',
			configName: ClientConstants.CLIENT_6,
			httpMethod: HttpMethods.PUT,
			jsonPayload: requestBody
		};
		return this._httpClient.post(
			this.baseUrlGateway + '/gateway/for-map',
			requestBodyGateway
		);
		// return this._httpClient.put<any>(this.baseUrl + '/job-role/manage-job-role',requestBody);
	}
	deleteJobRole(
		branchCode: any,
		siteCodeType: any,
		countryCode: any,
		jobRoleCode: any,
		jobRoleName: any
	): Observable<any> {
		const requestBodyGateway: any = {
			endpoint:
				environment.baseUrlPcf +
				'sitestructure/api/v1/job-role/manage-job-role?branch=' +
				branchCode +
				'&ford-site-code-type=' +
				siteCodeType +
				'&country=' +
				countryCode +
				'&job-role-code=' +
				jobRoleCode +
				'&job-role-name=' +
				jobRoleName,
			configName: ClientConstants.CLIENT_6,
			httpMethod: HttpMethods.DELETE
		};
		return this._httpClient.post(
			this.baseUrlGateway + '/gateway/for-map',
			requestBodyGateway
		);
		// return this._httpClient.delete<any>(this.baseUrl + '/job-role/manage-job-role/'+branchCode+'/'+siteCodeType+'/'+countryCode+'/'+jobRoleCode+'/'+jobRoleName);
	}

	/*getUserAttributes(url: string): Observable<any> {
		return this._httpClient.get(this.baseUrl + url);
	}*/

	getRequestForGateway(clientConfig: string): Observable<any> {
		const requestBody: any = {
			endpoint: this.replaceHostName(
				"",
				environment.baseUrlPcfSiteStructure
			),
			configName: clientConfig
		};
		return this._httpClient
			.post(this.baseUrlGateway + '/gateway/for-map', requestBody)
			.pipe(take(1));
	}

	replaceHostName(url: string, newHostName: string): string {
		try {
			const parsedUrl = new URL(url);
			const targetUrl = new URL(newHostName);
			if (parsedUrl.hostname !== targetUrl.hostname) {
				parsedUrl.href = targetUrl.href + parsedUrl.pathname;
			}
			return parsedUrl.toString();
		} catch (error) {
			console.error('Invalid URL:', error);
			return url;
		}
	}

	downloadManual(fileName: any) {
		const requestBody: IGatewayRequest = {
			endpoint: environment.baseUrlSPSUtil + 'supportCenter/blob-details?fileName=' + fileName,
			configName: ClientConstants.CLIENT_5,
			httpMethod: 'GET'
		};
		return this._httpClient
			.post(this.baseUrlGateway + '/gateway/for-map', requestBody,{responseType:"blob"})
			.pipe(take(1));
	}
}
