import {Component, OnDestroy} from '@angular/core';
import { AdministrationService } from '../../services/administration.service';
import { MenubarService } from 'src/app/common/menubar/service/menubar.service';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import {finalize, Observable, Subject, Subscribable, Subscription, take, takeUntil} from 'rxjs';
import {PageType} from "../../../common/models/user-role/page-type.enum";
import {BranchModel} from "../../../common/models/branch.model";
import {UserGroupsService} from "../../../user-groups/services/user-groups.service";


@Component({
  selector: 'manage-job-roles',
  templateUrl: './manage-job-roles.component.html',
  styleUrls: ['./manage-job-roles.component.scss']

})


export class ManageJobRolesComponent implements OnDestroy{

  enableDialog: boolean = false;
  isCollapsed = false;
  selectedBranch: any;
  selectedSiteCodeType: any;
  selectedMarket: any;
  jobRoleCode: string | undefined;
  jobRoleName: string | undefined;
  loading: boolean = false;
  results: boolean = false;
  visible: boolean = false;
  errorMessage: string = '';
  jobRoleList: any[] = [];
  branchesList: any[];
  siteCodeTypeList: any[] = []
  marketList: any[] = []
  onDestroy$: Subject<void> = new Subject();
	isBranchLoading:boolean;
  constructor(private menubarService: MenubarService, private adminService: AdministrationService,
  			private userGroupService:UserGroupsService) {
    this.menubarService.isCollapsed$.pipe(takeUntil(this.onDestroy$)).subscribe(isCollapsed => {
      this.isCollapsed = isCollapsed;
    });
	this.isBranchLoading = true;
	  this.userGroupService.fetchBranchList()
		  .pipe(finalize(()=>this.isBranchLoading = false))
		  .subscribe((branchList:BranchModel[])=>{
		  this.branchesList = branchList;
	  })
   this.adminService.getJobRefresh().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      if (data) {
        this.getJobRoles();
      }
    });
  }

  ngOnDestroy(){
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  getStyle(){
    if(this.results){
      return "auto;"
    }else{
      return "hidden"
    }
  }
  showDialog() {
    this.visible = true;
    this.adminService.updateData(this.visible);
    this.adminService.updateDialogTitle("Create Job Role");
    this.adminService.updateBranch(this.selectedBranch);
    this.adminService.updateSiteCodeType(this.selectedSiteCodeType);
  }

  public onChangeBranch(): void {
    this.results=false
    this.selectedSiteCodeType=null;
    this.selectedMarket=null;
    this.marketList=[];
    this.adminService.updateBranch(this.selectedBranch);
    this.loading=true;
    this.adminService.getSiteCodeTypes(this.selectedBranch).subscribe(
      {
        next: data => {
          this.siteCodeTypeList = data.results.map((item: any) => {
            return {
              siteCodeType: item.value};
            })
            this.loading=false
        },
        error: error => {
          this.loading = false;
          this.results = false;
          this.errorMessage=error?.message;
          this.enableDialog=true;
        }
      });
  }

  disableActionButton() {
    return this.selectedBranch && this.selectedSiteCodeType ? false : true;
  }

  public onChangeSiteCodeType(): void {
    this.adminService.updateSiteCodeType(this.selectedSiteCodeType);
    this.results=false
    this.selectedMarket=null;
    this.loading=true;
    this.adminService.getMarkets(this.selectedBranch,this.selectedSiteCodeType).subscribe({
        next: data => {
          this.marketList = data.results.map((item: any) => {
            return {
              marketName: item.value};
            })
            this.marketList.unshift({marketName: 'ALL'});
            this.loading=false;
            this.selectedMarket = this.marketList.length>0 ? this.marketList[0] : null;
            this.getJobRoles();
        },
        error: error => {
          this.loading = false;
          this.results = false;
          this.errorMessage=error?.message;
          this.enableDialog=true;
        }
      });
  }

  public onChangeMarket(): void {
    this.adminService.updateMarket(this.selectedMarket);
    this.loading=true;
    this.getJobRoles();
  }

  updateGroupData(){
        this.adminService.updateBranch(this.selectedBranch);
        this.adminService.updateSiteCodeType(this.selectedSiteCodeType);
        this.adminService.updateMarket(this.selectedMarket);
  }
  errorShowMessage(data:any){
    this.errorMessage=data.errorMessage,
    this.enableDialog=true;
  }
  public getJobRoles(): void {

    this.loading = true;
    this.results = false

    let requestBody = {
      "branchCode": this.selectedBranch.branchCode,
      "siteCodeType": this.selectedSiteCodeType.siteCodeType,
      "marketName": this.selectedMarket?.marketName ? this.selectedMarket.marketName : 'ALL'
    }
    this.adminService.getJobRoles(requestBody).subscribe({
      next: data => {
        this.loading = false;
        this.results = data?.jobRoleResponseList?.length>0;
        this.jobRoleList=data?.jobRoleResponseList;
        this.adminService.updateJobroleList(data?.jobRoleResponseList);
        this.adminService.updateJobRefresh(false);
        this.updateGroupData();
        data?.errorMessage ? this.errorShowMessage(data): this.errorMessage='';
      },
      error: error => {
        this.loading = false;
        this.results = false;
        this.errorMessage="No job role found for this criteria";
        this.enableDialog=true;
        this.adminService.updateJobroleList([]);
        this.adminService.updateJobRefresh(false);
        this.updateGroupData();
      }
    });
  }

    protected readonly PageType = PageType;
}


