import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {UserIdService} from '../services/user-id.service';
import {AuthService} from "../services/auth.service";

/** Interface used to represent the contents of a JWT that we leverage in this class */
export interface JSONTokenObject {
	/** The user's CommonName attribute */
	CommonName: string;
	/** Token exipration timestamp */
	exp: string;
	/** Token issue timestamp */
	iat: string;
}

/** This class is utilized to create a route path that potentially includes queryParams
 */
export class RedirectUrlOptions {
	/** The path segment of the redirect url */
	path: string;
	/** The query parameter segment of the redirect url parsed as an object*/
	queryParams: object;

	/** Creates an instance of RedirectUrlOptions and instantiates path and queryParams.
	 */
	constructor() {
		this.path = '';
		this.queryParams = {};
	}
}

/** This guard is used in the oauth callback flow, consuming the URL before the Angular router can.
 * The URL built by the auth provider is technically invalid (no ? for the queryParams), so this guard
 * needs to consume the URL before Angular router (which would fail to parse it).
 */
@Injectable()
export class OauthTokenGuard implements CanActivate {
	/** Creates an instance of the OauthTokenGuard
	 *
	 * @param router route instance for current routing params
	 * @param idService the angular service for handling user ID
	 */
	constructor(
		private router: Router,
		private idService: UserIdService,
		private authService:AuthService
	) {}

	/** the actual guard function. Parses the queryString and stores the params in sessionStorage.
	 * Redirects the user to the default route, or to the route that was stored before the auth redirect.
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @returns whether route can be activated or not
	 */
	canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
		const queryParamsObj = this.parseParamString(route.fragment);

		if (queryParamsObj && queryParamsObj['access_token'] != null) {
			//token is part 2 of a JWT (index 1)
			const accessToken = atob(
				queryParamsObj['access_token'].split('.')[1]
			);
			const idToken = queryParamsObj['id_token'];
			const jsonToken = JSON.parse(accessToken);

			//store off token information in the appropriate places
			this.storeTokenInformation(
				accessToken,
				queryParamsObj['access_token'],
				jsonToken,
				idToken
			);
			this.idService.setUserId(jsonToken.CommonName);
			this.authService.setLoggedInUserName();
			const urlSegments = this.extractRedirectUrlSegments(
				sessionStorage.getItem('redirectURL')
			);
			return this.router.navigate([urlSegments.path], {
				queryParams: urlSegments.queryParams
			});
		} else {
			console.error('Invalid Token');
		}

		return false;
	}

	/** This function set values in sessionStorage from the given parameters
	 *
	 * @param strAccessToken string representation of the access token
	 * @param encodedAccessToken encoded JWT representation of the access token as sent on the url
	 * @param jsonToken parsed representation of the access token
	 * @param idToken
	 */
	private storeTokenInformation(
		strAccessToken: string,
		encodedAccessToken: string,
		jsonToken: JSONTokenObject,
		idToken: string
	): void {
		sessionStorage.setItem('strAccessToken', strAccessToken);
		sessionStorage.setItem('encodedAccessToken', encodedAccessToken);
		sessionStorage.setItem('tokenIssue', jsonToken.iat);
		sessionStorage.setItem('tokenExp', jsonToken.exp);
		sessionStorage.setItem('userId', jsonToken.CommonName);
		sessionStorage.setItem('idToken', idToken);
	}

	/** This function return a parsed segment object given a valid url with and without query parameter
	 *
	 * @param url url for redirection
	 * @returns segments object that contains properties used for navigateByUrl call
	 */
	private extractRedirectUrlSegments(url: string): RedirectUrlOptions {
		const segments = new RedirectUrlOptions();
		if (url) {
			if (url.indexOf('?') > -1) {
				const [path, queryString] = url.split('?');
				segments.path = path.substring(path.lastIndexOf('/') + 1);
				segments.queryParams = this.getRedirectQueryParams(queryString);
			} else {
				segments.path = url;
			}
		}
		return segments;
	}

	/** This function return list of params object with valid query and return empty object with undefined query
	 *
	 * @param query string from the url to parse parameters from
	 * @returns object containing the parsed query string params from the given url
	 */
	private getRedirectQueryParams(query: string): object {
		const queryObj = {};
		if (query) {
			const queryArgs = query.split('&');
			queryArgs.forEach((item) => {
				const pair = item.split('=');
				queryObj[pair[0]] = pair[1];
			});
			return queryObj;
		}
		return queryObj;
	}

	/** Parses the technically malformed queryString to pick off the token and associated properties.
	 * @param paramString the param string from the url
	 * @returns The queryString params in Object format, or null if the string was invalid.
	 */
	private parseParamString(paramString: string): object | null {
		if (paramString.indexOf('access_token') === 0) {
			//URLSearchParams should be the solution here. it's not working. so we did it manually
			const paramArray = paramString.split('&');
			const queryParamsObj = {};
			for (const param of paramArray) {
				//we can't use a simple split() call here as base64 allows for = padding
				const i = param.indexOf('=');
				const splitArray = [param.slice(0, i), param.slice(i + 1)];
				queryParamsObj[splitArray[0]] = splitArray[1];
			}
			return queryParamsObj;
		} else {
			return null;
		}
	}
}
