import {ModuleWithProviders, NgModule} from '@angular/core';
import {AdfsConfig} from './api/adfs-config';
import {AuthService} from './services/auth.service';
import {AUTHSERVICE_WITH_CONFIG} from './services/auth.service.config';
import {AuthenticationGuard} from './authentication-guard/authentication.guard';
import {OauthTokenGuard} from './oauth-token-guard/oauth-token.guard';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {OauthCallbackComponent} from './oauth-callback/oauth-callback.component';

@NgModule({
	declarations: [OauthCallbackComponent],
	imports: [CommonModule, BrowserModule, RouterModule]
})
export class NgxAdfsModule {
	static forRoot(config: AdfsConfig): ModuleWithProviders<NgxAdfsModule> {
		return {
			ngModule: NgxAdfsModule,
			providers: [
				AuthService,
				{
					provide: AUTHSERVICE_WITH_CONFIG,
					useValue: config
				},
				AuthenticationGuard,
				OauthTokenGuard
			]
		};
	}
}
