<form [formGroup]="createJobForm" (ngSubmit)="onSubmit()">
<p-dialog header="Header" [(visible)]="visible" [modal]="true" [breakpoints]="{ '960px': '75vw' }"  class="dialoghw" (onHide)="closeDialog()"  [closable]="true">
  <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{"header.createJobRole" | translate}}</span>
  </ng-template>
  <ng-container>
    <div class="addModalPadding">
      <div class="card flex flex-row align-items-center gap-6 p-6">
        <span class="p-float-label">
            <p-dropdown [style]="{width:'200px'}" [options]="branchesList"  formControlName="selectedBranch" placeholder="{{'placeholder.selectBranch' | translate}}" optionLabel="branchName" (onChange)="onChangeBranch()" inputId="float-label" [filter]="true" filterBy="branchName" [disabled]="true" ></p-dropdown>
            <label class="floatClass addinput"  for="float-label">{{"label.branchName" | translate}}</label>
        </span>
        <span class="p-float-label">
          <p-dropdown  [style]="{width:'200px'}"[options]="siteCodeTypeList" formControlName="selectedSiteCodeType" placeholder="{{'placeholder.selectSiteCodeType' | translate}}" optionLabel="siteCodeType" (onChange)="onChangeSiteCodeType()" inputId="float-label" [filter]="true" filterBy="siteCodeType" [disabled]="true"></p-dropdown>
          <label class="addinput" for="float-label"> {{"label.siteCodeType" | translate}}</label>
      </span>

      <span class="p-float-label">
        <p-dropdown [style]="{width:'200px'}" [options]="marketList" formControlName="selectedMarket" placeholder="{{'placeholder.selectMarket' | translate}}"  optionLabel="marketName" [filter]="true" filterBy="marketName" inputId="float-label"></p-dropdown>
        <label class="addinput" for="float-label">{{"label.market" | translate}}</label>
    </span></div>
    <div class="card flex flex-row justify-content-center gap-6 p-3">
        <span class="p-float-label">
          <input autofocus [style]="{width:'200px'}" pInputText  formControlName="jobRoleCode" />
          <label class="addinput"  htmlFor="jobrolecode">{{"label.jobRoleCode" | translate}}</label>
      </span>
      <span class="p-float-label">
        <input [style]="{width:'200px'}"  pInputText formControlName="jobRoleName" />
        <label class="addinput" htmlFor="jobrolename">{{"label.jobRoleName" | translate}}</label>
      </span>
      </div>
    </div>

  </ng-container>
  <div class="flex align-items-center justify-content-center" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" type="submit" [loading]="loadingButton" [disabled]="!createJobForm.valid" label="{{'button.create' | translate}}" styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-times" (click)="closeDialog()" label="{{'button.cancel'| translate}}" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
</form>
<p-dialog header="Header" [(visible)]="visibleResponseDialog" [modal]="true">
  <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{"header.createResponse" | translate}}</span>
  </ng-template>
  <p *ngIf="!loadingDialog">
      {{responseMessage}}
  </p>
  <div class="flex align-items-center justify-content-center" *ngIf="loadingDialog">
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>
  <ng-template  pTemplate="footer">
      <p-button icon="pi pi-danger" (click)="closeResponseDialog()" label="{{'button.close'| translate}}" styleClass="p-button-text"></p-button>
</ng-template>
</p-dialog>

