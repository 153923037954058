import { Component } from '@angular/core';
import { MessageService, SortEvent } from 'primeng/api';
import {formatDate } from '@angular/common';
import { AdministrationService } from '../../services/administration.service';
import { Subject, takeUntil } from 'rxjs';
import {EnvConstants} from "../../../common/env.constants";
interface Column {
  field: string;
  header: string;
  customExportHeader?: string;
}

interface ExportColumn {
  title: string;
  dataKey: string;
}

@Component({
  selector: 'job-roles-table',
  templateUrl: './job-roles-table.component.html',
  styleUrls: ['./job-roles-table.component.scss']
})

export class JobRolesTableComponent {
  showFilter: boolean = true;
  loadingButton: boolean = false;
  showEditPage: boolean = false;
  deleteData: any;
  cols!: Column[];
  today= new Date();
  downloadTime = '';
  exportColumns!: ExportColumn[];
  responseTitle: string = '';
  responseMessage: string = '';
  loading: boolean = false;
  totalRecords!: number;
  totalRecordsFound: string = '';
  private unsubscribe = new Subject<void>();

  constructor(private adminService: AdministrationService,private messageService: MessageService) {
    this,adminService.getJobroleList().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.jobRolesList = data;
      this.totalRecords = this.jobRolesList?.length;
      this.totalRecordsFound = this.totalRecords + ' records found for the selected market';
    });
  }
  visible: boolean = false;

  ngOnInit(){
    this.cols = [
      { field: 'branch', header: 'Branch'},
      { field: 'siteCodeType', header: 'SiteCode' },
      { field: 'country', header: 'Market' },
      { field: 'jobRoleCode', header: 'JobRoleCode' },
      { field: 'jobRoleName', header: 'JobRoleName' }
  ];

  this.exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));
  }
  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  filename(){
    this.today=new Date();
    return this.downloadTime = "JobRoleData_"+ formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');

  }
  showDialog() {
      this.visible = true;
  }

  jobRolesList: any[] = [];
  filteredTableRecords: any[] = [];

  public onFilter(event) {
    this.filteredTableRecords = event.filteredValue;
  }

  public onSorting(event: SortEvent) {
    event.data.sort((d1, d2) => {
      let v1 = d1[event.multiSortMeta[1].field];
      let v2 = d2[event.multiSortMeta[1].field];
      return event.multiSortMeta[1].order === -1 ? v1?.localeCompare(v2) : v2?.localeCompare(v1);
    });
  }

  public onClickEdit(editableRecord: any): void {
    this.showEditPage = true;
    this.adminService.editModal(this.showEditPage);
    this.adminService.updateEditData(editableRecord);
  }
  public deleteJobRole(jobRole: any): void {
    this.visible=true;
    this.responseTitle="deleteConfirmation";
    this.responseMessage="Are you sure you want to delete this record?"
    this.deleteData={
      branch: jobRole.branch,
      siteCodeType: jobRole.siteCodeType,
      country: jobRole.country,
      jobRoleCode: jobRole.jobRoleCode,
      jobRoleName: jobRole.jobRoleName,
    }

  }
  confirmDelete(){
    this.responseTitle="deleteResponse"
    this.loading=true;
    this.loadingButton=true;
    this.adminService.deleteJobRole(this.deleteData.branch,this.deleteData.siteCodeType,this.deleteData.country,this.deleteData.jobRoleCode,this.deleteData.jobRoleName).subscribe({
      next: data => {
      this.loadingButton=false;
      this.responseMessage=data.message;
      this.loading=false;
    },
     error: error => {
      this.loadingButton=false;
      this.responseMessage=error.message;
      this.loading=false;
      this.visible=false;
      }});
  }

  closeResponseDialog(){
    this.visible=false;
    this.adminService.updateJobRefresh(true);

  }

    protected readonly EnvConstants = EnvConstants;
}
