export const OTHER_LANGUAGE_COLUMNS = [
    '', `SPS Group Administrator's Manual`, `SPS User Manuals`, `Tutorial for DSA Users`
];

export const OTHER_LANGUAGE_MANUAL = [
    {
      language: { label: 'Português' },
      adminManual: { label: 'View', link: 'SPS Group Administrators Manual Portuguese.pdf'},
      userManual: { label: 'View', link: 'SPS User Manual Portuguese.pdf' },
      dsaTutorial: { label: 'View', link: 'Tutorial For DSA Users Portuguese.pdf' }
    },
    {
      language: { label: 'Français' },
      adminManual: { label: '' },
      userManual: { label: 'Manuel des Services de sécurité', link: 'SPS User Manual french.pdf' },
      dsaTutorial: { label: 'Tutoriel sommaire sur les SS Privilèges et descriptions', link: 'Tutorial For DSA Users french.pdf' }
    },
    {
      language: { label: 'Español' },
      adminManual: { label: '' },
      userManual: { label: 'View', link: 'SPS User Manual Spanish.pdf'},
      dsaTutorial: { label: 'View', link: 'Tutorial For DSA Users Spanish.pdf'  }
    },
    {
      language: { label: 'Russian' },
      adminManual: { label: 'View', link: 'SPS Group Administrators Manual Russian.pdf'},
      userManual: { label: 'View', link: 'SPS User Manual Russian.pdf' },
      dsaTutorial: { label: 'View', link: 'Tutorial For DSA Users Russian.pdf' }
    }
  ];

  export const SPS_USER_MANUAL_DETAILS = [
    {
      title: 'SPS Overview (1)',
      items: [
        {
			label: 'APS SPS Overview',
			link: 'APS SPS Overview.pdf'
		},
      ],
    },
    {
      title: 'SPS General (1)',
      items: [
        {
			label: 'General Navigation',
			link: 'General Navigation.pdf'
		}
      ],
    },
    {
      title: 'SPS Self-Administering Personal Information (4)',
      items: [
        {
			label: 'Change My Profile',
			link: 'Change My Profile.pdf'
		},
        {
			label: 'Changing Password',
			link: 'Changing Password.pdf'
		},
      {
			label: 'Viewing Your Information',
			link: 'Viewing Your Information.pdf'
		},
        {
			label: 'Authentication Flows for External Users',
			link: 'Authentication Flows for External Users.pdf'
		},
      ],
    },
    {
      title: 'SPS Basic Administration Tasks (12)',
      items: [
        {
			label: 'Searching Users',
			link: 'Searching Users.pdf'
		},
        {
			label: 'Resetting User\'s Password',
			link: 'Resetting User\'s Password.pdf'
		},
        {
			label: 'Updating User\'s Profile',
			link: 'Updating User\'s Profile.pdf'
		},
    {
			label: 'Inactive User',
			link: 'Inactive User.pdf'
		}, {
			label: 'Managing eMail Settings',
			link: 'Managing eMail Settings.pdf'
		}, {
			label: 'Selecting a User Group',
			link: 'Selecting a User Group.pdf'
		}, {
			label: 'Creating a New User',
			link: 'Creating a New User.pdf'
		}, {
			label: 'Adding an Existing User to the Group',
			link: 'Adding an Existing User to the Group.pdf'
		}, {
			label: 'Transferring a User to a Different Group',
			link: 'Transferring a User to a Different Group.pdf'
		}, {
			label: 'Copy User ID from Primary to Secondary Group',
			link: 'Copy User ID from Primary to Secondary Group.pdf'
		}, {
			label: 'Removing User\'s Group Membership',
			link: 'Removing User\'s Group Membership.pdf'
		}, {
			label: 'Group Members Attribute Report',
			link: 'Group Members Attribute Report.pdf'
		}
      ],
    },
    {
      title: 'Audit (2)',
      items: [
        {
			label: 'SPS Manual Audit Recommendations',
			link: 'SPS Manual Audit Recommendations.pdf'
		},
        {
			label: 'Audit Status Report',
			link: 'Business Owner Report.pdf'
		}
      ],
    },
    {
      title: 'SPS Advanced Administration Tasks (2)',
      items: [
        {
			label: 'Managing Temporary IDs',
			link: 'Managing Temporary IDs.pdf'
		},
        {
			label: 'Manage Group Administrators',
			link: 'Manage Group Administrators.pdf'
		}
      ],
    },
  ];
