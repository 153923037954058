import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SortEvent} from "primeng/api";
import {TableRowSelectEvent} from "primeng/table";
import {AdminFlag, AdminUser, UserFlagRequest} from "../admin-user-groups/admin.model";
import {EnvConstants} from "../../common/env.constants";

@Component({
  selector: 'admin-member-table',
  templateUrl: './admin-member-table.component.html',
  styleUrls: ['./admin-member-table.component.scss']
})
export class AdminMemberTableComponent implements OnInit{
	filteredTableRecords: any[] = [];
	@Input({required:true}) adminUsers:AdminUser[];
	@Output() selectedUserEmitter = new EventEmitter<UserFlagRequest>();
	@Output() deleteUserEmitter = new EventEmitter();
	selectedAdminUsers:any;
	showFilter: boolean = true;
	changedRecords = new Set<string>();
	public onFilter(event) {
		this.filteredTableRecords = event.filteredValue;
	}

	public onSorting(event: SortEvent) {
		event.data.sort((d1, d2) => {
			let v1 = d1[event.multiSortMeta[1].field];
			let v2 = d2[event.multiSortMeta[1].field];
			return event.multiSortMeta[1].order === -1 ? v1.localeCompare(v2) : v2.localeCompare(v1);
		});
	}


	// Function to handle row selection
	onRowSelect(event: TableRowSelectEvent) {
		this.deleteUserEmitter.emit(this.selectedAdminUsers)
	}

	onGroupAdminChange(adminUser:AdminUser){
		const updateFlagRequest:UserFlagRequest = {
			userId: adminUser.userId,
			adminFlag: adminUser.groupAdmin ? AdminFlag.GroupAdmin : AdminFlag.UserAdmin
		}
		this.selectedUserEmitter.emit(updateFlagRequest);
	}

	// onGroupAdminChange(userId: string) {
	// 	if (this.changedRecords.has(userId)) {
	// 		this.changedRecords.delete(userId);
	// 	} else {
	// 		this.changedRecords.add(userId);
	// 	}
	// 	const changedRecords = this.getChangedRecords();
	// 	console.log("updated records",changedRecords);
	// 	this.selectedUserEmitter.emit(changedRecords);
	// }
	//
	// getChangedRecords() {
	// 	const changed = this.adminUsers.filter((user:AdminUser) => this.changedRecords.has(user.userId));
	// 	console.log("changes",changed)
	// 	return changed.map(record => {
	// 		const updateFlagRequest:UserFlagRequest = {
	// 			userId : record.userId,
	// 			adminFlag : record.groupAdmin ? AdminFlag.GroupAdmin : AdminFlag.UserAdmin
	// 		};
	// 		return updateFlagRequest;
	// 	});
	// }

	ngOnInit(): void {
	}

	protected readonly EnvConstants = EnvConstants;
}
