<form [formGroup]="transferUserForm" (ngSubmit)="onSubmit()">
  <p-dialog header="Header" [(visible)]="visible" [modal]="true" [breakpoints]="{ '960px': '75vw' }" [styleClass]=" 'w-9 h-40rem' " (onHide)="closeDialog()"  [closable]="true">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{"header.transferUser" | translate}}</span>
    </ng-template>
    <ng-container>
    <div class="card">
      <div class="flex justify-content-end">
        <span class="text-red-500">*</span>
        <span> {{"label.indicationRequiredField" | translate}}</span>
      </div>
      <div *ngIf="siteCodes?.length<1" class="card flex justify-content-center spinner-mask">
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
      </div>

      <p-fieldset [toggleable]="false" [styleClass]="'h-25rem'">
          <ng-template pTemplate="header">
              <div class="flex align-items-center">
                  <span class="pi pi-user mr-2"></span>
                  <span class="font-bold text-lg"> {{"header.destinationDetails" | translate}}</span>
              </div>
          </ng-template>
          <div class="card">
            <div class="flex flex-wrap mb-4">
                <div class="col-4">
                    <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.siteCodeType" | translate}}  </label><span class="text-red-500"> *</span></div>
                    <input pInputText id="alpha" pKeyFilter="alpha" formControlName="destinationSiteCodeType" class="w-full" />
                  </div>
                <div class="col-4">
                  <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.market" | translate}}  </label><span class="text-red-500"> *</span></div>
                  <input pInputText id="alpha" pKeyFilter="alpha" formControlName="destinationCountryCode" class="w-full" />
                </div>
                  <div class="col-4">
					<div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">  {{"label.siteCode" | translate}} </label><span class="text-red-500"> *</span></div>
                  <p-dropdown  styleClass="w-full" [options]="siteCodes"  formControlName="destinationSiteCode" optionValue="value" optionLabel="value" (onFilter)="onFilter($event)"  appEnterKeyFilter
							   (enterKey)="onEnter()" [showClear]="true" [filter]="true" filterBy="value"  placeholder="{{'placeholder.selectSiteCode' | translate}}" virtualScroll="true" virtualScrollItemSize="50"></p-dropdown>
                  <div class="text-red-400" *ngIf="transferUserForm.get('destinationSiteCode').hasError('required') && transferUserForm.get('destinationSiteCode').touched">
                     {{"message.siteCodeRequired" | translate}}
                  </div>
                  <div class="text-red-400" *ngIf="transferUserForm.get('destinationSiteCode').hasError('siteCodes')">
                    {{"message.siteCodeNotSame" | translate}}
                  </div>
                </div>
            </div>
          </div>
    </p-fieldset>
    </div>
    </ng-container>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" type="submit" label="{{'button.transfer' | translate}}" [disabled]="!transferUserForm.valid" styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-times" (click)="closeDialog()" label="{{'button.cancel' | translate}}" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>
  </form>
  <p-dialog header="Header" [(visible)]="enableDialog" [modal]="true">
    <div *ngIf="loading" class="card flex justify-content-center">
      <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
    <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{ responseModalTitle | translate}}</span>
    </ng-template>
    <p>
      {{ responseMessage | translate}}
    </p>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-danger" (click)="closeRespDialog()" label="{{'button.close' | translate}}"
            styleClass="p-button-text"></p-button>
      <!-- <p-button icon="pi pi-danger" (click)="visible = false" label="No" styleClass="p-button-text"></p-button> -->
    </ng-template>
  </p-dialog>
