import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, map, Observable, of, Subject} from 'rxjs';
import { environment } from 'src/environments/environment';
import {ClientConstants} from "../../common/client.constants";
import { HttpMethods } from 'src/app/common/http-methods.constants';
import {BaseUserGroupModel} from "../base-usergroup.model";
import {AdminUpdateRequest, AdminUserRequest} from "../admin-user-groups/admin.model";
import {PageType} from "../../common/models/user-role/page-type.enum";
import {HeaderUtilityService} from "../../common/service/header-utility.service";
import {Branch} from "../search-tree-node/search-node.model";
import {tap} from "rxjs/operators";
import {response} from "express";
import {BranchModel} from "../../common/models/branch.model";

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService {

  baseUrlPcf: string = environment.baseUrlPcf;
  baseUrlGatewayMap: string = environment.baseUrlGateway+ '/gateway/for-map';
  baseUrlGatewayArray: string = environment.baseUrlGateway+ '/gateway/for-array';
  siteStructureBaseUrl: string = environment.baseUrlPcf + 'sitestructure/api/v1/';
  fdsBaseUrl: string = this.siteStructureBaseUrl + 'fds/';
  dealerAuditBaseUrl: string = environment.baseUrlPcf + 'DealerAudit/v2/';
  branchList:BranchModel[];
  defaultDelay=45000;
  constructor(private _httpClient: HttpClient,private headerUtility:HeaderUtilityService) {
	this.branchList = [];
  }

  /** Behavior Subject Section */
  private transferUser = new BehaviorSubject<boolean>(false);
  private refreshTransferUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	fetchBranchList(): Observable<BranchModel[]> {
		if (this.branchList.length === 0) {
			let requestBody: any = {
				"endpoint": this.siteStructureBaseUrl+ 'branch-details',
				"configName": ClientConstants.CLIENT_6
			}
			return this._httpClient.post<BranchModel[]>(this.baseUrlGatewayMap, requestBody);
		} else {
			return of(this.branchList);
		}
	}

  createTransferUser(transferUser: boolean) {
    this.transferUser.next(transferUser);
  }
  getTransferUser(){
    return this.transferUser.asObservable();
  }
  updateTransferRefresh(data: boolean) {
		this.refreshTransferUser.next(data);
	}
	getTransferRefresh() {
		return this.refreshTransferUser.asObservable();
	}

  mapEmailType(emailTypeList){
	 return emailTypeList.map(data => {
		  return {
			  name: data,
			  code: data
		  };
	  })
  }

	mapJobRoles(jobRoleList){
		return jobRoleList.map(data => {
			return {
				jobRoleCode: data.jobRoleCode,
				jobRoleName: data.jobRoleName
			};
		})
		.sort((n1: { jobRoleName: string; }, n2: { jobRoleName: string; }) => n1.jobRoleName.localeCompare(n2.jobRoleName));
	}

	mapLanguages(languageList){
		return languageList.sort((n1: { languageName: string; }, n2: { languageName: string; }) => n1.languageName.localeCompare(n2.languageName));
	}

	buildUrl(baseUrl:string, market?: string, siteCode?: string): string {
		let url = `${baseUrl}`;
		if (market) url += `&market=${market}`;
		if (siteCode) url += `&site-code=${siteCode}`;
		return url
	}

	getLoadingDelay(){
		return environment.memberLoadingDelay || this.defaultDelay;
	}

  getGroupDetails(supplier: string) {
    let requestBody: any = {
      "endpoint": this.fdsBaseUrl+ 'group-details/'+supplier,
      "configName": ClientConstants.CLIENT_6
    }
    return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
    // return this._httpClient.get(this.baseUrl + '/fds/group-details/' + supplier);
  }

  getSiteCodeTypes(supplier: string) {
    let requestBody: any = {
      "endpoint": this.fdsBaseUrl+ 'site-code-types/' + supplier,
      "configName": ClientConstants.CLIENT_6
    }
    return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
    // return this._httpClient.get(this.baseUrl + '/fds/site-code-types/' + supplier);
  }

  getMarkets(groupname: string, sitecode: string) {
    let requestBody: any = {
      "endpoint": this.fdsBaseUrl+ 'markets/' + groupname + '/' + sitecode,
      "configName": ClientConstants.CLIENT_6
    }
    return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
    // return this._httpClient.get(this.baseUrl + '/fds/markets/' + groupname + '/' + sitecode);
  }

  getSiteCodes(groupname: string, sitecode: string, sitecodeType: string) {
		let headers = {
			'PageTitle': PageType.USER_REPORT
		}
    let requestBody: any = {
      "endpoint": this.fdsBaseUrl+ 'site-codes/' + groupname + '/' + sitecodeType + '/' + sitecode,
      "configName": ClientConstants.CLIENT_6,
			"headers":headers
    }
    return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
    // return this._httpClient.get(this.baseUrl + '/fds/site-codes/' + groupname + '/' + sitecodeType + '/' + sitecode);
  }

  searchSiteCode(siteCode:string){
    let requestBody: any = {
      "endpoint": this.fdsBaseUrl+ `matching-sitecodes/${siteCode}`,
      "configName": ClientConstants.CLIENT_6
    }
    return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
	  // return this._httpClient.get(this.baseUrl + `/fds/matching-sitecodes/${siteCode}`);
  }

	getUsersforGroupsV2({siteCodeType,branch,siteCode,market}:BaseUserGroupModel,pageTitle:PageType){
		let requestBody: any = {
			"endpoint": this.dealerAuditBaseUrl + `audit-config/user-audit-status?branch=${branch}&ford-site-code-type=${siteCodeType}&country=${market}&site-code=${siteCode}`,
			"configName": ClientConstants.CLIENT_3,
			"headers": this.headerUtility.buildPageTitleHeader(pageTitle,{siteCodeType,branch,siteCode,market})
		}
		return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
	}

  getDealerAuditStatus(branch: string,siteCodeType:string,market:string,sitecode:string){
	  let requestBody: any = {
		  "endpoint": this.dealerAuditBaseUrl + `audit-config/group-audit-status?branch=${branch}&ford-site-code-type=${siteCodeType}&country=${market}`+ (sitecode ? `&site-code=${sitecode}`: ''),
		  "configName": ClientConstants.CLIENT_3
	  }
	  return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
  }

  getUserAuditDetails(userId: string){
		let requestBody: any = {
			"endpoint": environment.baseUrlPcf + `DealerAudit/v2/audit-config/user-audit-details?user-id=${userId}`,
			"configName": ClientConstants.CLIENT_3
		}
		return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
  }

  createNewUserService(request:any): Observable<any>{
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'usermanagement/api/v2/user/createUser',
      "configName": ClientConstants.CLIENT_2,
      "jsonPayload":request,
      "httpMethod": HttpMethods.POST
    }
    return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
  }

  getJobRoles(requestBody: any): Observable<any> {
    requestBody = {
      "endpoint": this.siteStructureBaseUrl + 'job-role/manage-job-role?branch=' + requestBody.branchCode + '&ford-site-code-type=' + requestBody.siteCodeType + '&country=' + requestBody.marketName,
      "configName": ClientConstants.CLIENT_6
    }
    return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
    // return this._httpClient.get<any>(this.baseUrl + `/job-role/manage-job-role?branch=${requestBody.branchCode}&ford-site-code-type=${requestBody.siteCodeType}&country=${requestBody.marketName}`);
  }

  getLanguageData(): Observable<any> {
    let requestBody:any = {
      "endpoint": this.siteStructureBaseUrl + 'languages',
      "configName": ClientConstants.CLIENT_6
    }
    return this._httpClient.post(this.baseUrlGatewayArray, requestBody);
  }

  public addSecondaryUser(branch:string,siteCodeType:string,country:string,siteCode:string, userIds:string): Observable<any> {
    let requestBody: any = {
			"endpoint": this.fdsBaseUrl+ `add-secondary-user/${branch}/${siteCodeType}/${country}/${siteCode}/${userIds}`,
			"configName": ClientConstants.CLIENT_6,
      "httpMethod": HttpMethods.POST
		}
		return this._httpClient.post(this.baseUrlGatewayMap, requestBody);

    //return this._httpClient.post(this.baseUrl + `/fds/add-secondary-user/${baseGroupModel.branch}/${baseGroupModel.siteCodeType}/${baseGroupModel.market}/${baseGroupModel.siteCode}/${userIds}`,{});
  }

  submitAuditAttestation(request:any): Observable<any>{
    let requestBody: any = {
			"endpoint": this.dealerAuditBaseUrl + `audit-config/audit-attestation`,
			"configName": ClientConstants.CLIENT_3,
      "jsonPayload":request,
      "httpMethod": HttpMethods.POST
		}
		return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
  }
  deleteUserService(request:any): Observable<any>{
		let requestBody: any = {
			"endpoint": environment.baseUrlPcf + 'usermanagement/api/v2/user/deleteUser',
			"configName": ClientConstants.CLIENT_2,
			"jsonPayload":request,
			"httpMethod": "POST"
		}
		return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
	}

  transferUserService(request:any): Observable<any>{
    let requestBody: any ={
      "endpoint": environment.baseUrlPcf + 'transferUser/api/v1/transfer',
      "configName": ClientConstants.CLIENT_7,
      "jsonPayload":request,
      "httpMethod": "POST"
    }

    return this._httpClient.post(this.baseUrlGatewayArray, requestBody);
  }

	public getSiteCodeDescription(siteCode: string): Observable<any> {
		let requestBody: any = {
			'endpoint': this.fdsBaseUrl+ `matching-sitecodes/${siteCode}`,
			'configName': ClientConstants.CLIENT_6
		}
		return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
	}

  public getMarketDescription(baseGroupModel: BaseUserGroupModel): Observable<any> {
		let requestBody: any = {
			'endpoint': this.fdsBaseUrl+ `market-with-details/${baseGroupModel.branch}/${baseGroupModel.siteCodeType}/${baseGroupModel.market}`,
			'configName': ClientConstants.CLIENT_6
		}
		return this._httpClient.post(this.baseUrlGatewayMap, requestBody);
	}
	public getAdminUsers({siteCodeType,branch,siteCode,market}:BaseUserGroupModel){
		let requestBody:any = {
			"endpoint": this.fdsBaseUrl + this.buildUrl(`admin-member?branch=${branch}&site-code-type=${siteCodeType}`,market,siteCode),
			"configName": ClientConstants.CLIENT_6
		}
		return this._httpClient.post(this.baseUrlGatewayArray,requestBody);
	}

	public addAdminUser(adminUser:AdminUserRequest){
		let requestBody:any = {
			"endpoint": this.fdsBaseUrl+ `admin-member`,
			"configName": ClientConstants.CLIENT_6,
			"jsonPayload":adminUser,
			"httpMethod": HttpMethods.POST
		}
		return this._httpClient.post(this.baseUrlGatewayMap,requestBody);
	}

	public updateAdminUser(adminUserUpdate:AdminUpdateRequest){
		let requestBody:any = {
			"endpoint": this.fdsBaseUrl+ `admin-member`,
			"configName": ClientConstants.CLIENT_6,
			"jsonPayload":adminUserUpdate,
			"httpMethod": HttpMethods.PUT
		}
		return this._httpClient.post(this.baseUrlGatewayMap,requestBody);
	}

	public deleteAdminUser({siteCodeType,branch,siteCode,market}:BaseUserGroupModel,userList:string){
		let requestBody:any = {
			"endpoint": this.fdsBaseUrl + this.buildUrl(`admin-member?branch=${branch}&site-code-type=${siteCodeType}&users-list=${userList}`,market,siteCode),
			"configName": ClientConstants.CLIENT_6,
			"httpMethod": HttpMethods.DELETE
		}
		return this._httpClient.post(this.baseUrlGatewayMap,requestBody);
	}

}
