import {JobRole} from "../create-new-user/user.model";

export interface MemberUserModel {
	userId:string;
	firstName:string;
	lastName:string;
	middleName?:string;
	location:string;
	primaryLocation?:string;
	passwordDate:Date | null;
	passwordSetStatus?:PasswordStatus
	mailbox:string;
	audit?:string;
	status:Status | null;
	fordJobRole?:JobRole;
}

export interface MemberTableModel{
	userId:string;
	firstName:string;
	lastName:string;
	middleName?:string;
	location:string;
	primaryLocation?:string;
	passwordDate:Date | null;
	passwordSetStatus?:PasswordStatus
	status:Status | null;
	lastAuditedDate: Date | null,
	lastAuditedBy: string
}

export interface MemberTableTempModel{
	userId:string;
	firstName:string;
	lastName:string;
	primaryLocation?:string;
	passwordDate:Date | null;
	passwordSetStatus?:PasswordStatus
	jobrole: string;
	comment: string;
	email: string;
}

export interface CustomerMember{
	title: string;
	dataList:MemberTableModel[];
	ttitle: string;
}

export enum Status {
	ACTIVE = 'Active-Members',
	INACTIVE = 'Inactive-Members'
}

export enum PasswordStatus {
	ACTIVE = 'Active' ,
	EXPIRED = 'Expired',
	INACTIVE ='Inactive'
}

export const PASSWORD_ON="ON";
