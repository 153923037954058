import {inject, Inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {HeaderBodyModel} from "../common/models/header-body.model";
import {AuthService} from "./adfs/services/auth.service";
import {Router} from "@angular/router";
import {UserIdService} from "./adfs/services/user-id.service";
import {UserGroupsService} from "../user-groups/services/user-groups.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	constructor(@Inject(PLATFORM_ID) private platFormId:Object,private router:Router,private inject: Injector) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let token = null;
		let loggedInUser = null;
		let modifiedReqBody = req.body;
		if(this.platFormId == 'browser'){
			const authService = this.inject.get(AuthService);
			token = sessionStorage.getItem('encodedAccessToken');
			if(!token){
				return next.handle(req);
			}
			if(authService.isTokenExpired()){
				authService.clearAuthSession();
				this.router.navigate(['401']);
			}
		}

		if(req.body && req.body.headers){
			const headerBody:HeaderBodyModel = req.body.headers;
			modifiedReqBody={
				...modifiedReqBody,
				headers: {
					...req.body.headers
				}
			}
		}
		const clonedRequest = req.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			},
			body: modifiedReqBody
		});
		return next.handle(clonedRequest);
	}
}
