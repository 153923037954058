<form [formGroup]="createTempForm" (ngSubmit)="onSubmit()">
  <p-dialog header="Header" [(visible)]="visible" [modal]="true" [breakpoints]="{ '960px': '75vw' }" [styleClass]=" 'w-9 h-40rem' " (onHide)="closeDialog()" [closable]="true">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{"header.createTemporaryUser" | translate}}</span>
    </ng-template>
    <ng-container>
  <div class="card h-20rem">
    <div class="flex justify-content-end">
      <span class="text-red-500">*</span>
      <span>{{"label.indicationRequiredField" | translate}}</span>
    </div>

    <!-- <p-fieldset [toggleable]="true" [styleClass]="'h-25rem'"> -->
      <ng-container>
        <div class="card">
          <div class="flex flex-wrap mb-4">
              <div class="col-4">
                  <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.firstName" | translate}} </label><span class="text-red-500"> *</span></div>
                  <input type="text" autofocus pInputText id="firstName" pKeyFilter="alpha" formControlName="firstName" class="w-full" />
                  <div class="absolute">
                    <small class="text-red-400" *ngIf="createTempForm.get('firstName').hasError('required') && createTempForm.get('firstName').touched">
                      {{"message.firstNameRequired" | translate}}
                    </small>
                    <small class="text-red-400" *ngIf="createTempForm.get('firstName').hasError('missingAlphanumeric')">
                       {{"message.firstNameOneLetter" | translate}}
                    </small>
					  <small class="text-red-400" *ngIf="createTempForm.get('firstName').hasError('maxlength')">
						  {{"message.nameLengthMessage" | translate}}
					  </small>
                    <div *ngIf="createTempForm.get('firstName').hasError('invalidName')">
                      <small class="text-red-400" *ngIf="createTempForm.get('firstName').getError('invalidName').illegalChars">
                        {{"message.illegalCharacters" | translate}}: {{ createTempForm.get('firstName').getError('invalidName').illegalChars }}
                      </small>
                    </div>
                  </div>
              </div>
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.lastName" | translate}}   </label><span class="text-red-500"> *</span></div>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="lastName" class="w-full" />
                <div class="absolute">
                  <small class="text-red-400" *ngIf="createTempForm.get('lastName').hasError('required') && createTempForm.get('lastName').touched">
                     {{"message.lastNameRequired" | translate}}
                  </small>
					<small class="text-red-400" *ngIf="createTempForm.get('lastName').hasError('maxlength')">
						{{"message.nameLengthMessage" | translate}}
					</small>
                  <div *ngIf="createTempForm.get('lastName').hasError('invalidName')">
                        <small class="text-red-400" *ngIf="createTempForm.get('lastName').getError('invalidName').illegalChars">
                          {{"message.illegalCharacters" | translate}}: {{ createTempForm.get('lastName').getError('invalidName').illegalChars }}
                        </small>
                  </div>
                  <small class="text-red-400" *ngIf="createTempForm.get('lastName').hasError('missingAlphanumeric')">
                    {{"message.lastNameOneLetter" | translate}}
                  </small>
                </div>
              </div>
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.jobRole" | translate}}  </label></div>
                <p-dropdown styleClass="w-full mr-4" [options]="jobRoleList" formControlName="jobrole" placeholder="{{'placeholder.selectJobRole' | translate }}" [showClear]="true" optionLabel="jobRoleName" ></p-dropdown>
              </div>
          </div>
          <div class="flex flex-wrap">
                <div class="col-4">
                  <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.userEmail" | translate}} </label><span class="text-red-500"> *</span></div>
                  <div class="relative">
                    <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="email" class="w-full" />
                    <div class="absolute">
                      <small class="text-red-400" *ngIf="(createTempForm.get('email').hasError('email') || createTempForm.get('email').hasError('pattern')) && !createTempForm.get('email').hasError('required')">
                        {{"message.validEmail" | translate}}
                      </small>
                      <small class="text-red-400" *ngIf="createTempForm.get('email').hasError('required') && createTempForm.get('email').touched">
                        {{"message.emailRequired" | translate}}
                      </small>
						<small class="text-red-400" *ngIf="createTempForm.get('email').hasError('maxlength')">
							{{"message.emailLengthMessage" | translate}}
						</small>
                    </div>
                  </div>

                </div>
                <div class="col-4">
                  <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2"> {{"label.primaryLocation" | translate}}  </label><span class="text-red-500"> *</span></div>
                  <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="primaryLocation" class="w-full" />
                </div>
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.comments" | translate}} </label>
                <textarea rows="5" pInputTextarea formControlName="comments" class="w-full"></textarea>
              </div>
          </div>
      </div>
    </ng-container>
    <!-- </p-fieldset> -->
  </div>
  </ng-container>
  <!-- <div class="flex align-items-center justify-content-center" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div> -->
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" type="submit" label="{{'button.create' | translate}}" [disabled]="!createTempForm.valid" styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-times" (click)="closeDialog()" label="{{'button.cancel' | translate}}" styleClass="p-button-text"></p-button>
  </ng-template>
  </p-dialog>
  </form>
  <p-dialog header="Header" [(visible)]="enableDialog || loading " [modal]="true" [style]="{'width': '50vw','min-height':'35vh'}">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{"header.createTemporaryUserResponse" | translate}}</span>
    </ng-template>
   <div class="card">
      <div class="flex align-items-center justify-content-center" *ngIf="loading">
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
        <p> {{"message.createTempIdResponse" | translate}}</p>
      </div>
      <div *ngIf="!responseMessage && !loading">
        <p class="text-center text-black-alpha-90 font-bold mb-4"> {{"message.preExpiredPassword" | translate}}</p>
      </div>
      <div class="flex justify-content-center" *ngIf="!responseMessage && !loading">
        <div class="col-4">
          <label for="alphabetic" class="font-bold block mb-2"> {{"label.userID" | translate}} </label>
          <div id="buttonGroup" class="p-inputgroup">
          <input type="text" pInputText pKeyFilter="alpha" [(ngModel)]="createdUserId" placeholder="{{'label.userID' | translate}}"/>
          <button type="button" pButton [cdkCopyToClipboard]="createdUserId" [icon]="copyUserId ? 'pi pi-check' : 'pi pi-copy'" (click)="copyUser()" styleClass="p-button-warn"></button>
          </div>
        </div>
        <div class="col-4">
          <label for="alphabetic" class="font-bold block mb-2"> {{"label.temporaryPassword" | translate}} </label>
          <!-- <input pInputText id="alphabetic" pKeyFilter="alpha" [(ngModel)]="tempPwd" class="w-full mb-2" />
          <p-button [cdkCopyToClipboard]="tempPwd" (click)="copy()" label="{{copyMessage}}"></p-button> -->
          <div id="buttonGroup" class="p-inputgroup">
            <input type="text" pInputText [(ngModel)]="tempPwd" placeholder="{{'label.temporaryPassword' | translate}}" />
            <button type="button" pButton [cdkCopyToClipboard]="tempPwd" [icon]="copyPass ? 'pi pi-check' : 'pi pi-copy'" (click)="copyPassword()" styleClass="p-button-warn"></button>
        </div>

        </div>
      </div>
      <div *ngIf="!responseMessage && !loading">
        <p class="text-center text-red-400 font-bold mb-4">{{"message.waitNewAccount" | translate}}
        </p>
        <p class="text-center text-red-400"> {{"message.noteIdPassword" | translate}}
        </p>
      </div>
      <p *ngIf="responseMessage && !loading">
        {{responseMessage}}
      </p>
   </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-danger" (click)="disableDialog()" label="{{'button.close' | translate}}" styleClass="p-button-text"></p-button>
        <!-- <p-button icon="pi pi-danger" (click)="visible = false" label="No" styleClass="p-button-text"></p-button> -->
    </ng-template>
  </p-dialog>
