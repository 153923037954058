<div class="audit-container flex flex-column mt-2">
	<div class="flex">
		<div class="col-5">
			<div class="audit-card card bg-color-1 mb-3 border-round-2xl flex align-items-center justify-content-center">
				<ng-template #showloader>
					<p-progressSpinner strokeWidth="5"  styleClass="w-3rem h-3rem" ariaLabel="loading" animationDuration=".8s" />
				</ng-template>
				<div id="audit-status" class="grid p-2 my-3 col-10 mx-auto align-items-center font-bold" *ngIf="!showAuditLoader;else showloader">
					<div class="col-6" >{{"label.auditDueDate" | translate}}:</div>
					<div class="col-6" [class.text-red-500]="'OVERDUE'=== auditStatus">
						{{auditDate ? (auditDate | date:'dd-MMM-yyyy') : 'NA'}}
					</div>
					<div class="col-6">{{"label.auditStatus" | translate}}:</div>
					<div class="col-6" [class.text-red-500]="'OVERDUE'=== auditStatus">{{auditStatus || 'NA'}}</div>
					<div class="col-6">{{"label.lastAuditDate" | translate}}:</div>
					<div class="col-6">{{lastAuditDate ? (lastAuditDate | date:'dd-MMM-yyyy') : 'NA'}}</div>
					<div class="col-6">{{"label.lastAudit" | translate}}:</div>
					<div class="col-6">{{lastAuditBy || '-'}}</div>
				</div>
			</div>
		</div>
		<div class="col-7">
			<div class="text-sm mt-5">
				<p>{{"message.groupMembersAudit" | translate}}:
				</p>
				<ul>
					<li>{{"label.groupMembership" | translate}}</li>
					<li>{{"label.groupMemberProfile" | translate}}</li>
					<li> {{"label.groupEntitlement" | translate}}</li>
				</ul>
			</div>

		</div>
	</div>
	<div class="card bg-color-1 p-1 border-round-2xl">
		<h5 class="font-bold text-center my-3 text-color-1">{{completeCount}} of {{totalCount}} {{isSiteCode ? ('message.verifiedGroupMembers' | translate) : ('message.subGroupAudit' | translate) }}</h5>
	</div>
</div>
<br>
<br>
