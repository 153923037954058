// error.interceptor.ts
import {Injectable, Injector} from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpResponse,
	HttpErrorResponse,
	HttpStatusCode
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import {AuthService} from "./adfs/services/auth.service";
import {Router} from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	private static ERROR_MSG:string = "Something went wrong";
	private static BAD_REQ_MSG:string = "Bad Request";
	private static CUSTOM_ERROR_CODE = ["400 BAD_REQUEST"];
	private static INTERNAL_SERVER_ERROR:string = "Internal Server error";
	private static NOT_FOUND_MSG:string = "Data Not Available";
	private isInternalServerError = (status: number) => status >= HttpStatusCode.InternalServerError;

	private isBadRequest = (status: number) => (status === HttpStatusCode.BadRequest);
	private isServiceUnavailable = (status: number) => status === HttpStatusCode.ServiceUnavailable;
	private isCustomError = (status:string) => ErrorInterceptor.CUSTOM_ERROR_CODE.some(err => status === err)
	private isDataNotFound = (status: number) => (status === HttpStatusCode.NotFound);

	constructor(private messageService: MessageService,private router:Router,private inject: Injector) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let internalErrorMsg = null;
		return next.handle(request).pipe(map((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				const isErrorStatus = this.isInternalServerError(event.status) ||
						(event.status === HttpStatusCode.Ok &&
							(this.isInternalServerError(event.body?.status) || this.isBadRequest(event.body?.status)));

				const isBodyErrorStatus = event.status === HttpStatusCode.Ok &&
						(this.isBadRequest(event.body[0]?.status) || this.isServiceUnavailable(event.body[0]?.status));

				const isCustomStatus = event.status === HttpStatusCode.Ok && (this.isCustomError(event.body?.code))

				const isNotFoundStatus = event.status === HttpStatusCode.Ok && (this.isDataNotFound(event.body?.code || event.body?.status))

				if(isNotFoundStatus){
					// Considered as non error
					// this.showErrorMessage(ErrorInterceptor.NOT_FOUND_MSG,'info');
					return event;
				}
				if (isErrorStatus) {
					internalErrorMsg = event.body;
				}
				if (isBodyErrorStatus) {
					internalErrorMsg = event.body[0]?.message || ErrorInterceptor.ERROR_MSG;
				}
				if (isCustomStatus){
					internalErrorMsg =event.body?.message || ErrorInterceptor.ERROR_MSG;
				}
				if (internalErrorMsg) {
					this.showErrorMessage(internalErrorMsg || ErrorInterceptor.ERROR_MSG,'info');
					throw new Error(internalErrorMsg)
				}
			}
			return event;
		}),
			catchError((error: HttpErrorResponse) => {
				let errorMessage = '';
				let showErrorMessage = true; //400 and 500 error handled internally
				if (error.error instanceof ErrorEvent) {
					errorMessage = `Client-side error: ${error.error.message}`;
				} else {
					const isUnauthRequest = error.status === HttpStatusCode.Unauthorized;
					if(isUnauthRequest) {
						const authService = this.inject.get(AuthService);
						authService.clearAuthSession();
						this.router.navigate(['401']);
					}
					const isBadRequest = error.status === HttpStatusCode.BadRequest;
					if(isBadRequest){
						showErrorMessage=false;
						errorMessage = error?.error?.errorMessage || error?.error?.message || ErrorInterceptor.BAD_REQ_MSG;
					}else{
						showErrorMessage=false;
						errorMessage = ErrorInterceptor.ERROR_MSG;
					}
					if (this.isInternalServerError(error.status)) {
						showErrorMessage=false;
						errorMessage=ErrorInterceptor.INTERNAL_SERVER_ERROR;
						console.error('Internal Server Error:', error);
					}

					showErrorMessage && this.showErrorMessage(errorMessage)
				}
				console.error(errorMessage);
				// Rethrow the error for further handling if necessary
				return throwError(() => new Error(errorMessage));
			}));
	}

	private showErrorMessage(message: string,severity='error'): void {
		this.messageService.clear();
		this.messageService.add({severity: severity, summary: 'API Fetch Error', detail: message ,life:5000});
	}
}
