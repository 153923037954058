export const ClientConstants = {
	CLIENT_1:"client_1", // Service: Not YET Added
	CLIENT_2:"client_2", // Service: UserManagement
	CLIENT_3:"client_3", // Service: Dealer audit
	CLIENT_4:"client_4",  // Service: SiteCodeCreation
	CLIENT_5:"client_5",  // Service: SPSUtil
	CLIENT_6:"client_6",   //site structure FDS
	CLIENT_7:"client_7",  //transfer
	CLIENT_8:"client_8"   //retiree
};
