import { Pipe, PipeTransform } from '@angular/core';
import {PageAccessType} from "./models/user-role/page-access-type.enum";
import {PageType} from "./models/user-role/page-type.enum";
import {UserRoleService} from "./services/user-role/user-role.service";
import {BaseUserGroupModel} from "../user-groups/base-usergroup.model";

@Pipe({
	name: 'adminFilter'
})
export class AdminFilterPipe implements PipeTransform {

	constructor(private userRoleService:UserRoleService) { }
	transform(items: any[], baseUserGroupModel: BaseUserGroupModel = null): any[] {
		if(!items){
			return items;
		}
		if(Array.isArray(items[0]?.items)){
			const itemList = items.map(group => {
				const filteredItems = group.items.filter(item => this.userRoleService.checkViewAccess(item.pageType, baseUserGroupModel));
				return {
					...group,
					items: filteredItems
				};
			}).filter(group => group.items.length > 0);
			return itemList;
		}
		const itemList =  items.filter(item => this.userRoleService.checkViewAccess(item.pageType,baseUserGroupModel));

		return itemList;
	}


}
