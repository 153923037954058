import {AccessLevel} from './access-level.enum';
import {Branch} from './branch.interface';
import {PageType} from './page-type.enum';
import {PageAccessType} from './page-access-type.enum';
import {RoleInfo} from './role-info.model';
import {CommonConstants} from "../../common.constants";

export class UserRole {
	userid: string;
	superAdmin: boolean;
	branches: {[key: string]: Branch} = {};
	allAdminSiteCodes: string[] = [];
	siteCodeTypeHighestRole: AccessLevel | null;
	marketHighestRole: AccessLevel | null;
	siteCodeHighestRole: AccessLevel | null;
	tempAdmin:boolean;
	sctMrkSCAccess:boolean;
	sctMrkAccess:boolean;
	sctAccess:boolean;
	dealerUser:boolean;
	organizationUnit:string;
	constructor() {}

	private ensureAccessLevelsAreSet(roleInfo: RoleInfo): void {
		if (
			roleInfo &&
			roleInfo.accessLevels &&
			!(roleInfo.accessLevels instanceof Set)
		) {
			roleInfo.accessLevels = new Set(roleInfo.accessLevels);
		}
	}

	getSiteCodeTypeRoleInfo(branch: string, siteCodeType: string): RoleInfo {
		const roleInfo = this.branches[branch]?.children[siteCodeType]
			?.roleInfo || {
			accessLevels: new Set()
		};
		this.ensureAccessLevelsAreSet(roleInfo);
		return roleInfo;
	}

	getMarketRoleInfo(
		branch: string,
		siteCodeType: string,
		market: string
	): RoleInfo {
		const roleInfo = this.branches[branch]?.children[siteCodeType]
			?.children[market]?.roleInfo || {accessLevels: new Set()};
		this.ensureAccessLevelsAreSet(roleInfo);
		return roleInfo;
	}

	getSiteRoleInfo(
		branch: string,
		siteCodeType: string,
		market: string,
		siteCode: string
	): RoleInfo {
		const roleInfo = this.branches[branch]?.children[siteCodeType]
			?.children[market]?.children[siteCode]?.roleInfo || {
			accessLevels: new Set()
		};
		this.ensureAccessLevelsAreSet(roleInfo);
		return roleInfo;
	}

	private getHighestRoleInfo(
		branch: string,
		siteCodeType: string,
		market?: string,
		siteCode?: string
	): RoleInfo {
		let roleInfo: RoleInfo = {accessLevels: new Set()};
		let highestAccess: AccessLevel | null = null;

		if (siteCode && market) {
			const siteRoleInfo = this.getSiteRoleInfo(
				branch,
				siteCodeType,
				market,
				siteCode
			);
			const siteAccess = this.getHighestAccessLevel(siteRoleInfo);
			if (siteAccess) {
				roleInfo = siteRoleInfo;
				highestAccess = siteAccess;
				if(siteAccess == AccessLevel.USER_ADMIN)
					roleInfo.admin = "SC";
			}
		}

		if (!highestAccess && market) {
			const marketRoleInfo = this.getMarketRoleInfo(
				branch,
				siteCodeType,
				market
			);
			const marketAccess = this.getHighestAccessLevel(marketRoleInfo);
			if (marketAccess) {
				roleInfo = marketRoleInfo;
				highestAccess = marketAccess;
				if(marketAccess == AccessLevel.USER_ADMIN)
					roleInfo.admin = "MARKET";

			}
		}

		if (!highestAccess) {
			const siteCodeTypeRoleInfo = this.getSiteCodeTypeRoleInfo(
				branch,
				siteCodeType
			);
			const siteCodeTypeAccess =
				this.getHighestAccessLevel(siteCodeTypeRoleInfo);
			if (siteCodeTypeAccess) {
				roleInfo = siteCodeTypeRoleInfo;
				if(siteCodeTypeAccess == AccessLevel.USER_ADMIN)
					roleInfo.admin = "SCT";
			}
		}

		return roleInfo;
	}

	hasAccess(
		branch: string,
		siteCodeType: string,
		market: string,
		siteCodeOrRequiredAccess: string | AccessLevel,
		requiredAccess?: AccessLevel
	): boolean {
		if (this.superAdmin) {
			return true;
		}

		let roleInfo: RoleInfo;
		let accessLevel: AccessLevel;

		if (typeof siteCodeOrRequiredAccess === 'string') {
			const siteCode = siteCodeOrRequiredAccess;
			accessLevel = requiredAccess!;
			roleInfo = this.getSiteRoleInfo(
				branch,
				siteCodeType,
				market,
				siteCode
			);
		} else {
			accessLevel = siteCodeOrRequiredAccess;
			roleInfo = this.getMarketRoleInfo(branch, siteCodeType, market);
		}

		return this.evaluateAccess(accessLevel, roleInfo);
	}

	private evaluateAccess(
		requiredAccess: AccessLevel,
		roleInfo: RoleInfo
	): boolean {
		switch (requiredAccess) {
			case AccessLevel.GROUP_ADMIN:
				return roleInfo.accessLevels.has(AccessLevel.GROUP_ADMIN);
			case AccessLevel.USER_ADMIN:
				return (
					roleInfo.accessLevels.has(AccessLevel.USER_ADMIN) ||
					roleInfo.accessLevels.has(AccessLevel.GROUP_ADMIN)
				);
			case AccessLevel.MEMBER:
				return (
					roleInfo.accessLevels.has(AccessLevel.MEMBER) ||
					roleInfo.accessLevels.has(AccessLevel.USER_ADMIN) ||
					roleInfo.accessLevels.has(AccessLevel.GROUP_ADMIN)
				);
			default:
				return false;
		}
	}

	getPageAccess(
		pageType: PageType,
		branch?: string,
		siteCodeType?: string,
		market?: string,
		siteCode?: string
	): PageAccessType {
		if (this.superAdmin) {
			return this.getPageAccessForSuperAdmin(pageType);
		}
		if (!branch || !siteCodeType) {
			if(this.tempAdmin && pageType === PageType.MANAGE_TEMP_IDS)
				return PageAccessType.WRITE;
			if(this.dealerUser && [PageType.VIEW_MY_INFO, PageType.CHANGE_MY_PROFILE, PageType.CHANGE_MY_PASSWORD].includes(pageType)){
				return this.getMemberPageAccess(pageType);
			}
			if(this.sctMrkSCAccess && [PageType.SEARCH_USER].includes(pageType))
				return PageAccessType.WRITE;

			if(this.sctMrkSCAccess && [PageType.TREE_STRUCTURE,PageType.USER_REPORT,PageType.HELP_ACCESS,PageType.ACCESS_RIGHTS].includes(pageType))
				return PageAccessType.READ;

			if(this.sctMrkAccess && [PageType.ADMIN_REPORT,PageType.GROUP_AUDIT_REPORT,PageType.REPORT].includes(pageType))
				return PageAccessType.READ;

			return PageAccessType.NO_ACCESS;
		}
		const roleInfo = this.getHighestRoleInfo(
			branch,
			siteCodeType,
			market,
			siteCode
		);
		let highestAccess = this.getHighestAccessLevel(roleInfo);
		if(this.tempAdmin &&  pageType === PageType.MANAGE_TEMP_IDS){
			// Specific to manage user group admin tab
			switch (highestAccess) {
				case AccessLevel.GROUP_ADMIN:
					return PageAccessType.WRITE;
				case AccessLevel.USER_ADMIN:
					return PageAccessType.READ;
				default:
					return PageAccessType.NO_ACCESS;
			}
		}
		if(roleInfo.admin){
			highestAccess = this.checkLowerAndUpdateAccessLevel(pageType, highestAccess, roleInfo);
		}
		return this.determinePageAccess(pageType, highestAccess);
	}

	checkLowerAndUpdateAccessLevel(pageType: PageType, currentAccess: AccessLevel, roleInfo:RoleInfo): AccessLevel {
		if (([PageType.SC_ADMINISTRATORS,PageType.SC_MEMBERS,PageType.SC_SUPPORT_CENTER].includes(pageType)
				&& currentAccess === AccessLevel.USER_ADMIN && roleInfo.admin=="MARKET") ||
			([PageType.MARKET_ADMINISTRATORS,PageType.MARKET_SUPPORT_CENTER].includes(pageType)
				&& currentAccess === AccessLevel.USER_ADMIN && roleInfo.admin=="SCT")) {
				return AccessLevel.GROUP_ADMIN;
		}
		return currentAccess;
	}

	private getHighestAccessLevel(roleInfo: RoleInfo): AccessLevel | null {
		console.log('RoleInfo:',roleInfo);
		if(Array.isArray(roleInfo.accessLevels)){
			roleInfo.accessLevels = new Set(roleInfo.accessLevels)
		}
		if (roleInfo.accessLevels.has(AccessLevel.GROUP_ADMIN)) {
			return AccessLevel.GROUP_ADMIN;
		}
		if (roleInfo.accessLevels.has(AccessLevel.USER_ADMIN)) {
			return AccessLevel.USER_ADMIN;
		}
		if (roleInfo.accessLevels.has(AccessLevel.MEMBER)) {
			return AccessLevel.MEMBER;
		}
		return null;
	}

	private getPageAccessForSuperAdmin(pageType: PageType): PageAccessType {
		switch (pageType) {
			case PageType.VIEW_MY_INFO:
			case PageType.CHANGE_MY_PROFILE:
			case PageType.CHANGE_MY_PASSWORD:
				return PageAccessType.NO_ACCESS;

			case PageType.MANAGE_TEMP_IDS:
			case PageType.MANAGE_JOB_ROLES:
			case PageType.MANAGE_AUDIT_CONFIG:
			case PageType.SITE_RECOVERY_TOOL:
			case PageType.SCT_ADMINISTRATORS:
			case PageType.MARKET_ADMINISTRATORS:
			case PageType.MARKET_SUPPORT_CENTER:
			case PageType.SC_MEMBERS:
			case PageType.SC_ADMINISTRATORS:
			case PageType.CREATE_TEMP_ID:
				return PageAccessType.WRITE;

			case PageType.USER_REPORT:
			case PageType.ADMIN_REPORT:
			case PageType.GROUP_AUDIT_REPORT:
			case PageType.HELP_ACCESS:
			case PageType.TREE_STRUCTURE:
			case PageType.MARKET_AUDIT:
			case PageType.SC_AUDIT:
			case PageType.SC_SUPPORT_CENTER:
			case PageType.REPORT:
			case PageType.ACCESS_RIGHTS:
				return PageAccessType.READ;
			case PageType.SEARCH_USER:
				return PageAccessType.WRITE;
			default:
				return PageAccessType.NO_ACCESS;
		}
	}

	private determinePageAccess(
		pageType: PageType,
		accessLevel: AccessLevel | null
	): PageAccessType {
		if (!accessLevel) {
			return PageAccessType.NO_ACCESS;
		}

		switch (accessLevel) {
			case AccessLevel.GROUP_ADMIN:
				return this.getGroupAdminPageAccess(pageType);
			case AccessLevel.USER_ADMIN:
				return this.getUserAdminPageAccess(pageType);
			case AccessLevel.MEMBER:
				return this.getMemberPageAccess(pageType);
			default:
				return PageAccessType.NO_ACCESS;
		}
	}

	private getGroupAdminPageAccess(pageType: PageType): PageAccessType {
		switch (pageType) {
			case PageType.CHANGE_MY_PROFILE:
			case PageType.CHANGE_MY_PASSWORD:
				return PageAccessType.WRITE;
			case PageType.VIEW_MY_INFO:
			case PageType.TREE_STRUCTURE:
				return PageAccessType.READ;
			case PageType.SCT_ADMINISTRATORS:
			case PageType.MARKET_ADMINISTRATORS:
			case PageType.MARKET_SUPPORT_CENTER:
			case PageType.SC_MEMBERS:
			case PageType.SC_ADMINISTRATORS:
				return PageAccessType.WRITE;
			case PageType.MARKET_AUDIT:
			case PageType.SC_AUDIT:
			case PageType.ACCESS_RIGHTS:
			case PageType.SC_SUPPORT_CENTER:
				return PageAccessType.READ;
			case PageType.SEARCH_USER:
				return PageAccessType.WRITE;
			case PageType.USER_REPORT:
			case PageType.ADMIN_REPORT:
			case PageType.GROUP_AUDIT_REPORT:
			case PageType.HELP_ACCESS:
			case PageType.REPORT:
				return PageAccessType.READ;
			default:
				return PageAccessType.NO_ACCESS;
		}
	}

	private getUserAdminPageAccess(pageType: PageType): PageAccessType {
		switch (pageType) {
			case PageType.CHANGE_MY_PROFILE:
			case PageType.CHANGE_MY_PASSWORD:
			case PageType.MARKET_SUPPORT_CENTER:
			case PageType.SC_MEMBERS:
			case PageType.SEARCH_USER:
				return PageAccessType.WRITE;
			case PageType.VIEW_MY_INFO:
			case PageType.TREE_STRUCTURE:
			case PageType.SCT_ADMINISTRATORS:
			case PageType.MARKET_ADMINISTRATORS:
			case PageType.MARKET_AUDIT:
			case PageType.SC_AUDIT:
			case PageType.SC_ADMINISTRATORS:
			case PageType.ACCESS_RIGHTS:
			case PageType.USER_REPORT:
			case PageType.ADMIN_REPORT:
			case PageType.GROUP_AUDIT_REPORT:
			case PageType.HELP_ACCESS:
			case PageType.REPORT:
			case PageType.SC_SUPPORT_CENTER:
				return PageAccessType.READ;
			default:
				return PageAccessType.NO_ACCESS;
		}
	}

	private getMemberPageAccess(pageType: PageType): PageAccessType {
		switch (pageType) {
			case PageType.CHANGE_MY_PROFILE:
			case PageType.CHANGE_MY_PASSWORD:
				return PageAccessType.WRITE;
			case PageType.VIEW_MY_INFO:
			case PageType.TREE_STRUCTURE:
			case PageType.SC_AUDIT:
			case PageType.SC_SUPPORT_CENTER:
				return PageAccessType.READ;
			case PageType.USER_REPORT:
				return PageAccessType.READ;
			case PageType.HELP_ACCESS:
				return PageAccessType.READ;
			default:
				return PageAccessType.NO_ACCESS;
		}
	}

	checkAccessLevels(
		siteCodeTypeHighestRole: AccessLevel | null,
		marketHighestRole: AccessLevel | null = null,
		siteCodeHighestRole: AccessLevel | null = null
	): boolean {
		if (siteCodeTypeHighestRole === AccessLevel.GROUP_ADMIN ||
			siteCodeTypeHighestRole === AccessLevel.USER_ADMIN) {
			return true;
		}
		if (marketHighestRole === AccessLevel.USER_ADMIN ||
			marketHighestRole === AccessLevel.GROUP_ADMIN) {
			return true;
		}
		if (siteCodeHighestRole === AccessLevel.USER_ADMIN ||
			siteCodeHighestRole === AccessLevel.GROUP_ADMIN) {
			return true;
		}
		return false;
	}

	compareRoles(role1: AccessLevel, role2: AccessLevel) {
		const roleArr = [AccessLevel.MEMBER, AccessLevel.USER_ADMIN, AccessLevel.GROUP_ADMIN];
		const role1Index = roleArr.indexOf(role1);
		const role2Index = roleArr.indexOf(role2);
		return role1Index > role2Index ? role1 : role2;
	}

	checkNode(branchNode) {
		if (!branchNode) return false; // Handle case where branch might not exist
		if (branchNode.roleInfo && (branchNode.roleInfo.highestAccessLevel === AccessLevel.GROUP_ADMIN || branchNode.roleInfo.highestAccessLevel === AccessLevel.USER_ADMIN)) {
			return true;
		}
		if (branchNode.children) {
			for (const child in branchNode.children) {
				if (this.checkNode(branchNode.children[child])) {
					return true;
				}
			}
		}
		return false;
	}

	fetchHighestRolesAndUsersDetails(){
		if(this.superAdmin)	return;
		const roles = this.getHighestRoles();
		console.log("Roles:",roles);
		this.siteCodeTypeHighestRole =roles.siteCodeTypes;
		this.siteCodeHighestRole =roles.siteCodes;
		this.marketHighestRole =roles.markets;
		this.tempAdmin = Object.hasOwn(this.branches, CommonConstants.TEMPORARY) && this.checkNode(this.branches[CommonConstants.TEMPORARY]);
		this.sctMrkSCAccess = this.checkAccessLevels(this.siteCodeTypeHighestRole, this.marketHighestRole,this.siteCodeHighestRole);
		this.sctMrkAccess = this.checkAccessLevels(this.siteCodeTypeHighestRole, this.marketHighestRole);
		this.sctAccess = this.checkAccessLevels(this.siteCodeTypeHighestRole);
		this.dealerUser = [CommonConstants.DEALER,CommonConstants.OTHER].includes(this.organizationUnit)
	}

	getHighestRoles() {
		const results = {
			siteCodes: null,
			markets: null,
			siteCodeTypes: null
		};
		[CommonConstants.DEALERSHIP,CommonConstants.OTHER].forEach(branch=>{
			for (const siteCodeTypeKey in this.branches[branch]?.children) {
				const siteCodeTypeData = this.branches[branch].children[siteCodeTypeKey];
				if(results.siteCodeTypes != AccessLevel.GROUP_ADMIN) {
					let siteCodeTypeHighestRole:AccessLevel = this.getHighestAccessLevel(siteCodeTypeData.roleInfo);
					results.siteCodeTypes = this.compareRoles(siteCodeTypeHighestRole, results.siteCodeTypes);
				}
				for (const marketKey in siteCodeTypeData.children) {
					const marketData = siteCodeTypeData.children[marketKey];
					if(results.markets != AccessLevel.GROUP_ADMIN) {
						let marketHighestRole = this.getHighestAccessLevel(marketData.roleInfo);
						results.markets = this.compareRoles(marketHighestRole, results.markets);
					}
					for (const siteCodeKey in marketData.children) {
						const siteCodeData = marketData.children[siteCodeKey];
						if(results.siteCodes != AccessLevel.GROUP_ADMIN) {
							const siteCodeHighestRole = this.getHighestAccessLevel(siteCodeData.roleInfo);
							results.siteCodes = this.compareRoles(siteCodeHighestRole, results.siteCodes);
						}
					}
				}
			}
		})
		return results;
	}

	isSuperAdmin(){
		return this.superAdmin
	}
}
