import { Injectable } from '@angular/core';
import {SpinnerState} from "../models/spinner-state.model";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
	spinnerSubject = new BehaviorSubject<SpinnerState>({isLoading:true});
	spinnerObservable = this.spinnerSubject.asObservable();

  constructor() { }

	setInitialLoadSpinner(state: SpinnerState) {
		this.spinnerSubject.next(state);
	}
}
