import { Component, OnDestroy, OnInit } from '@angular/core';
import { SearchUserService } from '../../services/search-users/search-user.service';
import {Subject, map, takeUntil, finalize, switchAll, switchMap, of, Observable, concatMap} from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Users } from "../users-interfaces";
import { GroupInfo } from '../group-details';
import { UserRole } from 'src/app/common/models/user-role/user-role.model';
import { AccessLevel } from 'src/app/common/models/user-role/access-level.enum';
import { UserRoleService } from 'src/app/common/services/user-role/user-role.service';
import { SiteDetails } from '../search-constants';
import {PageType} from "../../../common/models/user-role/page-type.enum";
import {BaseUserGroupModel} from "../../../user-groups/base-usergroup.model";
import {AdminFilterPipe} from "../../../common/admin-filter.pipe";
import {CommonConstants} from "../../../common/common.constants";
import {mark} from "@angular/compiler-cli/src/ngtsc/perf/src/clock";
import {tap} from "rxjs/operators";

interface UserData {
  userId: string;
  firstName: string;
  lastName: string;
  primaryLocation: string;
}
@Component({
  selector: 'search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss']
})
export class SearchUsersComponent implements OnInit, OnDestroy {
  usersForm: FormGroup;
  loading: boolean = false;
  firstLoad: boolean;
  multiselect: boolean = false;
  editUser: boolean = false;
  userDetails: Users;
  baseUserModel: BaseUserGroupModel;
  disableInputs: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(private searchUserService: SearchUserService, private formBuilder: FormBuilder,
			  private userRoleService: UserRoleService) { }


  ngOnInit() {
    this.disableInputs = false;
    this.firstLoad = true;
    this.usersForm = this.formBuilder.group({
      searchValue: ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9-]*$")]]
    });
    this.getProfileUpdateStatus();
    this.initializeUserRole();
  }

  getProfileUpdateStatus(): void {
    this.searchUserService.getProfileUpdateStatus().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data)
        this.onClickSearch();
    });
  }

  //TODO: Will be modified based on changes in user-role service
  initializeUserRole(): void {
    this.userRoleService.ensureInitialized().then(() => {
      console.log('User role service initialized');
    })
      .catch((error) => {
        console.error('Failed to initialize user role service', error);
      });
  }

	onClickSearch(): void {
		this.loading = true;
		this.firstLoad = false;
    this.disableInputs = false;
		this.searchUserService.searchUsers(this.usersForm.value.searchValue)
			.pipe(
				tap(userDetail => console.log('User detail received:', userDetail)),
				concatMap((userDetail: Users) => {
					console.log('Checking dealership access...');
					const hasDealershipAccess = this.checkSearchUserAccess(
						CommonConstants.DEALERSHIP,
						userDetail.fordSiteCodeType,
						userDetail.market,
						userDetail.fordSiteCode
					);
					console.log("Dealer Access:", hasDealershipAccess);
					if (hasDealershipAccess) {
						return of(userDetail);
					}
					console.log('Checking other access...');
					const hasOtherAccess = this.checkSearchUserAccess(
						CommonConstants.OTHER,
						userDetail.fordSiteCodeType,
						userDetail.market,
						userDetail.fordSiteCode
					);
					console.log("Other Access:", hasOtherAccess);
					if (hasOtherAccess) {
						return of(userDetail);
					}
					console.log("Performing secondary search...");
					return this.searchUserService.getGroupsForUser(userDetail.userId).pipe(
						switchMap((response: any) => {
							console.log('Received group details:', response.groupDetails);
							return this.checkSecondaryLevelAccess(userDetail, response.groupDetails);
						})
					);
				}),
				takeUntil(this.destroy$),
				finalize(() => this.loading = false)
			)
			.subscribe({
				next: (data: Users | any) => {
					console.log('Details:', data);
					this.userDetails = data;
				},
				error: error => {
					console.error('Error:', error);
					this.userDetails = null;
				}
			});
	}


	checkSearchUserAccess(branch:string,siteCodeType:string,market:string,siteCode:string){
	  const baseUserModel = {
		  branch,
		  siteCodeType,
		  market,
		  siteCode
	  };
	  console.log("checking for the comb:",baseUserModel);
	  return this.userRoleService.checkViewAccess(PageType.SEARCH_USER, baseUserModel, true);
  }

	checkSecondaryLevelAccess(userDetail: Users, detailArr: any[]): Observable<Users | null> {
		if (detailArr.length === 0) {
			return of(null);
		}
		const hasAccess = detailArr.some((detail: any) => {
			console.log('some detail',detail);
			const [branch, siteCodeType, market, siteCode] = detail.cn.split("-");
			return this.checkSearchUserAccess(branch,siteCodeType,market,siteCode);
		});
    let userRole: UserRole = this.userRoleService.getUserRoleDetailsForSearchUser();
    this.disableInputs = userRole && !userRole?.allAdminSiteCodes.includes(userDetail.fordSiteCode);
		console.log('secondary access',hasAccess,this.disableInputs);
		return hasAccess ? of(userDetail) : of(null);
	}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

    protected readonly PageType = PageType;
}
