import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import {UserIdService} from '../services/user-id.service';
import {AuthService} from '../services/auth.service';

/**
 * This guard is used across all app routes that require live data,
 * determining if the auth token is valid before allowing navigation to occur
 */
@Injectable()
export class AuthenticationGuard implements CanActivate {
	/** Creates an instance of the AuthenticationGuard
	 *
	 * @param router used for navigation actions
	 * @param idService used for storing user information
	 * @param authService used for redirecting to authentication provider
	 */
	constructor(
		private router: Router,
		private idService: UserIdService,
		private authService: AuthService
	) {}

	/** the actual guard for routing. Uses the current token information to determine if the user needs to go through the login flow again
	 *
	 * @param _route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not
	 */
	canActivate(
		_route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean>  {
		// Store state url in sessionStorage for later redirect by callback component
		// The route parameter may have potential special character that needs to be decoded before redirecting.
		// the routing will fail if there are encoded special characters in a parameterized route
		const decodedURI = decodeURI(state.url);
		const pageType = _route?.data['pageType'];
		console.log('Page Type:', pageType);
		sessionStorage.setItem('redirectURL', decodedURI);
		return this.authService.checkLogin(_route);
	}

}
