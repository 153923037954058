<form [formGroup]="editJobForm" (ngSubmit)="onSubmit()">

<p-dialog header="Header" [(visible)]="openModal" [modal]="true"  class="dialoghw" (onHide)="closeDialog()" [closable]="true">


  <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{"header.editJobRole" | translate}}</span>
  </ng-template>
  <div class="flex align-items-center justify-content-center" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <ng-container *ngIf="!loading">
    <div class="card flex flex-row align-items-center gap-6 p-6">
      <span class="p-float-label">
          <p-dropdown [style]="{width:'200px'}"  (onChange)="onChangeBranch()" [options]="branchesList"  formControlName="selectedBranch" placeholder="{{'placeholder.selectBranch' | translate}}" optionLabel="branchName" [filter]="true" filterBy="branchName" inputId="float-label" [disabled]="true" ></p-dropdown>
          <label class="editinput" for="float-label">{{"label.branchName" | translate}}</label>
      </span>
      <span class="p-float-label">
        <p-dropdown  [style]="{width:'200px'}" [options]="siteCodeTypeList" formControlName="selectedSiteCodeType" (onChange)="onChangeSiteCodeType()" placeholder="{{'placeholder.selectSiteCodeType' | translate}}" optionLabel="siteCodeType" [filter]="true" filterBy="siteCodeType" inputId="float-label" [disabled]="true"></p-dropdown>
        <label class="editinput" for="float-label">{{"label.siteCodeType" | translate}}</label>
    </span>
    <span class="p-float-label">
      <p-dropdown [style]="{width:'200px'}" [options]="marketList" formControlName="selectedMarket" (onChange)="onChangeMarket()"  placeholder="{{'placeholder.selectMarket' | translate}}" optionLabel="marketName" [filter]="true" filterBy="marketName" inputId="float-label" [disabled]="true"></p-dropdown>
      <label class="editinput" for="float-label">{{"label.market" | translate}}</label>
  </span></div>
  <div class="card flex flex-row justify-content-center gap-6 p-3">
      <span class="p-float-label">
        <input [style]="{width:'200px'}" pInputText  formControlName="jobRoleCode" [disabled]="true" />
        <label class="editinput"  htmlFor="jobrolecode">{{"label.jobRoleCode" | translate}}</label>
    </span>
    <span class="p-float-label">
      <input [style]="{width:'200px'}"  pInputText formControlName="jobRoleName" />
      <label class="editinput" htmlFor="jobrolename">{{"label.jobRoleName" | translate}}</label>
    </span>
    </div>
  </ng-container>

  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" type="submit" [loading]="loadingButton" label="{{'button.update' | translate}}" [disabled]="!editJobForm.valid" styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-times" (click)="closeDialog()" label="{{'button.cancel' | translate}}" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
</form>
<p-dialog header="Header" [(visible)]="visible" [modal]="true">
  <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{"header.updateResponse" | translate}}</span>
  </ng-template>
  <p *ngIf="!loadingDialog">
      {{responseMessage}}
  </p>
  <div class="flex align-items-center justify-content-center" *ngIf="loadingDialog">
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  </div>
  <ng-template  pTemplate="footer">
      <p-button icon="pi pi-danger" (click)="closeResponseDialog()" label="{{'button.close' | translate}}" styleClass="p-button-text"></p-button>
</ng-template>
</p-dialog>
