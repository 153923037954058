import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UserGroupsService } from '../services/user-groups.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DropdownFilterEvent, DropdownModule } from 'primeng/dropdown';
import { finalize, Subject, takeUntil } from 'rxjs';
import { BaseUserGroupModel } from '../base-usergroup.model';
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {DynamicDialogActionsComponent} from "../../common/dynamic-dialog-actions/dynamic-dialog-actions.component";
import { MemberTableModel } from '../members-table/member-user.model';
import { Column } from '../manage-user-groups/manage-user-groups.model';
import { CommonConstants } from 'src/app/common/common.constants';
import { SearchSiteArray } from '../members-table/search-site.model';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-transfer-user',
  templateUrl: './transfer-user.component.html',
  styleUrls: ['./transfer-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferUserComponent {

  visible:boolean = false;
  transferUserForm: FormGroup;
  enableDialog:boolean=false;
  loading: boolean = false;
  loadingText: boolean=false;
	filterErrorMsg: boolean;
  transferedUser:boolean = false;
  responseModalTitle: string = 'label.response';
	responseMessage: string = '';
  @Input() selectedSiteCodeType: string;
  @Input() selectedMarket: string;
  @Input() selectedBranch: string;
  @Input() sourceSiteCode: string;
  @Input() baseUserGroupModel: BaseUserGroupModel;
  @Input() siteCodes: [];
  @Input() selectedMembersForAction: MemberTableModel[] = [];
  private onDestroy$ = new Subject<void>();
  ref: DynamicDialogRef | undefined;
  // selectedMembersForAction: MemberTableModel[] = [];
  activeMembers: MemberTableModel[] = [];
	InactiveMembers: MemberTableModel[] = [];
  cols!: Column[];
  data: any[];
  filteredSiteCodes: any[];

  constructor(private userGroupService: UserGroupsService,private fb: FormBuilder, public dialogService: DialogService, private changeDetectorRef: ChangeDetectorRef,		private translate:TranslateService) {
    this.userGroupService.getTransferUser().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.visible=data;
      if(this.visible){
        this.loadTransferForm();
      }
    });
    this.transferUserForm = this.fb.group({
      destinationSiteCodeType: new FormControl({value:this.baseUserGroupModel?.siteCodeType,disabled: true}, Validators.required),
      destinationCountryCode: new FormControl({value:this.baseUserGroupModel?.market,disabled: true}, Validators.required),
      destinationSiteCode: new FormControl('', Validators.required),
	});
}

  ngOnInit() {
    this.cols = [
			{field: 'userId', header: 'User ID'},
			{field: 'firstName', header: 'First Name'},
			{field: 'lastName', header: 'Last Name'},
		];
    this.loadingText = false;
    this.filterErrorMsg = false;
    }

  loadTransferForm(){
    this.transferUserForm.patchValue({
      destinationSiteCodeType: this.baseUserGroupModel?.siteCodeType,
      destinationCountryCode: this.baseUserGroupModel?.market
    });

  }



  siteCodeValidator(control: FormControl): { [key: string]: any } | null {

    if (control.value && control.value.length > 0) {
      return null;
    }
    if(control.value.localeCompare(this.sourceSiteCode) == 0){
      return { 'siteCodes': true };
    }
    return null;
  }
  transferMember(){
      this.ref = this.dialogService.open(DynamicDialogActionsComponent,

      {
        header: this.translate.instant('button.transfer'), width: "50%",
        data:{
          selectedItems: this.selectedMembersForAction,
          cols: this.cols,
          actionType: "TransferUsers",
          dialogMessageHead: "message.transferUserHead",
          dialogMessageBody: "message.transferUserBody",
          dialogMessageFoot: "message.transferUserFoot",
          siteCode: this.transferUserForm.get('destinationSiteCode').value
        }
      });
      this.ref.onClose.subscribe((data) => {
        if(data?.close){

          this.transferUser(data?.targetSiteCode);
        }
      });
  }

  transferUser(targetSiteCode:string){
    this.enableDialog = true;
    this.closeDialog();
    this.loading=true;
    this.changeDetectorRef.markForCheck();
    let request = {
      "userId": this.selectedMembersForAction.filter(member=>member.location==CommonConstants.PRIMARY).map(member => member.userId),
      "sourceSiteCodeType": this.baseUserGroupModel.siteCodeType,
      "sourceSiteCode": this.baseUserGroupModel.siteCode,
      "targetSiteCode": targetSiteCode,
      "sourceCountry": this.baseUserGroupModel.market,
      "lastModifiedBy": sessionStorage.getItem("userId"),
    }
    this.userGroupService.transferUserService(request).subscribe({
			next: data => {
        this.loadingText=true;
        this.loading=false;
        this.changeDetectorRef.markForCheck();
				// this.getUsersForSiteCodes(this.baseUserGroupModel);
				this.responseModalTitle = "message.transferUser";
				this.responseMessage = "message.transferUserSuccess";
				this.selectedMembersForAction=[];
				this.changeDetectorRef.markForCheck();
			},
			error: error => {
        this.loadingText=true;
        this.loading=false
        this.changeDetectorRef.markForCheck();
				this.responseModalTitle = "message.errorResponse";
				this.responseMessage = "message.transferUserFailure"
				this.selectedMembersForAction=[];
				this.changeDetectorRef.markForCheck();
			}
		});
  }

  onFilter(event: DropdownFilterEvent) {
    const filterValue = event.filter;
    this.filteredSiteCodes = this.siteCodes.filter((siteCode: any) => {
      return siteCode.value.toLowerCase().includes(filterValue);
    });
  }

  onEnter() {
    if (this.filteredSiteCodes?.length > 0) {
      const selectedSiteCode = this.filteredSiteCodes[0];
      this.transferUserForm?.patchValue({
        destinationSiteCode: selectedSiteCode.value
      });
	  setTimeout(()=>this.changeDetectorRef.markForCheck(),50);

    }
  }
  closeDialog(){
    this.visible = false;
    this.responseModalTitle = "Response";
    this.responseMessage = "";
    this.userGroupService.createTransferUser(this.visible);
    this.changeDetectorRef.markForCheck();
    this.transferUserForm.reset({
      destinationSiteCode:''
    })
  }
  closeRespDialog(){
    this.enableDialog=false;
    this.loadingText=false;
    this.userGroupService.updateTransferRefresh(true);
  }
  onSubmit(){
    this.transferMember();
  }

  ngOnDestroy(){
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
