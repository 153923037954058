
<div>
  <div>
    <p class="text-red-500 p-4 text-center">
      {{dialogMessageHead | translate}}
    </p>
    <div *ngIf="actionType==='TransferUsers'" class="card flex justify-content-center align-items-center gap-3 mb-4">
      <div class="col-4">
        <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">  {{"label.siteCode" | translate }} </label><span class="text-red-500"> *</span></div>
          <input class="w-full" type="text" pInputText [(ngModel)]="destinationSiteCode" disabled placeholder="{{'placeholder.enterSiteCode' | translate }}" />
      </div>
    </div>
    <div *ngIf="actionType==='AddSecondarySite'" class="card flex justify-content-center align-items-center gap-3 mb-4">
      <div class="col-4">
        <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">  {{"label.siteCode" | translate }} </label><span class="text-red-500"> *</span></div>
          <p-dropdown  styleClass="w-full" [(ngModel)]="siteCodeSecondary" (onFilter)="onFilter($event)" appEnterKeyFilter (enterKey)="onEnter()" (onChange)="onInputChange($event)" [options]="siteCodeSecondaryList"  optionValue="value" optionLabel="value" [showClear]="true" [filter]="true" filterBy="value" placeholder="{{'placeholder.selectSiteCode' | translate}}" virtualScroll="true" virtualScrollItemSize="50"></p-dropdown>
        </div>
      <div *ngIf="secondaryLoader" class="card flex justify-content-center spinner-mask">
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    </div>
   <div> <span *ngIf="enableDescription()" class="text-color-2 flex p-2 mt-4 user-sec-label">{{siteCodeDescription}}</span></div>
    <!-- <p *ngIf="siteCodeDescription" class="text-red-500 p-4 text-center align-items-end">{{siteCodeDescription}}</p> -->
    </div>
    <p *ngIf="dialogMessageBody" class="text-orange-500 p-4 text-center">
      {{dialogMessageBody | translate}}
    </p>
  </div>
  <div>
    <div class="card mb-4">
      <div *ngIf="actionType=='RemoveMembers'">
        <p-table [columns]="cols" [value]="selectedItems" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                {{ rowData[col.field] }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div *ngIf="actionType=='AddSecondarySite'">
        <p-table [columns]="cols" [value]="selectedPrimaryUsers" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                {{ rowData[col.field] }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <h4 *ngIf="selectedItems?.length == 0;" class="text-center">
        {{"message.noItemsSelected" | translate}} </h4>
    </div>
    <div *ngIf="actionType==='AddSecondarySite' && selectedSecondaryUsers?.length" class="card">
      <p class="text-center text-red-400 mb-2">{{"message.canNotAddMembers" | translate}}</p>
      <p-table  [columns]="cols" [value]="selectedSecondaryUsers" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <h4 *ngIf="selectedSecondaryUsers?.length == 0;" class="text-center">
        {{"message.noItemsSelected" | translate}} </h4>
    </div>
    <div *ngIf="actionType==='TransferUsers' && selectedSecondaryUsers?.length" class="card">
      <p class="text-center text-red-400 mb-2">{{"message.canNotTransferMembers" | translate}}</p>
      <p-table  [columns]="cols" [value]="selectedSecondaryUsers" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
      <h4 *ngIf="selectedSecondaryUsers?.length == 0;" class="text-center">
        {{"message.noItemsSelected" | translate}} </h4>
    </div>
    <p class="text-red-500 p-4 text-center">{{dialogMessageFoot | translate}}</p>
    <div class="flex justify-content-center gap-3">
        <p-button icon="pi pi-check" (click)="closeRef(true)" label="{{'label.yes' | translate}}"
                styleClass="p-button-text"[disabled]="disableYes()" ></p-button>
          <p-button icon="pi pi-danger" (click)="close()" label="{{'label.no' | translate}}"
                styleClass="p-button-text"></p-button>
    </div>
  </div>
