<div class="flex justify-content-evenly bg-color-2 menubar-container" >
	<div id="menu-bar" class="overflow-y-auto" [class.hidden]="isCollapsed">
		<!-- <button (click)="toggleMenu()">Toggle Menu</button> -->
		<div class="flex flex-column">
			<div class="p-1">
				<ng-container *ngFor="let item of items | adminFilter">
					<ul
						class="list-none pb-0 m-0 mt-2"
						[ngClass]="item?.noAccordion ? 'p-0' : 'p-3'">
						<li>
							<div
								*ngIf="item?.items?.length >= 1"
								pRipple
								pStyleClass="@next"
								enterClass="hidden"
								enterActiveClass="slidedown"
								leaveToClass="hidden"
								leaveActiveClass="slideup"
								class="p-2 flex align-items-center justify-content-between text-color-1 cursor-pointer p-ripple">
								<ng-container *ngIf="!item?.noAccordion">
									<span class="font-semibold text-xl">
										{{ item.label | translate}}
									</span>
									<i class="pi pi-chevron-down"></i>
								</ng-container>
							</div>
							<ul class="list-none p-0 m-0 overflow-hidden">
								<li *ngFor="let subitems of item.items">
									<a
										pRipple
										(click)="
											redirectToPage(
												subitems.routerLink,
												subitems.label
											)
										"
										class="flex align-items-center cursor-pointer p-3 border-round text-color-1 hover:surface-100 transition-duration-150 transition-colors p-ripple">
										<i
											class="mr-2"
											[ngClass]="subitems.icon"></i>
										<span
											*ngIf="item?.noAccordion"
											class="font-semibold text-xl">
											{{ subitems.label | translate}}
										</span>
										<span
											*ngIf="!item?.noAccordion"
											class="font-medium"
											>{{ subitems.label | translate}}</span
										>
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</ng-container>
			</div>
		</div>
	</div>
	<div>
		<p-button
			class="menu-bar-icon"
			styleClass="shadow-none"
			(click)="toggleMenu()"
			[pTooltip]="isCollapsed ? 'Expand Menu Bar' : 'Collapse Menu Bar'"
			[icon]="
				isCollapsed
					? 'pi pi-chevron-circle-right'
					: 'pi pi-chevron-circle-left'
			" />
	</div>
</div>
