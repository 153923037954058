import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {SharedCommonModule} from "../common/shared.module";
import {AdminMemberTableComponent} from "./admin-member-table/admin-member-table.component";
import {MembersTableComponent} from "./members-table/members-table.component";
import {ManageUserGroupsComponent} from "./manage-user-groups/manage-user-groups.component";
import {CreateNewUserComponent} from "./create-new-user/create-new-user.component";
import {TransferUserComponent} from "./transfer-user/transfer-user.component";
import {AuditStatusComponent} from "./audit-status/audit-status.component";
import {SupportCenterComponent} from "./support-center/support-center.component";
import {SearchTreeNodeComponent} from "./search-tree-node/search-tree-node.component";
import {AdminUserGroupsComponent} from "./admin-user-groups/admin-user-groups.component";

@NgModule({
	imports: [
		FormsModule,
		ReactiveFormsModule,
		SharedCommonModule
	],
	declarations:[
		MembersTableComponent,
		ManageUserGroupsComponent,
		CreateNewUserComponent,
		TransferUserComponent,
		AuditStatusComponent,
		SupportCenterComponent,
		SearchTreeNodeComponent,
		AdminUserGroupsComponent,
		AdminMemberTableComponent
	],exports:[AdminMemberTableComponent]
})
export class UserGroupsModule { }
