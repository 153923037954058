import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter, Input,
	NgZone,
	OnInit,
	Output
} from '@angular/core';
import {TreeNode} from "primeng/api";
import {catchError, finalize, map, of, Subject, takeUntil, tap} from "rxjs";
import {UserGroupsService} from "../services/user-groups.service";
import {SearchSiteArray} from "../members-table/search-site.model";
import {ApiResponse, Branch} from "./search-node.model";


@Component({
	selector: 'search-tree-node',
	templateUrl: './search-tree-node.component.html',
	styleUrls: ['./search-tree-node.component.scss'],
	changeDetection:ChangeDetectionStrategy.OnPush
})
export class SearchTreeNodeComponent implements OnInit {

	@Output() expandSelectEmitter = new EventEmitter<TreeNode>();
	@Output() nodeSelectEmitter = new EventEmitter<TreeNode>();
	@Input() selectedNodes:TreeNode;
	data: TreeNode[] = [];
	showDropDownBtn: boolean = false;
	id: any = 'drop-down-' + Math.floor(Math.random() * 1000);
	dropDownEl: any;
	firstTimeLoad = true;
	visible: boolean = false;
	lastParentNode: TreeNode | null = null;
	firstParentNode: TreeNode | null = null;
	loading: boolean;
	filterErrorMsg: boolean;
	branchList: Branch[];
	prevNode: TreeNode;
	private destroy$ = new Subject<void>();

	constructor(private userGroupService: UserGroupsService, private ngZone: NgZone,
				private changeDetectorRef: ChangeDetectorRef) {
		this.userGroupService.fetchBranchList()
			.pipe(
				tap((response: any[]) => console.log("TOP GROUP", response)),
				map((responseArray: any[]) => responseArray.map((response) => ({
					name: response?.branchName,
					code: response?.branchCode
				})))
			)
			.subscribe((data: Branch[]) => {
			this.branchList = data;
			this.getTopGroupsList();
			this.changeDetectorRef.markForCheck();
		})
	}

	ngOnInit(): void {
		this.loading = true;
		this.filterErrorMsg = false;
	}

	openDropDownTree(event) {
		if (this.firstTimeLoad) {
			this.dropDownEl = document.getElementById(this.id);
			document.getElementById("tree-input").appendChild(this.dropDownEl);
			this.firstTimeLoad = false;
		}
		this.showDropDown()
	}

	showDropDown() {
		this.visible = !this.visible;
	}

	clearFilter(event) {
		event.value = null;
		this.data = [];
		this.loading = true;
		this.filterErrorMsg = false;
		this.changeDetectorRef.markForCheck();
		this.getTopGroupsList()
		this.changeDetectorRef.markForCheck();
	}

	getTopGroupsList() {
		this.getTopGroupsMock();
		// this.getTopGroups();
	}

	searchSiteGroup(event) {
		this.filterErrorMsg = false;
		if (!event?.validity?.valid) {
			this.filterErrorMsg = true;
			return;
		}
		let value = event.value;
		this.loading = true;
		this.userGroupService.searchSiteCode(value)
			.pipe(finalize(() => {
				this.loading = false;
				this.changeDetectorRef.markForCheck()
			}))
			.subscribe((data: SearchSiteArray) => {
				const result = [];
				data.hierarchies.forEach(item => {
					let groupObj = result.find(group => group.label == item.group);
					if (!groupObj) {
						groupObj = this.mapResponseToNode(item.group);
						result.push(groupObj)
					}
					let subGroupObj = groupObj.children.find(subGroup => subGroup.label === item.subGroup);

					if (!subGroupObj) {
						subGroupObj = this.mapResponseToNode(item.subGroup);
						groupObj.children.push(subGroupObj);
					}
					;
					let marketObj = subGroupObj.children.find(market => market.label == item.country);

					if (!marketObj) {
						marketObj = this.mapResponseToNode(item.country);
						subGroupObj.children.push(marketObj);
					}
					if (!marketObj.children.some(site => site.label === item.siteCode)) {
						marketObj.children.push(this.mapResponseToNode(item.siteCode, false));
					}
				});

				this.data = result && result.length > 0 ? result : [];
				this.changeDetectorRef.markForCheck();
			})
	}

	mapResponseToNode(label, child = true) {
		let node: TreeNode = {
			label: label,
			data: label,
			expanded: true
		};
		if (child) {
			let leaf = true;
			let children = [];
			return {...node, leaf, children};

		} else {
			return node;
		}
	}

	getTopGroupsMock() {
		of<ApiResponse>({
			result: this.branchList
		})
			.pipe(
				tap(data => {
					console.log(data)
				}),
				map((response: ApiResponse) =>
					response.result.map((result, index, array) => ({
						label: result.name,
						data: result.name,
						children: [{}],
						expanded: false,
						isLastParent: index === array.length - 1
					})),
				),
				finalize(() => {
					this.showDropDownBtn = true;
					this.loading = false;
					this.changeDetectorRef.markForCheck()
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(
				(data) => {
					this.data = data;
					this.lastParentNode = this.data[this.data.length - 1];
					this.firstParentNode = this.data[0];
					this.changeDetectorRef.markForCheck();
				}
			);
	}

	onNodeExpand(event: any) {
		if(event.node.data?.loadMore) 
			{
				this.loadMoreData(event.node) 
				return;
			}
		this.handleNodeExpand(event.node);
	}

	onNodeCollapse(event: any) {
		this.collapseNode(event.node);
	}

	private handleNodeExpand(node: TreeNode) {
		if (!node.parent) {
			this.clearOtherTopLevelSelections(node);
		}

		if (
			!node.children ||
			(node.children.length === 1 && !node.children[0].label)
		) {
			this.loading = true;
			this.loadNodeChildren(node).then(() => {
				this.loading = false;
				this.expandNode(node);
				if (
					node === this.lastParentNode ||
					node === this.firstParentNode
				) {
					this.scheduleTreeUpdate(node);
				}
			});
		} else {
			this.expandNode(node);
			this.scheduleTreeUpdate(node);
		}
	}

	private scheduleTreeUpdate(node: TreeNode) {
		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
				this.ngZone.run(() => {
					this.expandNode(node);
					this.forceTreeUpdate();
				});
			}, 0);
		});
	}

	private expandNode(node: TreeNode) {
		node.expanded = true;
		this.updateTreeState();
	}

	updateSelection(node) {
		this.expandSelectEmitter.emit(node);
	}

	private collapseNode(node: TreeNode) {
		node.expanded = false;
		this.updateTreeState();
	}

	clearOtherTopLevelSelections(selectedNode: TreeNode) {
		this.data.forEach((topLevelNode) => {
			if (topLevelNode !== selectedNode) {
				this.collapseNodeAndChildren(topLevelNode);
			}
		});
	}

	collapseNodeAndChildren(node: TreeNode) {
		node.expanded = false;
		if (node.children && node.children.length > 0) {
			node.children.forEach((child) =>
				this.collapseNodeAndChildren(child)
			);
		}
	}

	private loadNodeChildren(node: TreeNode): Promise<void> {
		return new Promise((resolve) => {
			
			let observable;

			const nodeLevel = this.getNodeLevel(node);

			switch (nodeLevel) {
				case 1:
					observable = this.userGroupService.getSiteCodeTypes(
						node.data
					);
					break;
				case 2:
					observable = this.userGroupService.getMarkets(
						node.parent!.data,
						node.data
					);
					break;
				case 3:
					observable = this.userGroupService.getSiteCodes(
						node.parent!.parent!.data,
						node.data,
						node.parent!.data
					);
					break;
				default:
					console.error(`Unexpected node level: ${nodeLevel}`);
					resolve();
					return;
			}

			observable
				.pipe(
					map((response: any) =>
						this.mapResponseToNodes(response, nodeLevel)
					),
					tap((responseNodes:any[])=>{
						if(responseNodes?.length > 200){
							node.children = responseNodes.slice(0, 200);
							node.children.push({ label: 'Load More',expanded: false,leaf:true,children:[{}],data:{loadMore:true,responseList:responseNodes}});
						}else{
							node.children = responseNodes;
						}
					}),
					catchError((error) => {
						console.error('Error loading node children:', error);
						return of([]);
					}),
					finalize(() => {
						this.changeDetectorRef.markForCheck();
					}),
					takeUntil(this.destroy$)
				)
				.subscribe((children) => {
					if(!node.children)
						node.children = children;
					node.expanded = true;
					this.updateTreeState();
					resolve();
				});
		});
	}

	loadMoreData(node:TreeNode): void {
		// event.stopPropagation();
		const parentNode:TreeNode = node.parent;
		const allNodeList =node.data?.responseList;
		const currLenght = parentNode?.children.length-1;
		if(allNodeList){
			const slicednodes = allNodeList.slice(currLenght,currLenght+200);
			parentNode.children = [
				...parentNode.children.slice(0,-1),
				...slicednodes
			]
			if(allNodeList.length > parentNode?.children.length){
				parentNode.children.push({label:'Load More',expanded: false,children:[{}],data:{loadMore:true,responseList:allNodeList}});
			}
		}
		this.updateTreeState();
	}

	private getNodeLevel(node: TreeNode): number {
		let level = 1;
		let parent = node.parent;
		while (parent) {
			level++;
			parent = parent.parent;
		}
		return level;
	}

	private mapResponseToNodes(response: any, level: number): TreeNode[] {
		if (response.siteCodes) {
			return response.siteCodes.map((result: string) => ({
				label: result,
				data: result,
				expanded: false,
			}));
		}
		return response.results.map((result: any) => ({
			label: result['value'],
			data: result['value'],
			children: level < 3 ? [{}] : [],
			expanded: false,
			leaf: true
		}));
	}

	onNodeSelect(node: TreeNode) {
		if(node.data?.loadMore)
			{
				if (this.selectedNodes?.label=="Load More")
					this.selectedNodes=this.prevNode;
				this.loadMoreData(node) 
				return;
			}
		this.prevNode={...this.selectedNodes}
		this.showDropDown();
		this.nodeSelectEmitter.emit(node);
	}

	updateTreeState() {
		this.data = [...this.data];
		this.forceTreeUpdate();
	}

	private forceTreeUpdate() {
		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
				this.ngZone.run(() => {
					this.changeDetectorRef.detectChanges();
				});
			}, 0);
		});
	}
}
