<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <p-dialog header="Header" [(visible)]="editModal" [modal]="true" [breakpoints]="{ '960px': '75vw' }"
    [styleClass]=" 'w-12 h-40rem' " (onHide)="closeEditDialog()" [closable]="true">
    <ng-template pTemplate="header">
      <span class="text-xl font-bold">{{"header.modifySiteAttributes" | translate}}</span>
    </ng-template>
    <ng-container>
      <div class="card">
        <div class="flex justify-content-end">
          <span class="text-red-500">*</span>
          <span>{{"label.indicationRequiredField" | translate}}</span>
        </div>

        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <div class="flex align-items-center">
              <span class="pi mr-2"></span>
              <span class="font-bold text-lg">{{"header.basicInfo" | translate}}</span>
            </div>
          </ng-template>

          <div class="card">

            <div class="flex flex-wrap  mb-4">
              <div class="col-3">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.branch" | translate}} </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="branch" class="w-full"
                  [readonly]="true" />
              </div>
              <div class="col-3">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.siteCodeType" | translate}} </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fordSiteCodeType" class="w-full"
                  [readonly]="true" />
              </div>
              <div class="col-3">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.country" | translate}} </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="market" class="w-full"
                  [readonly]="true" />
              </div>
              <div class="col-3">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.site" | translate}} </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fordSiteCode" class="w-full"
                  [readonly]="true" />
              </div>
            </div>
          </div>
        </p-fieldset>

        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <div class="flex align-items-center">
              <span class="pi mr-2"></span>
              <span class="font-bold text-lg">{{"header.contactInfo" | translate}}</span>
            </div>
          </ng-template>
          <div class="card">
            <div class="flex flex-wrap mb-4">
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">{{"label.siteName" | translate}} 
                  </label></div>
                <input type="text" pInputText id="alphabetic" pKeyFilter="alpha" formControlName="dealershipName"
                  class="w-full" />
              </div>
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.streetAddress" | translate}} </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="postalAddress" class="w-full" />
              </div>
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">{{"label.city" | translate}}
                  </label></div>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="city" class="w-full" />
              </div>
            </div>
            <div class="flex flex-wrap mb-4">
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">{{"label.stateOrProvince" | translate}} 
                    
                  </label></div>
                <input type="text" pInputText id="alphabetic" pKeyFilter="alpha" formControlName="state"
                  class="w-full" />
              </div>
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.postalOrZipCode" | translate}} </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="postalCode" class="w-full" />
              </div>
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">  {{"label.telephoneNumber" | translate}}
                    
                  </label></div>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="telephoneNumber" class="w-full" />
              </div>
            </div>
            <div class="flex flex-wrap mb-4">

              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2"> {{"label.fax" | translate}} </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="facsimileTelephoneNumber"
                  class="w-full" />
              </div>
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">{{"label.language" | translate}}
                  </label><span class="highlight"> {{"message.languageSuggestion" | translate}}</span></div>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="preferredLanguage"
                  class="w-full" />
              </div>
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">{{"label.salesCode" | translate}}
                  </label><span class="highlight">{{"message.salesCodeSuggestion" | translate}}</span></div>
                <input type="text" pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fordSalesCode"
                  class="w-full" />
              </div>
            </div>
            <div class="flex flex-wrap mb-4">
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.url" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="labeledURI" class="w-full" />
              </div>
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">{{"label.parentOrganization" | translate}}
                    
                  </label><span class="highlight">{{"message.parentOrganizationSuggestion" | translate}}</span></div>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fordParentOrganization"
                  class="w-full" />
              </div>
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">
                  {{"label.description" | translate}}
                  </label><span class="highlight">{{"message.descriptionSuggestion" | translate}}</span></div>
                <input type="text" pInputText id="alphabetic" pKeyFilter="alpha" formControlName="description"
                  class="w-full" />
              </div>
            </div>
            <div class="flex flex-wrap mb-4">
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fordCSDCode" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fordCsdCode" class="w-full" />
              </div>
              <div class="col-4">
                <div class="flex justify-content-start"><label for="alphabetic" class="font-bold block mb-2">
                  {{"label.lastModifiedBy" | translate}}
                  </label></div>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fordLastModifiedBy"
                  class="w-full" />
              </div>
              <div id="createUsersSelect" class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fordStatus" | translate}}  </label>
                <p-selectButton [options]="statusOptions" formControlName="fordstatus" optionLabel="label"
                  optionValue="value"></p-selectButton>
              </div>
            </div>
          </div>
        </p-fieldset>


        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <div class="flex align-items-center">
              <span class="pi mr-2"></span>
              <span class="font-bold text-lg">{{"header.fMCCAttributes" | translate}}</span>
            </div>
          </ng-template>

          <div class="card">
            <div class="flex flex-wrap  mb-4">
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCLessor" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccLessor" class="w-full" />
              </div>
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCBrand" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccBrand" class="w-full" />
              </div>
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCBCode" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccBCode" class="w-full" />
              </div>
            </div>

            <div class="flex flex-wrap  mb-4">
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCFinLab" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccFinLab" class="w-full" />
              </div>
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCRegion" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccRegion" class="w-full" />
              </div>
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCSBCode" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccSbCode" class="w-full" />
              </div>
            </div>
            <div class="flex flex-wrap  mb-4">
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCMkt" | translate}} </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccMkt" class="w-full" />
              </div>
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCPGroup" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccpGroup" class="w-full" />
              </div>
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCGroup" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccGroup" class="w-full" />
              </div>
            </div>
            <div class="flex flex-wrap ">
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCSt" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccSt" class="w-full" />
              </div>
              <div class="col-4">
                <label for="alphabetic" class="font-bold block mb-2">{{"label.fMCCClass" | translate}}  </label>
                <input pInputText id="alphabetic" pKeyFilter="alpha" formControlName="fmccClass" class="w-full" />
              </div>
            </div>
          </div>
        </p-fieldset>
      </div>
    </ng-container>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" type="submit" label="{{'button.saveChanges' | translate}}" [disabled]="!editForm.valid"
        styleClass="p-button-text"></p-button>
      <p-button icon="pi pi-times" (click)="closeEditDialog()" label="{{'button.cancel' | translate}}" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>
</form>

<p-dialog header="Header" [(visible)]="saveChangesResponseModal" [modal]="true" [style]="{ width: '50vw' }"
  (onHide)="closeSaveChangeResponseDialog()">
  <ng-template pTemplate="header">
    <span class="text-xl font-bold">{{"label.response" | translate}}</span>
  </ng-template>
  <div class="flex align-items-center justify-content-center" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <p>
    {{saveChangesAPIResponse}}
  </p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-danger" (click)="saveChangesResponseModal = false" label="{{'button.close' | translate}}"
      styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>