import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
	selector: '[appEnterKeyFilter]'
})
export class EnterKeyFilterDirective {
	@Output() enterKey = new EventEmitter<void>();

	constructor(private el: ElementRef) {}

	@HostListener('keydown.enter', ['$event'])
	onEnter(event: KeyboardEvent) {
		console.log('event enter emitter')
		if (this.el.nativeElement.contains(event.target)) {
			this.enterKey.emit();
			event.preventDefault();
		}
	}
}
