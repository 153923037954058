<div class="divPurgeBtn" *ngIf="selectedRows.length > 0">
  <p-button severity="danger" label="{{'button.purgeAll' | translate}}" (onClick)="delete()"></p-button>

</div>

<br>
<p-table #dt *ngIf="siteDataList.length > 0" [value]="siteDataList" [scrollable]="true" scrollHeight="63vh"
  styleClass="p-datatable-striped" [(selection)]="selectedRows" dataKey="fordSiteCode" selectionMode="multiple"
  [metaKeySelection]="true" [paginator]="siteDataList.length > 5" [rows]="EnvConstants.PAGINATION_LENGTH" [rowsPerPageOptions]="[5, 10, 20]" >

  <ng-template pTemplate="header">
    <tr>
      <th style="text-align: center;">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th class="tableheaderwidth">{{"label.site" | translate}}</th>
      <th class="tableheaderwidth">{{"label.branch" | translate}}</th>
      <th class="tableheaderwidth">{{"label.description" | translate}}</th>
      <th class="tableheaderwidth">{{"label.lastUpdatedDate" | translate}}</th>
      <th class="tableheaderwidth"> {{"label.lastUpdatedBy" | translate}}</th>
      <th class="tableheaderwidth"> {{"label.structure" | translate}}</th>
      <th class="tableheaderwidth">{{"action.edit" | translate}}</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-siteData>
    <tr>
      <td>
        <div style="text-align: center;"> <p-tableCheckbox [value]="siteData"></p-tableCheckbox></div>
      </td>
      <td>
        {{ siteData.fordSiteCode }}
      </td>
      <td>
        {{ siteData.branch }}
      </td>
      <td>
        {{ siteData.dealershipName }}
      </td>
      <td>
        {{ siteData.lastUpdatedDate}}
      </td>
      <td>
        {{ siteData.fordLastModifiedBy }}
      </td>
      <td class="text-center">
        <p-badge [value]="siteData.structure" [severity]="siteData.structure === 'Verified' ? 'success': 'danger'" />
      </td>
      <td>
        <i class="pi pi-pencil cursor-pointer flex justify-content-center" *ngIf="siteData.structure==='Verified'" (click)="onClickEdit(siteData)"></i>
      </td>
    </tr>
  </ng-template>
</p-table>


<div *ngIf="editModal"><modify-site-attributes [editableDataNotifier]="editableDataNotifier"></modify-site-attributes></div>
