<div *ngIf="users;else nouser;" [adminCheck]="PageType.SEARCH_USER">
	<p-table #dt [value]="[users]" [scrollable]="true" scrollHeight="63vh" dataKey="userId"
			 styleClass="p-datatable-striped">

		<ng-template pTemplate="header">
			<tr>
				<th class="tableheaderwidth">{{"label.userID" | translate}}</th>
				<th class="tableheaderwidth">{{"label.lastName" | translate}}</th>
				<th class="tableheaderwidth">{{"label.firstName" | translate}}</th>
				<th class="tableheaderwidth">{{"label.primaryLocation" | translate}}</th>
				<!-- <th class="tableheaderwidth">Edit User</th> -->
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-user>
			<tr>
				<td>
					<div>
						<ng-container *ngIf="!loading;else showspinner">
							<p-button [label]="user.userId" [link]="true" (click)="onClickEdit(user)"/>
						</ng-container>
						<ng-template #showspinner>
							<i class="pi pi-spin pi-spinner text-xl" ></i>
						</ng-template>
					</div>

				</td>
				<td>
					{{ user.sn }}
				</td>
				<td>
					{{ user.givenName }}
				</td>
				<td>
					{{ user.fordSiteCode }}
				</td>
			</tr>
		</ng-template>
	</p-table>
</div>
<ng-template #nouser>
	<h4 class="text-center">
		{{"message.noUserMessage" | translate}}
	</h4>
</ng-template>

