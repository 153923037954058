<div class="p-2">
	<p class="text-xl flex gap-3 flex-column justify-content-center align-items-center">
		<span [class.icon-border]="iconBorder" *ngIf="!inlineIcon"><i [class]="iconMsg"></i></span>
		<span class="flex align-items-center gap-2"><i [class]="iconMsg" *ngIf="inlineIcon"></i>{{message}}</span>
	</p>
	<div class="grid gap-2 justify-content-center mt-4" *ngIf="stringIds?.length != 0">
		<ng-container *ngFor="let stringid of stringIds">
			<p-chip [label]="stringid"></p-chip>
		</ng-container>
	</div>
	<div class="flex gap-4 mt-5 mb-3 justify-content-center">
		<button type="button" pButton icon="pi pi-check" label="Yes" (click)="onDialogClose(true)"></button>
		<button type="button" pButton icon="pi pi-times" class="p-button-outlined" label="No" (click)="onDialogClose()"></button>
	</div>
</div>
