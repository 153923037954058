import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResetRequest, Users } from '../../search-users/users-interfaces';
import { ClientConstants } from 'src/app/common/client.constants';
import { HttpMethods } from 'src/app/common/http-methods.constants';
@Injectable({
  providedIn: 'root'
})
export class SearchUserService {
  constructor(private _httpClient: HttpClient) { }

  private updateStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  updateProfileStatus(data: boolean) {
    this.updateStatus.next(data);
  }

  getProfileUpdateStatus() {
    return this.updateStatus.asObservable();
  }

  searchUsers(searchValue: string) {
    let requestBody: any = {
      'endpoint': environment.baseUrlPcf + 'usermanagement/api/v2/user/getUserDetails?userId=' + searchValue,
      'configName': ClientConstants.CLIENT_2
    }

    return this._httpClient.post(environment.baseUrlGateway + '/gateway/for-map', requestBody);
  }

  updateUser(user: any) {
    let requestBody: any = {
      'endpoint': environment.baseUrlPcf + 'usermanagement/api/v2/user/updateUser',
      'configName': ClientConstants.CLIENT_2,
      'jsonPayload': user,
      "httpMethod": HttpMethods.POST
    }
    return this._httpClient.post(environment.baseUrlGateway + '/gateway/for-map', requestBody);
  }

  updateUserStatus(user: any) {
    let requestBody: any = {
      'endpoint': environment.baseUrlPcf + 'usermanagement/api/v2/user/activateInactivateUser',
      'configName': ClientConstants.CLIENT_2,
      'jsonPayload': user,
      "httpMethod": HttpMethods.POST
    }
    return this._httpClient.post(environment.baseUrlGateway + '/gateway/for-map', requestBody);
  }

  resetPassword(resetRequest:ResetRequest) {
    let requestBody: any = {
      'endpoint': environment.baseUrlPcf + 'usermanagement/api/v2/user/passReset',
      'configName': ClientConstants.CLIENT_2,
      'jsonPayload': resetRequest,
      "httpMethod": HttpMethods.POST
    }
    return this._httpClient.post(environment.baseUrlGateway + '/gateway/for-map', requestBody);
  }
  getGroupsForUser(userId: string) {
    let requestBody: any = {
      'endpoint': environment.baseUrlPcf + 'usermanagement/api/v2/user/groupService/getGroupsForUser?userId=' + userId,
      'configName': ClientConstants.CLIENT_2
    }
    return this._httpClient.post(environment.baseUrlGateway + '/gateway/for-map', requestBody);
  }
  getJobRoles(requestBody: any): Observable<any> {
    let request = {
      "endpoint": environment.baseUrlPcf + 'sitestructure/api/v1/job-role/manage-job-role?branch=' + requestBody.branch.toUpperCase() + '&ford-site-code-type=' + requestBody.siteCodeType + '&country=' + requestBody.marketName,
      "configName": ClientConstants.CLIENT_6
    }
    return this._httpClient.post(environment.baseUrlGateway + '/gateway/for-map', request);
    // return this._httpClient.get<any>(this.baseUrl + `/job-role/manage-job-role?ford-site-code-type=${requestBody.siteCodeType}&country=${requestBody.marketName}`);
  }
  getEmailTypes(regionMarket: string): Observable<any> {
    let requestBody = {
      "endpoint": environment.baseUrlPcf + 'DealerAudit/v2/audit-config/email-type-value?region-market=' + regionMarket,
      "configName": ClientConstants.CLIENT_3
    }
    return this._httpClient.post(environment.baseUrlGateway + '/gateway/for-map', requestBody);
  }

  getUserAuditStatus(userId: string): Observable<any> {
    let requestBody = {
      "endpoint": environment.baseUrlPcf + `DealerAudit/v2/audit-config/user-audit-details?user-id=${userId}`,
      "configName": ClientConstants.CLIENT_3
    }
    return this._httpClient.post(environment.baseUrlGateway + '/gateway/for-map', requestBody);
  }

}
