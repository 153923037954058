export const ViewMyInfoConstants = {
	USER_ID: 'userId',
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
	EMAIL: 'email',
	PHONE: 'phone',
	FAX: 'fax',
	PRIMARY_LOCATION: 'primaryLocation',
	CITY: 'city',
	STATE: 'state',
	COUNTRY: 'country',
	POSTAL_CODE: 'postalCode',
	JOB_ROLE: 'jobRole',
	ADDRESS: 'address',
	STATUS: 'status',
	PREFERRED_LANGUAGE: 'preferredLanguage',
	SITE_CODE_TYPE: 'siteCodeType',
	LAST_PASSWORD_RESET: 'lastPasswordReset',
	PASSWORD_EXPIRATION_DATE: 'passwordExpirationDate',
	EMAIL_TYPE: 'emailType',
	LAST_AUDITED_USER: 'lastAuditedUser',
	LAST_AUDITED_DATE: 'lastAuditedDate',
	DEALER: 'dealer',
	CODES: 'codes',
	CODE_DESC: 'code_desc',
	PRIMARY_CODE: 'primaryCode',
	PRIMARY_DESCRIPTION: 'primaryDescription',
	HYPHEN: '-',
	EMPTY_STRING: '',
	STATUS_ACTIVE: 'ACTIVE',
	STATUS_INACTIVE: 'INACTIVE',
	STATUS_ON: 'on'
};
