<div>
	<div>
		<app-title
			[userTitle]="'Help in other languages' | translate"></app-title>
	</div>
	<div *ngIf="otherLanguageManual" class="ml-5 help-link">
		<p-table
			#dt
			[value]="otherLanguageManual"
			[scrollable]="true"
			scrollHeight="63vh"
			styleClass="p-datatable-striped">
			<ng-template pTemplate="header">
				<tr>
					<th
						*ngFor="let headerName of otherLanguageColumns"
						class="tableheaderwidth">
						{{ headerName }}
					</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body" let-manual let-i="rowIndex">
				<tr>
					<td>{{ manual.language.label }}</td>
					<td>
						<p-button
							[link]="true"
							styleClass="p-button-no-focus"
							[loading]="manual?.adminManual?.isLoading"
							[label]="manual.adminManual.label"
							(onClick)="downloadManual(manual?.adminManual)" />
					</td>
					<td>
						<p-button
							[link]="true"
							styleClass="p-button-no-focus"
							[loading]="manual?.userManual?.isLoading"
							[label]="manual.userManual.label"
							(onClick)="downloadManual(manual?.userManual)" />
					</td>
					<td>
						<p-button
							[link]="true"
							styleClass="p-button-no-focus"
							[loading]="manual?.dsaTutorial?.isLoading"
							[label]="manual.dsaTutorial.label"
							(onClick)="downloadManual(manual?.dsaTutorial)" />
					</td>
				</tr>
			</ng-template>
		</p-table>
		<div
			*ngIf="isLoading"
			class="card flex justify-content-center spinner-mask">
			<p-progressSpinner
				styleClass="w-4rem h-4rem"
				strokeWidth="8"
				fill="var(--surface-ground)"
				animationDuration=".5s"></p-progressSpinner>
		</div>
	</div>
</div>
