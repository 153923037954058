<div>
	<div class='drop-down-tree-field' id="tree-input">
		<input #filter autofocus pInputText type="text" (click)='openDropDownTree($event)'
			   [class.cursor-pointer]="showDropDownBtn" [disabled]="!showDropDownBtn" class='col-12 w-19rem'
			   value={{selectedNodes?.label}}
			   [placeholder]="showDropDownBtn ? ('placeholder.selectGroup' | translate):'Loading groups..'" readonly>
		<span class='pi drop-down-icon cursor-pointer' [ngClass]="visible ? 'pi-times': 'pi-angle-down'"
			  *ngIf="showDropDownBtn" (click)='openDropDownTree($event)'></span>
		<span class='pi pi-spin pi-spinner drop-down-icon' *ngIf="!showDropDownBtn"></span>
	</div>
	<div class="dropdown-section w-12 z-5 p-1" [ngClass]='{"hide": visible == false}' [attr.id]='id'>
		<div class="flex mb-1 border-round-sm custom-input p-1 mx-2 my-1 p-inputtext-dummy" [class.ng-invalid]="filterErrorMsg">
					<span class="flex flex-1">
						<input type="text" id="sitefilter" class="w-full border-none" placeholder="{{'placeholder.enterSiteCode' | translate }}" name="siteFilter" pInputText #siteFilter
							   (keyup.enter)="searchSiteGroup(siteFilter)" [disabled]="loading" required minlength="1"/>
						<p-button class="fadein animation-duration-500 search-filter-btn" [disabled]="loading" icon="pi pi-search" (click)="searchSiteGroup(siteFilter)"></p-button>
						<small *ngIf="filterErrorMsg" class="text-color-1 absolute fadein animation-duration-500 text-xs" style="top:55px">{{"message.validSiteCode" | translate}}</small>
					</span>
			<p-button severity="info" styleClass="exclude-custom w-2rem h-2rem" class="fadein animation-duration-500 clear-filter-btn" icon="pi pi-minus" size="small"  (click)="clearFilter(siteFilter)" pTooltip="{{'tooltip.clearFilter' | translate}}" [disabled]="loading" [rounded]="true"></p-button>
		</div>
		<p-tree [value]="data" scrollHeight="500px" selectionMode="single" [(selection)]="selectedNodes"
				[loading]="loading" (onNodeExpand)="onNodeExpand($event)" emptyMessage="{{'message.siteCodeNotAvailable' | translate}}"
				(onNodeCollapse)="onNodeCollapse($event)" (onNodeSelect)="onNodeSelect($event.node)"
				[lazy]="true" [styleClass]="'custom-ui-tree p-0 border-none'" togglerAriaLabel="one">
			<ng-template let-node pTemplate="default">
				<span class="loadmore" *ngIf="node?.data?.loadMore;else showLabel">
					<....>
				</span>
				<ng-template #showLabel>
					{{ node?.label }}
				</ng-template>

			</ng-template>
		</p-tree>
	</div>
</div>
