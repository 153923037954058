<div [adminCheck]="PageType.SEARCH_USER">
  <app-title></app-title>

  <ng-container *ngIf="usersForm">
    <form [formGroup]="usersForm" (submit)="onClickSearch()">
      <div class="card border-round-xl flex p-5 mx-2 px-6 gap-8 bg-color-2  shadow-3 input-container">
        <div>
          <input autofocus class="search-input" pInputText name="searchValue" formControlName="searchValue" placeholder="Enter {{'label.userID' | translate}}" required [minlength]="1"/>
        </div>
        <p-button label="{{'button.search' | translate}}" [type]="'submit'"
          [disabled]="usersForm.invalid || loading"></p-button>
      </div>
    </form>
  </ng-container>
	<ng-container *ngIf="!firstLoad">
		<div class="flex align-items-center justify-content-center mt-8" *ngIf="loading">
			<p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="5" animationDuration=".7s"></p-progressSpinner>

		</div>
		<div id="tabmenu" class="card overflow-y-auto tableStyle p-2 search-table" *ngIf="!loading ">
			<search-users-table [users]="userDetails" [disableInputs]="disableInputs"></search-users-table>
		</div>
	</ng-container>

<!--  <p-dialog header="Header" [(visible)]="showNoRecords" [modal]="true" [style]="{ width: '50vw' }">-->
<!--    <ng-template pTemplate="header">-->
<!--        <span class="text-xl font-bold">Response</span>-->
<!--    </ng-template>-->
<!--    <p>-->
<!--      No user records found for the given criteria!-->
<!--    </p>-->
<!--    <ng-template pTemplate="footer">-->
<!--        <p-button icon="pi pi-danger" (click)="showNoRecords = false" label="Close" styleClass="p-button-text"></p-button>-->
<!--    </ng-template>-->
<!--  </p-dialog>-->
</div>
