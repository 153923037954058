import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '@wame/ngx-frf-utilities';
import { ManageUserGroupsComponent } from './user-groups/manage-user-groups/manage-user-groups.component';
import { SearchUsersComponent } from './administration/search-users/search-users/search-users.component';
import { ManageJobRolesComponent } from './administration/manage-job-role/manage-job-roles/manage-job-roles.component';
import {
	ManageAuditConfigurationComponent
} from "./administration/manage-audit-configuration/manage-audit-configuration.component";
import { ManageTempIdsComponent } from './administration/manage-temporary-id/manage-temp-ids/manage-temp-ids.component';
import { CreateTemporaryGroupComponent } from './administration/create-temporary-group/create-temporary-group.component';
import { SiteRecoveryComponent } from './administration/site-recovery/site-recovery.component';
import {OauthCallbackComponent,OauthTokenGuard,AuthenticationGuard} from "./core/ngx-adfs";
import {ViewMyInfoComponent} from "./user-info/view-my-info/view-my-info.component";
import {LayoutComponent} from "./common/layout/layout.component";
import {PageType} from "./common/models/user-role/page-type.enum";
import {AppRoutes} from "./app-router.contants";
import {ChangeMyProfileComponent} from "./user-info/change-my-profile/change-my-profile.component";
import {UnAuthComponent} from "./common/un-auth-component/un-auth.component";
import {SpsUserManualComponent} from "./administration/help-manual/sps-user-manual/sps-user-manual.component";
import {
	HelpOtherLanguagesComponent
} from "./administration/help-manual/help-other-languages/help-other-languages.component";

const routes:Routes =[{
	path: '',
	component: LayoutComponent,
	children: [
		{
			path: AppRoutes.MANAGE_USER_GROUPS,
			component: ManageUserGroupsComponent,
			title: 'Manage User Groups',
			canActivate: [AuthenticationGuard],
			data: { pageType: PageType.TREE_STRUCTURE,title: 'title.manageUserGroups' }
		},
		{
			path: '',
			redirectTo: AppRoutes.MANAGE_USER_GROUPS,
			pathMatch: 'full'
		},
		{
			path: AppRoutes.VIEW_MY_INFO,
			component: ViewMyInfoComponent,
			title: 'View My Info',
			canActivate: [AuthenticationGuard],
			data: { pageType: PageType.VIEW_MY_INFO,title: 'title.viewMyInfo' }
		},
		{
			path: AppRoutes.CHANGE_MY_PROFILE,
			component: ChangeMyProfileComponent,
			title: 'Change My Profile',
			canActivate: [AuthenticationGuard],
			data: { pageType: PageType.CHANGE_MY_PROFILE,title: 'title.changeMyProfile' }
		},
		{
			path: AppRoutes.MANAGE_JOB_ROLES,
			component: ManageJobRolesComponent,
			pathMatch: 'full',
			title: 'Manage Job Roles',
			canActivate: [AuthenticationGuard],
			data: { pageType: PageType.MANAGE_JOB_ROLES,title: 'title.manageJobRoles' }
		},
		{
			path: AppRoutes.MANAGE_AUDIT_CONFIGURATION,
			component: ManageAuditConfigurationComponent,
			title: 'Manage Audit Configuration',
			canActivate: [AuthenticationGuard],
			data: { pageType: PageType.MANAGE_AUDIT_CONFIG ,title: 'title.manageAuditConfiguration'}
		},
		{
			path: AppRoutes.MANAGE_TEMPORARY_IDS,
			component: ManageTempIdsComponent,
			pathMatch: 'full',
			title: 'Manage Temporary Id',
			canActivate: [AuthenticationGuard],
			data: { pageType: PageType.MANAGE_TEMP_IDS ,title: 'title.manageTemporaryIds'}
		},
		{
			path: AppRoutes.SEARCH_USERS,
			component: SearchUsersComponent,
			pathMatch: 'full',
			title: 'Search Users',
			canActivate: [AuthenticationGuard],
			data: { pageType: PageType.SEARCH_USER,title: 'title.searchUsers' }
		},
		{
			path: AppRoutes.CREATE_TEMPORARY_GROUP,
			component: CreateTemporaryGroupComponent,
			pathMatch: 'full',
			title: 'Create Temporary Group',
			canActivate: [AuthenticationGuard],
			data: { pageType: PageType.MANAGE_TEMP_IDS,title: 'title.createTemporaryGroup'}
		},
		{
			path: AppRoutes.SITE_RECOVERY_TOOL,
			component: SiteRecoveryComponent,
			pathMatch: 'full',
			title: 'Site Recovery Tool',
			canActivate: [AuthenticationGuard],
			data: { pageType: PageType.SITE_RECOVERY_TOOL,title: 'title.siteRecoveryTool' }
		},
		{
			path: AppRoutes.REPORT,
			loadChildren: () =>
				import('./report/report.module').then((m) => m.ReportModule),
		},
		{
			path: AppRoutes.SPS_USER_MANUALS,
			component: SpsUserManualComponent,
			data: {pageType: PageType.HELP_ACCESS, title: 'title.spsUserManual'},
			canActivate: [AuthenticationGuard],
		},
		{
			path: AppRoutes.HELP_IN_OTHER_LANG,
			component: HelpOtherLanguagesComponent,
			data: {pageType: PageType.HELP_ACCESS, title: 'title.helpInOtherLanguages'},
			canActivate: [AuthenticationGuard],
		},
	]
	},
	{
		path: AppRoutes.RETIREE_PASSWORD,
		loadChildren: () =>
			import('./retiree/retiree.module').then(m => m.RetireeModule),
		title: 'Reset Password',
	},
	{
		path: AppRoutes.OAUTH,
		component: OauthCallbackComponent,
		canActivate: [OauthTokenGuard]
	},
	{
		path: AppRoutes.NOT_FOUND,
		component: NotFoundComponent,
		pathMatch: 'full',
		title: 'title.pageNotFound'
	},
	{
		path: AppRoutes.UNAUTHORIZED,
		component: UnAuthComponent,
		title: 'Access denied'
	},
	{
		path: AppRoutes.WILDCARD,
		redirectTo: AppRoutes.NOT_FOUND
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
