import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientConstants } from 'src/app/common/client.constants';
import { HttpMethods } from 'src/app/common/http-methods.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportCenterService {

  baseUrlGateway: string = environment.baseUrlGateway;
  constructor(private _httpClient: HttpClient) { 
  }

  getSupportCenterDetails(siteCodeType: string,marketCode:string): Observable<any> {

    
    let requestBody: any = {
     
      "endpoint": environment.baseUrlSPSUtil + 'supportCenter/support-center-details?siteCodeType=' + siteCodeType +'&marketCode=' +marketCode,
      "configName": ClientConstants.CLIENT_5
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);

    //return this.httpClient1.get(environment.baseUrlSPS + 'supportCenter/support-center-details?siteCodeType=' + siteCodeType +'&marketCode=' +marketCode);
  }

  updateSupportCenter(data: any): Observable<any> {
    
    let requestBody: any = {
      "endpoint": environment.baseUrlSPSUtil + 'supportCenter/support-center-details',
      "configName": ClientConstants.CLIENT_5,
      "jsonPayload":data,
      "httpMethod": HttpMethods.POST
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);

    //return this.httpClient1.post(environment.baseUrlSPS + 'supportCenter/support-center-details',data);
  }

}
