import { Component } from '@angular/core';
import { SPS_USER_MANUAL_DETAILS } from '../help-manual-constants';
import {AdministrationService} from "../../services/administration.service";
import {map} from "rxjs";

@Component({
  selector: 'sps-user-manual',
  templateUrl: './sps-user-manual.component.html',
  styleUrls: ['./sps-user-manual.component.scss']
})
export class SpsUserManualComponent {
  spsSections = SPS_USER_MANUAL_DETAILS;
  isLoading = false;

	constructor(private adminService: AdministrationService) {
	}


  openLink(item: any) {
		item.isLoading = true;
		const fileName = item.link;
    this.adminService.downloadManual(fileName).subscribe({
		next : (response:any) => {
			// console.log('response',response,response?.message);
			// const byteArray = new Uint8Array(response);
			// console.log('response byte',byteArray);
			// const blob = new Blob([byteArray], { type: 'application/pdf' });
			// console.log("BLOBL",blob);
			const blobUrl = window.URL.createObjectURL(response);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(blobUrl);
			item.isLoading = false;
		},
		error: (error) => {
		  console.error('Error downloading file:', error);
		  item.isLoading = false;
	  }
	});
  }
}
