import {Component, inject, OnInit} from '@angular/core';
import {AuthService} from "../../core/adfs/services/auth.service";
import {MessageService} from "primeng/api";

@Component({
  selector: 'un-auth-component',
  templateUrl: './un-auth.component.html',
  styleUrls: ['./un-auth.component.scss']
})
export class UnAuthComponent implements OnInit{

	authService = inject(AuthService);
	messageService = inject(MessageService);

	login(){
		this.authService.login()
	}

	ngOnInit(): void {
		this.messageService.add({severity:'error', summary:'Error', detail:'You are not authorized to view this page. Please login to view this page.'});
	}
}
