import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {PageType} from "../models/user-role/page-type.enum";
import {UserRoleService} from "../services/user-role/user-role.service";
import {BaseUserGroupModel} from "../../user-groups/base-usergroup.model";
import {HeaderBodyModel} from "../models/header-body.model";

@Injectable({
	providedIn: 'root',
})
export class HeaderUtilityService {

	constructor(private userRoleService:UserRoleService) {}

	buildPageTitleHeader(pageTitle:PageType,userBaseModel:BaseUserGroupModel=null):HeaderBodyModel {
		return {
			pageTitle:this.userRoleService.getPageTileIfBaseModel(pageTitle,userBaseModel),
			group: userBaseModel ? this.buildBaseModel(userBaseModel) : null
		}
	}

	private buildBaseModel(baseUserModel: BaseUserGroupModel): string {
		const parts = [
			`BR=${baseUserModel.branch}`,
			baseUserModel.siteCodeType ? `SCT=${baseUserModel.siteCodeType}` : '',
			baseUserModel.market ? `MR=${baseUserModel.market}` : '',
			baseUserModel.siteCode ? `SC=${baseUserModel.siteCode}` : ''
		];
		return parts.filter(part => part !== '').join(',');
	}
}
