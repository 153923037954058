import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedCommonModule} from "../common/shared.module";
import {ManageJobRolesComponent} from "./manage-job-role/manage-job-roles/manage-job-roles.component";
import {JobRolesTableComponent} from "./manage-job-role/job-roles-table/job-roles-table.component";
import {AddJobRoleComponent} from "./manage-job-role/add-job-role/add-job-role.component";
import {EditJobRoleComponent} from "./manage-job-role/edit-job-role/edit-job-role.component";
import {ManageAuditConfigurationComponent} from "./manage-audit-configuration/manage-audit-configuration.component";
import {ManageTempIdsComponent} from "./manage-temporary-id/manage-temp-ids/manage-temp-ids.component";
import {TempMembersTableComponent} from "./manage-temporary-id/temp-members-table/temp-members-table.component";
import {CreateTempIdComponent} from "./manage-temporary-id/create-temp-id/create-temp-id.component";
import {CreateTemporaryGroupComponent} from "./create-temporary-group/create-temporary-group.component";
import {EditTempIdComponent} from "./manage-temporary-id/edit-temp-id/edit-temp-id.component";
import {SearchUsersComponent} from "./search-users/search-users/search-users.component";
import {EditUserComponent} from "./search-users/edit-user/edit-user.component";
import {SearchUsersTableComponent} from "./search-users/search-users-table/search-users-table.component";
import {SiteRecoveryComponent} from "./site-recovery/site-recovery.component";
import {SiteRecoveryTableComponent} from "./site-recovery/site-recovery-table/site-recovery-table.component";
import {ModifySiteAttributesComponent} from "./site-recovery/modify-site-attributes/modify-site-attributes.component";
import { AdminTempGroupsComponent } from './manage-temporary-id/admin-temp-groups/admin-temp-groups.component';
import { UserGroupsModule } from '../user-groups/user-groups.module';

@NgModule({
	imports: [
		FormsModule,
		ReactiveFormsModule,
		SharedCommonModule,
		UserGroupsModule
	],
	declarations:[
		ManageJobRolesComponent,
		JobRolesTableComponent,
		AddJobRoleComponent,
		EditJobRoleComponent,
		ManageAuditConfigurationComponent,
		ManageTempIdsComponent,
		TempMembersTableComponent,
		CreateTempIdComponent,
		CreateTemporaryGroupComponent,
		EditTempIdComponent,
		SearchUsersComponent,
		EditUserComponent,
		SearchUsersTableComponent,
		SiteRecoveryComponent,
		SiteRecoveryTableComponent,
		ModifySiteAttributesComponent,
  AdminTempGroupsComponent
	]
})
export class AdministrationModule { }
