import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AdfsConfig} from '../api/adfs-config';
import {AUTHSERVICE_WITH_CONFIG} from './auth.service.config';
import {UserIdService} from './user-id.service';
import { jwtDecode } from "jwt-decode";
import {UserAuthModel} from "../api/user-auth.model";
import {UserRoleService} from "../../../common/services/user-role/user-role.service";
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from "@angular/router";
import {AppRoutes} from "../../../app-router.contants";
import { Language } from 'src/app/user-groups/manage-user-groups/manage-user-groups.model';
import { LanguageTranslationService } from 'src/app/common/service/language.translation.service';
import {BehaviorSubject} from "rxjs";
import {SpinnerState} from "../../../common/models/spinner-state.model";
import {SpinnerService} from "../../../common/services/spinner.service";
/** This service handles Oauth2 implicit grant flow authentication using the configurations provided by the application and also interacts
 *  with sessionStorage stored parameters related to authentication.
 */
@Injectable({
	providedIn: 'root'
})
export class AuthService {
	/** the configured URL endpoint to send the user's browser to for token negotiation */
	private readonly loginEndpoint: string;

	/** the endpoint to send the user's browser to for logout, including redirect */
	private readonly logoutEndpoint: string;

	/** reference to the window object */
	private window: Window;

	/** Creates a new instance of the LoginComponent, makes available
	 * a DOCUMENT reference and instantiates required variables
	 *
	 * @param document angular provided reference to the current document
	 * @param config config object
	 * @param idService user id service for tracking currently logged-in user
	 */

	private ERROR_MSG = "Something went wrong. Please try again later.";

	constructor(
		@Inject(AUTHSERVICE_WITH_CONFIG) private config: AdfsConfig,
		@Inject(DOCUMENT) private document: Document,
		private idService: UserIdService,
		private userRoleService:UserRoleService,
		private router:Router,
		private languageTranslationService:LanguageTranslationService,
		private spinnerService:SpinnerService
	) {
		this.window = this.document.defaultView as Window;
		this.loginEndpoint =
			config.openidUrl +
			'/authorize' +
			'?client_id=' +
			config.openidClientId +
			'&resource=' +
			config.openidResource +
			'&response_type=' +
			encodeURIComponent('id_token token') +
			'&redirect_uri=' +
			encodeURIComponent(
				this.makeRedirectUrl(
					config.openidLoginRedirectRoute
						? config.openidLoginRedirectRoute
						: 'oauth'
				)
			);
		this.logoutEndpoint =
			config.openidUrl +
			'/logout' +
			(config.openidLogoutRedirectRoute !== undefined
				? '?post_logout_redirect_uri=' +
					encodeURIComponent(
						this.makeRedirectUrl(config.openidLogoutRedirectRoute)
					) +
					'&id_token_hint=' +
					sessionStorage.getItem('idToken')
				: '');
	}

	/** once the component is up and running, redirect the user to the auth provider */
	public login(): void {
		this.window.location.replace(this.loginEndpoint);
	}

	/** clears browser session storage, unsets user ID and redirects to ADFS logout
	 */
	public logout(): void {
		this.clearAuthSession();
		this.idService.setUserId('Not Logged In');
		this.window.location.replace(this.logoutEndpoint);
	}

	/** Function used to determine if the current token is valid. Checks token expiration against the current timestamp
	 * @returns whether or not the token is expired
	 */
	public isTokenExpired(): boolean {
		const epoch = Math.trunc(new Date().getTime() / 1000);
		let expEpoch = null;
		const tokenExp = sessionStorage.getItem('tokenExp');
		if (tokenExp && tokenExp !== 'null') {
			expEpoch = parseInt(tokenExp, 10);
			return epoch >= expEpoch;
		} else {
			return true;
		}
	}

	/** Uses isTokenExpired() to determine if the user credentials should be cleared and the user forwarded to the login component
	 * @returns for whether the user is "logged in" or not
	 */
	public async checkLogin(route:ActivatedRouteSnapshot=null): Promise<boolean> {
		if (this.isTokenExpired()) {
			this.clearAuthSession();
			this.login();
			return false;
		}
		try {
			await this.userRoleService.ensureInitialized();
			await this.languageTranslationService.initializeTranslations('auth');
			const pageType = route.data['pageType'];
			const hasRole:boolean =  this.userRoleService.checkViewAccess(pageType);
			if(!hasRole){
				const defaultRoute=this.userRoleService.getDefaultPageAccess() || AppRoutes.UNAUTHORIZED;
				return this.router.navigate([defaultRoute]);
			}
			return true;
		} catch (error) {
			this.spinnerService.setInitialLoadSpinner({isLoading:false,errorMessage:error?.message || this.ERROR_MSG});
			console.error('Error initializing user role:', error);
			return false;
		}
	}

	/** Creates redirect url based on the route param and configuration
	 * from the config parameter passed into the component. Used for both login and logout.
	 *
	 * @param route the route at which redirect should navigate to
	 */
	private makeRedirectUrl(route: string): string {
		if (this.config.languageInUrl) {
			const languagePath = this.window.location.pathname.split('/')[1];
			return (
				this.window.location.origin + '/' + languagePath + '/' + route
			);
		}
		return this.window.location.origin + '/' + route;
	}

	/** clears all sessionStorage related to authentication
	 */
	public clearAuthSession(): void {
		sessionStorage.removeItem('tokenExp');
		sessionStorage.removeItem('tokenIssue');
		sessionStorage.removeItem('strAccessToken');
		sessionStorage.removeItem('encodedAccessToken');
		sessionStorage.removeItem('userId');
		sessionStorage.removeItem('idToken');
		sessionStorage.removeItem('displayName');
	}

	private getUserName(){
		let dispName = sessionStorage.getItem('displayName');
		if(dispName) return dispName;
		const token = sessionStorage.getItem('encodedAccessToken');
		if(!token) return dispName;
		let jwtpayload:UserAuthModel =  this.decodeToken(token);
		const fordName = jwtpayload.fordDisplayName + ' ('+jwtpayload.CommonName+')';
		dispName =  jwtpayload.fordDisplayName ? fordName : jwtpayload.CommonName;
		sessionStorage.setItem('displayName',dispName);
		return dispName;
	}

	public setLoggedInUserName(){
		const userName = this.getUserName();
		this.idService.setUserName(userName);
	}


	// Method to decode a JWT token
	private decodeToken(token: string): any {
		try {
			return jwtDecode(token);
		} catch (Error) {
			console.error('Invalid token', Error);
			return null;
		}
	}


}
