import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SiteData } from '../site-recovery.component';
import { ClientConstants } from 'src/app/common/client.constants';
import { HttpMethods } from 'src/app/common/http-methods.constants';
@Injectable({
  providedIn: 'root'
})
export class SiteRecoveryServiceService {
  baseUrlGateway: string = environment.baseUrlGateway;
  private sitesData: BehaviorSubject<SiteData[]> = new BehaviorSubject<SiteData[]>([]);
  private editFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private selectedRows: BehaviorSubject<SiteData[]> = new BehaviorSubject<SiteData[]>([]);
  private checkSaveChangesStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _httpClient: HttpClient) {
  }


  setCheckSaveChangesStatus(data: any) {
    this.checkSaveChangesStatus.next(data);
  }

  getCheckSaveChangesStatus() {
    return this.checkSaveChangesStatus.asObservable();
  }


  setSitesData(data: any) {
    this.sitesData.next(data);
  }

  getSitesData() {
    return this.sitesData.asObservable();
  }

  searchSiteCode(sites: string[]): Observable<any> {
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'sitecodecreation/verified-sitecode-details?siteCodeNames=' + sites,
      "configName": ClientConstants.CLIENT_4
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);
  }


  confirmDeleteRecords(records: any[]): Observable<any> {
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'sitecodecreation/deleteSiteCode',
      "configName": ClientConstants.CLIENT_4,
      "items": records,
      "httpMethod": HttpMethods.DELETE
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-array', requestBody);

  }

  modifySiteAttribute(sitesData: SiteData[]): Observable<any> {
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'sitecodecreation/savesitecode',
      "configName": ClientConstants.CLIENT_4,
      "httpMethod": HttpMethods.POST,
      "jsonPayload": sitesData,
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);
  }

  editableModal(data: boolean) {
    this.editFlag.next(data);
  }

  getEditableModal() {
    return this.editFlag.asObservable();
  }

  setSelectedRows(data: any[]) {
    this.selectedRows.next(data);
  }

  getSelectedRows() {
    return this.selectedRows.asObservable();
  }

}
