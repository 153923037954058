<div class="flex justify-content-between mx-2">
	<h1 class="text-2xl font-bold" style="color: #00095b">
		{{ userTitle || title | translate }}
	</h1>

	<!--	<p-menu #menu [model]="items" [popup]="true">-->
	<!--		<ng-template pTemplate="item" let-item>-->
	<!--			<a-->
	<!--				[routerLink]="item.routerLink"-->
	<!--				routerLinkActive="active-menu-item">-->
	<!--				{{ item.label }}-->
	<!--			</a>-->
	<!--		</ng-template>-->
	<!--	</p-menu>-->
	<div
		class="align-content-center"
		[adminCheck]="PageType.HELP_ACCESS"
		[excludeChange]="true"
		*ngIf="items && items.length">
		<p-menu styleClass="help-menu" #menu [model]="items" [popup]="true" autofocus="false" appendTo="body" />
		<p-button
			pTooltip="{{ 'tooltip.help' | translate }}"
			styleClass="shadow-none"
			tooltipPosition="bottom"
			(onClick)="menu.toggle($event)"
			icon="pi pi-question-circle" />
	</div>
</div>
