<div>
<!--	<input type="file" (change)="onFileSelected($event)">-->
    <div>
        <app-title [userTitle]="'SPS User Manuals' | translate"></app-title>
    </div>
    <div class="sps-container">
        <p-accordion>
            <p-accordionTab *ngFor="let section of spsSections" [header]="section.title">
                <ul>
                    <li *ngFor="let item of section.items">
                        <button (click)="openLink(item)" class="link-button">
                            <i class="pi" [ngClass]="(item.isLoading) ? 'pi-spinner pi-spin' : 'pi-file-pdf'" mr-5></i>
                            {{ item.label }}
                        </button>
                    </li>
                </ul>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
