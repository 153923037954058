<div id="app-branding" class="flex align-items-center justify-content-between">
	<div id="app-logo" class="pointer-events-none">
		<div id="app-icon">
			<img
				alt="Ford Oval"
				height="100px"
				src="assets/image/FordOval.png"
				width="267px" />
		</div>
		<div class="text-white" id="app-header-text">
			<h1>Partner Identity Management</h1>
		</div>
	</div>
	<div id="username" class="flex gap-1 text-white">
		<ng-container *ngIf="userName;else nologin">
			<p>Welcome, {{userName || 'User'}}</p>
			<p-button severity="secondary" label="{{'label.logout' | translate}}" (click)="logOut()" [rounded]="true"  *ngIf="userName"></p-button>
		</ng-container>
		<ng-template #nologin>
			<p-button severity="secondary" label="{{'label.login' | translate}}" (click)="login()" [rounded]="true"  *ngIf="!userName"></p-button>
		</ng-template>

	</div>
</div>
