import { ChangeDetectorRef, Component } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {UserGroupsService} from "../../user-groups/services/user-groups.service";
import {MemberTableModel} from "../../user-groups/members-table/member-user.model";
import { DropdownChangeEvent, DropdownFilterEvent } from 'primeng/dropdown';
interface Column {
	field: string;
	header: string;
}
@Component({
  selector: 'dynamic-dialog-actions',
  templateUrl: './dynamic-dialog-actions.component.html',
  styleUrls: ['./dynamic-dialog-actions.component.scss']
})

export class DynamicDialogActionsComponent {

dialogMessageHead:string;
dialogMessageBody:string;
dialogMessageFoot:string;
selectedItems: MemberTableModel[] = [];
selectedSecondaryUsers: MemberTableModel[] = [];
selectedPrimaryUsers: MemberTableModel[] = [];
siteCodeSecondary: string;
cols:Column[]
actionType: string;
siteCodeDescription: string;
siteCodeCountry: string;
siteSubGroup: string;
secondaryLoader:boolean = false;
primaryLocation:string;
destinationSiteCode:string;
siteCodeSecondaryList:[];
filteredSiteCodes: any[];
private searchSubject = new Subject<string>();

constructor(public ref: DynamicDialogRef, public configDialog: DynamicDialogConfig,private changeDetectorRef: ChangeDetectorRef, public userGroupsService: UserGroupsService) {
  this.selectedItems = configDialog.data?.selectedItems;
  this.primaryLocation=configDialog.data?.siteCode;
  this.cols = configDialog.data?.cols;
  this.dialogMessageHead = configDialog.data?.dialogMessageHead;
  this.dialogMessageBody = configDialog.data?.dialogMessageBody;
  this.dialogMessageFoot = configDialog.data?.dialogMessageFoot;
  this.actionType = configDialog.data?.actionType;
  this.selectedSecondaryUsers = this.selectedItems?.filter(item => item.location === "Secondary");
  this.selectedPrimaryUsers = this.selectedItems?.filter(item => item.location === "Primary");
  this.destinationSiteCode = configDialog.data?.siteCode;
  this.siteCodeSecondaryList = configDialog.data?.siteCodeSecondarySite
}

ngOnInit() {
  this.setupSearchDebounce();
}

setupSearchDebounce() {
  this.searchSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      map(query => query?.trim())
  ).subscribe(query => {
      query ? this.performSearch(query):"";
  });
}
onInputChange(event: DropdownChangeEvent) {
  this.siteCodeSecondary = event.value;
  this.siteCodeDescription="";

  this.searchSubject.next(this.siteCodeSecondary); // Emit the current value
}
onFilter(event: DropdownFilterEvent) {
  const filterValue = event.filter;
  this.filteredSiteCodes = this.siteCodeSecondaryList.filter((siteCode: any) => {
    return siteCode.value.toLowerCase().includes(filterValue);
  });

}

onEnter() {
  if (this.filteredSiteCodes?.length > 0) {
    const selectedSiteCode = this.filteredSiteCodes[0];
    this.siteCodeSecondary=selectedSiteCode.value;
  	setTimeout(()=>this.changeDetectorRef.markForCheck(),50);
  }
}
performSearch(query:string){
this.secondaryLoader = true;
this.userGroupsService.searchSiteCode(query).subscribe({
  next: (data: any) => {
  const descriptionObject = data?.hierarchies.find(hierarchy => hierarchy.description);
  const siteCodeCountry = data?.hierarchies.find(hierarchy => hierarchy.country);
  const siteSubGroup = data?.hierarchies.find(hierarchy => hierarchy.subGroup);
  this.siteCodeDescription = descriptionObject ? descriptionObject.description : 'No Description found in FDS';
  this.siteCodeCountry = siteCodeCountry && siteCodeCountry.country;
  this.siteSubGroup = siteSubGroup && siteSubGroup.subGroup;

    this.secondaryLoader = false;
  },
  error: error => {
    this.secondaryLoader = false;
  }
});

}
enableDescription(){
  return this.siteCodeDescription && this.siteCodeSecondary;
}
closeRef(close:boolean=false){
  let data=null;
  if(this.actionType=="SubmitAttestation"){
    data = {
      'close':close,
    }
    this.ref.close(data);
    return;
  }
  if(this.actionType=="RemoveMembers"){
    data = {
      'close':close,
    }
    this.ref.close(data);
    return;
  }else if(this.actionType=="TransferUsers"){
    data = {
      'close':close,'targetSiteCode':this.destinationSiteCode
    }
    this.ref.close(data);
    return;
  }else{
    if (this.siteCodeSecondary?.toUpperCase() == this.primaryLocation?.toUpperCase()) {
      this.siteCodeDescription = "The new Group cannot be the same as the old group. Please try again.";
      return;
    }
    else if(this.siteSubGroup === undefined || this.siteSubGroup === null){
      this.siteCodeDescription = "Sitecode not found in FDS";
    }
    else if(this.siteSubGroup==="fordeuropecode"){
      data = {
        'close':close,
        'siteCodeSecondary':this.siteCodeSecondary,
        'siteCodeCountry':this.siteCodeCountry,
        'siteSubGroup':this.siteSubGroup
      }
      this.ref.close(data);
      return;
    }else {
      this.siteCodeDescription = "Adding users to a secondary site is only possible within fordeuropecode.";
    }
  }
}

close(){
  this.ref.close();
}
ngOnDestroy() {
  this.searchSubject.complete();
}

disableYes(){
  return this.actionType==="AddSecondarySite" && !this.siteCodeSecondary || ((this.actionType=="AddSecondarySite" || this.actionType=="TransferUsers") && this.selectedSecondaryUsers.length===this.selectedItems.length);
}
}
