<div [adminCheck]="PageType.MANAGE_JOB_ROLES">
  <app-title></app-title>

  <ng-container>
    <div class="card flex justify-content-center border-round-xl flex p-5 mx-2 px-6 gap-8 bg-color-2  shadow-3 input-container">
            <span class="p-float-label">
          <p-dropdown [style]="{width:'200px'}" [options]="branchesList"  [(ngModel)]="selectedBranch" [dropdownIcon]="isBranchLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
			[disabled]="isBranchLoading"  (onChange)="onChangeBranch()" placeholder="{{'placeholder.selectBranch' | translate}}"  [filter]="true" filterBy="branchName"  optionLabel="branchName" inputId="float-label"  ></p-dropdown>
          <label class="manageinput" for="float-label">{{"label.branchName" | translate}}</label>
      </span>
      <span class="p-float-label">
        <p-dropdown  [style]="{width:'200px'}"[options]="siteCodeTypeList" [(ngModel)]="selectedSiteCodeType" placeholder="{{'placeholder.selectSiteCodeType' | translate}}"  (onChange)="onChangeSiteCodeType()" [filter]="true" filterBy="siteCodeType"  [filter]="true" filterBy="siteCodeType"  optionLabel="siteCodeType" inputId="float-label"></p-dropdown>
        <label class="manageinput" for="float-label">{{"label.siteCodeType" | translate}}</label>
    </span>
    <span class="p-float-label">
      <p-dropdown [style]="{width:'200px'}" [options]="marketList" [(ngModel)]="selectedMarket" placeholder="{{'placeholder.selectMarket' | translate}}" optionLabel="marketName" [filter]="true" filterBy="marketName"   (onChange)="onChangeMarket()" [filter]="true" filterBy="marketName"  inputId="float-label"></p-dropdown>
      <label class="manageinput" for="float-label">{{"label.market" | translate}}</label>
  </span>
  </div>
    <div class="card flex justify-content-center gap-3 p-4" [adminCheck]="PageType.MANAGE_JOB_ROLES" [buttonGroup]="true">
      <p-button label="{{'button.getJobRole' | translate}}" (onClick)="getJobRoles()" [disabled]="disableActionButton()" [hidden]="true"></p-button>
      <p-button label="{{'header.createJobRole' | translate}}" (click)="showDialog()" [disabled]="disableActionButton()"></p-button>
  </div>
  </ng-container>

  <div id="tabmenu" class="card overflow-y-auto tableStyle">

    <div *ngIf="results">
      <job-roles-table [adminCheck]="PageType.MANAGE_JOB_ROLES"></job-roles-table>
    </div>
    <div class="flex align-items-center justify-content-center" *ngIf="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <app-add-job-role *ngIf="selectedMarket" [selectedBranch]="selectedBranch" [selectedSiteCodeType]="selectedSiteCodeType" [selectedMarket]="selectedMarket" [siteCodeTypeList]="siteCodeTypeList" [marketList]="marketList"></app-add-job-role>
  <p-dialog header="Header" [(visible)]="enableDialog" [modal]="true">
    <ng-template pTemplate="header">
        <span class="text-xl font-bold">{{"label.response" | translate}}</span>
    </ng-template>
    <p>
     {{errorMessage}}
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-danger" (click)="enableDialog = false" label="{{'button.close' | translate}}" styleClass="p-button-text"></p-button>
        <!-- <p-button icon="pi pi-danger" (click)="visible = false" label="No" styleClass="p-button-text"></p-button> -->
    </ng-template>
  </p-dialog>
</div>
