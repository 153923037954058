<header id="app-header" class="bg-color-3 header-container">
	<app-nav></app-nav>
</header>
<div id="app-content" class="flex flex-grow-1 flex-column m-0 p-0 bg-color-5" >
	<div class="flex flex-grow-1">
		<div [class.col-fixed]="isCollapsed" class="p-0 menubar-container bg-color-2" [ngStyle]="{'width': isCollapsed ? '310px':'0px'}">
			<app-menubar></app-menubar>
		</div>
		<p-toast position="top-center" styleClass="toast-msg"></p-toast>
		<div class="px-3 pb-0 col flex flex-column w-8">
			<div class="flex-grow-1">
				<router-outlet ></router-outlet>
			</div>
			<footer id="footer" class="mt-3">
				<app-footer></app-footer>
			</footer>
		</div>
	</div>
</div>



