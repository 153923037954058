import { Component, ViewChild } from '@angular/core';
import { AdministrationService } from '../../services/administration.service';
import { ManageTemporaryIdService } from '../../services/manage.temporary.id.service';
import { TemporaryUserModel } from '../temp-members-table/temp-member.model';
import { Subject, takeUntil } from 'rxjs';
import { UtilityCommon } from 'src/app/core/utility.common';
import { MemberTableTempModel, PasswordStatus } from 'src/app/user-groups/members-table/member-user.model';
import {TempConstants, TempGroupModel} from '../manage-temp-ids/manage.temp.model'
import { AdminTempGroupsComponent } from '../admin-temp-groups/admin-temp-groups.component';
import { MenuItem } from 'primeng/api';
import { TabViewChangeEvent } from 'primeng/tabview';
import {PageType} from "../../../common/models/user-role/page-type.enum";
@Component({
  selector: 'manage-temp-ids',
  templateUrl: './manage-temp-ids.component.html',
  styleUrls: ['./manage-temp-ids.component.scss']
})
export class ManageTempIdsComponent {

	@ViewChild(AdminTempGroupsComponent) adminTempGroupsComponent: AdminTempGroupsComponent;
  selectedTempId: any;
  totalRecordsCount: number[] = [];
  tabs: { title: string, content: string }[] = [];
	cust: { title: string }[] = [];
  tempGroupName: string = TempConstants.TEMPORARY;
  languageCode: string = 'en';
  selectedTempGroup: TempGroupModel;
  tempIdList: any[] ;
  loading: boolean = false;
  members!: MemberTableTempModel[];
  enableDialog: boolean = false;
  responseMessage: string = '';
  responseTitle: string = '';
  loadDescription: string = '';
  jobRoleList: any[];
  loadingDialog: boolean = false;
  selectedIdsforDelete: MemberTableTempModel[] = [];
  onDestroy$: Subject<void> = new Subject();
  translatedResponseTitle: string = '';
  apiResponse: string ='';
  activeItem: number = 0;
    constructor(private manageTempidService: ManageTemporaryIdService) {
    this.manageTempidService.getTempTable().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
     if(data){
      this.getMembersForTempGroup();
     }
    });
  }
  ngOnInit(){
    this.tabs = [
      { title: 'Members', content: 'Tab 1 Content' },
      { title: 'Admin', content: 'Tab 2 Content' }
    ];

    this.cust = [
      { title: 'header.currentMembers' },
    ];
    this.loadTemporaryId();
    this.loadJobRoles();
  }

  ngOnDestroy(){
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  loadTemporaryId(){
    this.loading=true;
    this.loadDescription="Loading Temporary Ids";
    this.manageTempidService.getTemporaryIds(this.tempGroupName).subscribe({
      next: data => {
        this.tempIdList = data.entries.sort((n1: { name: string; }, n2: { name: any; }) => n1.name.localeCompare(n2.name))
        .map((item: any) => {
          return {
            tempName: item.name
          };
        });
        this.loading=false;
        this.loadDescription='';
      },
      error: error => {
        this.loading=false;
        this.loadDescription='';
      }
    });
  }

  loadJobRoles(){
    this.manageTempidService.getJobRoles(this.languageCode,this.tempGroupName).subscribe({
      next: (data:any) => {
        this.jobRoleList=data.sort((n1: { jobRoleName: string; }, n2: { jobRoleName: any; }) => n1.jobRoleName.localeCompare(n2.jobRoleName));
      },
      error: error => {
        console.log(error);
      }
    });
  }

  removeTempUser(data: { selectedIds: MemberTableTempModel[]}) {
    this.selectedIdsforDelete=data.selectedIds;
	}

  deleteConfirmation(){
    this.enableDialog=true;
    this.translatedResponseTitle="label.deleteConfirmation";
    this.responseTitle="Delete Confirmation";
    this.responseMessage="message.deleteTempUserConfirmation";
  }
  showDelete(){
    return this.responseTitle!=="Delete Confirmation";
  }
  deleteTemporaryId(){
    this.translatedResponseTitle="label.deleteTemporaryUser";
    this.responseTitle="Delete Temporary User Response";
    this.loadingDialog=true;
    let request={
      "userId":this.selectedIdsforDelete.map(item => item.userId),
      "lastModifiedBy":"SPSHELP"
    }
    this.manageTempidService.deleteTemporaryId(request).subscribe({
      next: data => {
        this.apiResponse=data?.message;
        // this.getMembersForTempGroup();
        this.loadingDialog=false;
      },
      error: error => {
        this.loadingDialog=false;
        this.translatedResponseTitle="label.deleteTemporaryUser";
        this.responseTitle="Delete Temporary User Response";
        this.responseMessage="message.errorOccurredWhileDeletingTempUser";
      }
    });
  }
  getMembersForTempGroup(){
    this.loading=true;
    this.loadDescription='Loading members for the selected Temporary group';
    this.manageTempidService.getMembersForTempGroup(this.selectedTempGroup?.tempName).subscribe({
      next: data => {
        this.members=data?.users?.map(result => {
					let passwordStatus = PasswordStatus.EXPIRED;
					let date: Date = null;

					//HINT: Check if the password last set date is valid and not "0"
					if (result.pwdLastSet !== "0") {
            let formatDate=UtilityCommon.formatDate(result.pwdLastSet);
            formatDate = UtilityCommon.formatPwdLastSet(formatDate);
						const [day, month, year] = formatDate?.split('/').map(Number);
						date = new Date(year, month - 1, day);
						passwordStatus = UtilityCommon.getDateDiff(date) <= 371 ? PasswordStatus.ACTIVE : PasswordStatus.EXPIRED;
						if(passwordStatus === PasswordStatus.ACTIVE)
							date.setDate(date.getDate() + 371);
            if(passwordStatus === PasswordStatus.EXPIRED)
							date.setDate(date.getDate() + 371);
					}
					return {
						userId: result.userId || null,
						firstName: result.firstName || null,
						lastName: result.lastName || null,
						primaryLocation: result.primaryLocation || null,
						passwordDate: date,
						passwordSetStatus: passwordStatus,
            email: result.email || null,
            jobrole: result.jobrole || null,
            comment: result.comment || null,};
				});
        this.loading=false;
        this.loadDescription='';
        this.members?.length>0? '' : this.loadError();
      },
      error: error => {
        this.loading=false;
        this.loadDescription='';
        this.enableDialog=true;
        this.translatedResponseTitle="message.errorResponse";
        this.responseTitle="Error Response";
        this.responseMessage="message.errorFetchingMembers";
      }
    });
  }
  onTabChange(event: TabViewChangeEvent) {
    this.activeItem = event?.index;
}
  loadError(){
    this.enableDialog=true;
    this.translatedResponseTitle="label.response";
    this.responseTitle="Response";
    this.responseMessage="message.noMembersFound";
  }
  public getTotalRecord(i: number): number {
		return this.totalRecordsCount[i] || 0;
	}

  disableandRefresh(){
    this.enableDialog=false;
    this.getMembersForTempGroup();
  }
	public getUpdatedRecordsCount(data: any[]): void {
		this.totalRecordsCount = data
	}
  createTemporaryId(){
    this.manageTempidService.openCreateTemporaryId(true);
  }

  closeDialog(){
    this.enableDialog=false;
    this.apiResponse='';
    this.responseTitle=="Delete Temporary User Response" ? this.getMembersForTempGroup() : "";
  }

	protected readonly PageType = PageType;
}
