import { Component } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {CommonConstants} from "../common.constants";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

	message:string;
	stringIds:string[];
	iconMsg:string;
	iconBorder:boolean;
	inlineIcon:boolean;
	constructor(public ref: DynamicDialogRef,public configDialog: DynamicDialogConfig,private translate: TranslateService)  {
		this.message = configDialog.data?.message || this.translate.instant(CommonConstants.CONFIRM_MSG);
		this.stringIds = configDialog.data?.stringIds?.split(",") || [];
		this.iconMsg = ( configDialog.data?.iconMsg || "pi pi-info-circle" ) + (configDialog.data?.inlineIcon ? "text-2xl": " text-7xl");
		this.iconBorder = configDialog.data?.iconBorder || false;
		this.inlineIcon = configDialog.data?.inlineIcon || false;
	}

	onDialogClose(action:boolean=false) {
		this.ref.close(action);
	}
}
