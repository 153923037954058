import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { ClientConstants } from 'src/app/common/client.constants';
import { HttpMethods } from 'src/app/common/http-methods.constants';
import { environment } from 'src/environments/environment';
import { AdminUpdateRequest, AdminUserRequest } from '../manage-temporary-id/admin-temp-groups/admin.model';


@Injectable({
  providedIn: 'root'
})
export class ManageTemporaryIdService{
  // baseUrl = environment.baseUrl;
  baseUrlGateway = environment.baseUrlGateway
  baseUrlGatewayMap: string = environment.baseUrlGateway+ '/gateway/for-map';
  baseUrlGatewayArray: string = environment.baseUrlGateway+ '/gateway/for-array';
  siteStructureBaseUrl: string = environment.baseUrlPcf + 'sitestructure/api/v1/';
  fdsBaseUrl: string = this.siteStructureBaseUrl + 'fds/';
  constructor(private _httpClient: HttpClient) { }

  private openCreateTempId = new BehaviorSubject<boolean>(false);
  public openCreateTempId$ = this.openCreateTempId.asObservable();

  openCreateTemporaryId(openCreateTempId: boolean) {
    this.openCreateTempId.next(openCreateTempId);
  }
  getOpenCreateTemporaryId(){
    return this.openCreateTempId.asObservable();
  }

  private openEditTempId = new BehaviorSubject<boolean>(false);
  public openEditTempId$ = this.openEditTempId.asObservable();

  openEditTemporaryId(openEditTempId: boolean) {
    this.openEditTempId.next(openEditTempId);
  }
  getOpenEditTemporaryId(){
    return this.openEditTempId.asObservable();
  }

  private editTempData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public editTempData$ = this.editTempData.asObservable();

  getEditTempData(){
    return this.editTempData.asObservable();
  }
  updateEditTempData(data: any) {
    this.editTempData.next(data);
  }
  private createTempUser = new BehaviorSubject<boolean>(false);
  public createTempUser$ = this.createTempUser.asObservable();
  createTemporaryUser(createTempUser: boolean) {
    this.createTempUser.next(createTempUser);
  }

  private refreshTempTable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshTempTable$ = this.refreshTempTable.asObservable();

  updateTempTable(data: boolean) {
    this.refreshTempTable.next(data);
  }
  getTempTable(){
    return this.refreshTempTable.asObservable();
  }

  getTemporaryIds(groupName:string): Observable<any> {
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'sitestructure/api/v1/fds/temporary-groups?group=' + groupName,
      "configName": ClientConstants.CLIENT_6
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);
    // return this._httpClient.get<any>(this.baseUrl + '/fds/matching-groups/'+groupName);
  }
  getMembersForTempGroup(groupName:string): Observable<any> {
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'sitestructure/api/v1/fds/members-for/' + groupName,
      "configName": ClientConstants.CLIENT_6
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);
    // return this._httpClient.get<any>(this.baseUrl + '/fds/members-for/'+groupName);
  }

  createTempIdUserService(request:any): Observable<any>{
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'usermanagement/api/v2/user/createTempUser',
      "configName": ClientConstants.CLIENT_2,
      "jsonPayload":request,
      "httpMethod": HttpMethods.POST
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);
  }
  updateTempIdUserService(request:any): Observable<any>{
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'usermanagement/api/v2/user/updateTempUser',
      "configName": ClientConstants.CLIENT_2,
      "jsonPayload":request,
      "httpMethod": HttpMethods.POST
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);
  }
  getJobRoles(languageCode:string,tempGroupName:string){
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'sitestructure/api/v1/job-role/job-role-list?languageCode='+languageCode+'&siteCodeType='+tempGroupName,
      "configName": ClientConstants.CLIENT_6,
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-array', requestBody);
    // return this._httpClient.get(this.baseUrl + '/job-role/job-role-list?languageCode='+languageCode+'&siteCodeType='+tempGroupName);
  }
  deleteTemporaryId(request:any): Observable<any>{
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'usermanagement/api/v2/user/deleteTempUser',
      "configName": ClientConstants.CLIENT_2,
      "jsonPayload":request,
      "httpMethod": HttpMethods.POST
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);
  }
  createTempGroup(request:any): Observable<any>{
    let requestBody: any = {
      "endpoint": environment.baseUrlPcf + 'sitestructure/api/v1/fds/create-temp-group?groupName='+request.groupName+'&groupDesc='+request.groupDesc+'&groupOwner='+request.groupOwner,
      "configName": ClientConstants.CLIENT_6,
      "httpMethod": HttpMethods.POST
    }
    return this._httpClient.post(this.baseUrlGateway + '/gateway/for-map', requestBody);
  }
  public getAdminUsers(tempGroupName:string){
		let requestBody:any = {
			"endpoint": this.fdsBaseUrl + 'temp-admin-member?temp-group-name='+tempGroupName,
			"configName": ClientConstants.CLIENT_6
		}
		return this._httpClient.post(this.baseUrlGatewayArray,requestBody);
	}

	public addAdminUser(adminUser:AdminUserRequest){
		let requestBody:any = {
			"endpoint": this.fdsBaseUrl+ `temp-admin-member`,
			"configName": ClientConstants.CLIENT_6,
			"jsonPayload":adminUser,
			"httpMethod": HttpMethods.POST
		}
		return this._httpClient.post(this.baseUrlGatewayMap,requestBody);
	}

	public updateAdminUser(adminUserUpdate:AdminUpdateRequest){
		let requestBody:any = {
			"endpoint": this.fdsBaseUrl+ `temp-admin-member`,
			"configName": ClientConstants.CLIENT_6,
			"jsonPayload":adminUserUpdate,
			"httpMethod": HttpMethods.PUT
		}
		return this._httpClient.post(this.baseUrlGatewayMap,requestBody);
	}

	public deleteAdminUser(tempGroupName:string,userList:string){
		let requestBody:any = {
			"endpoint": this.fdsBaseUrl + `temp-admin-member?temp-group-name=${tempGroupName}&users-list=${userList}` ,
			"configName": ClientConstants.CLIENT_6,
			"httpMethod": HttpMethods.DELETE
		}
		return this._httpClient.post(this.baseUrlGatewayMap,requestBody);
	}

}
