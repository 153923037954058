import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {MenuItem, MessageService, TreeNode} from 'primeng/api';
import {MenubarService} from 'src/app/common/menubar/service/menubar.service';
import {UserGroupsService} from '../services/user-groups.service';
import {BehaviorSubject, finalize, map, Subject, takeUntil, tap} from 'rxjs';
import {BreadcrumbItemClickEvent} from "primeng/breadcrumb";
import {BaseUserGroupModel} from "../base-usergroup.model";
import {CustomerMember, MemberTableModel, PasswordStatus, Status} from "../members-table/member-user.model";
import {UtilityCommon} from "../../core/utility.common";
import {JobRole} from "../create-new-user/user.model";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {CreateNewUserComponent} from "../create-new-user/create-new-user.component";
import {DynamicDialogActionsComponent} from "../../common/dynamic-dialog-actions/dynamic-dialog-actions.component";
import {SupportCenterData} from '../support-center/support-center.component';
import {Branch} from "../search-tree-node/search-node.model";
import { OverlayPanel } from 'primeng/overlaypanel';
import {AuditStatusComponent} from "../audit-status/audit-status.component";
import {AdminUserGroupsComponent} from "../admin-user-groups/admin-user-groups.component";
import {Column, Language, SiteCodeType, TabModel} from "./manage-user-groups.model";
import {TranslateService} from '@ngx-translate/core';
import {PageType} from "../../common/models/user-role/page-type.enum";
import {AdminFilterPipe} from "../../common/admin-filter.pipe";
import {TabView, TabViewChangeEvent} from 'primeng/tabview';
import {CommonConstants} from "../../common/common.constants";
import { UserIdService } from 'src/app/core/ngx-adfs';

@Component({
	selector: 'manage-user-groups',
	templateUrl: './manage-user-groups.component.html',
	styleUrls: ['./manage-user-groups.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageUserGroupsComponent implements OnInit, OnDestroy {
	@ViewChild('op') op!: OverlayPanel;
	@ViewChild(AuditStatusComponent) auditcomp: AuditStatusComponent;
	@ViewChild(AdminUserGroupsComponent) adminUserGroupsComponent: AdminUserGroupsComponent;
	selectedNodes: TreeNode | null = null;
	displayNodes: MenuItem[] = [];
	tabs: TabModel[] = [];
	customerMember: CustomerMember[] = [];
	actions: MenuItem[] | undefined;
	isCollapsed = false;
	jobroleData: JobRole[] = [];
	private destroy$ = new Subject<void>();
	isSiteCode: boolean;
	baseUserGroupModel: BaseUserGroupModel;
	memberLoader: boolean = false;
	keyFilter: string = '^[a-zA-Z0-9-]*$'; // Regular expression for alphanumeric + hyphen
	openTransfer: boolean = false;
	cols!: Column[];
	enableDialog: boolean = false;
	responseModalTitle: string = 'label.response';
	responseMessage: string = '';
	branchList: Branch[] = [];
	site!: SiteCodeType[];
	multiselect: boolean = false;
	selectedMembersForAction: MemberTableModel[] = [];
	activeMembers: MemberTableModel[] = [];
	InactiveMembers: MemberTableModel[] = [];
	disableSecondaryUser: boolean = false;
	disableCreateUser: boolean = false;
	disabledSiteCodeType = ['fordeuropecode'];
	userIdSecondary: string = '';
	userIdSecondarySite: string = '';
	secondaryLoader: boolean = false;
	secondaryUserResponse: string = '';
	userIdSecondaryError: string = '';
	supportCenterNotifier: BehaviorSubject<SupportCenterData | null> = new BehaviorSubject<SupportCenterData | null>(null);
	ref: DynamicDialogRef | undefined;
	language: any[] = [];
	loadingButton: boolean = false;
	respMessageClass:string='';
	siteCodeDescription:string;
	overallAuditStatus:string;
	overallAuditDate:Date;
	activeItem: number=0;
	activeTabHeader:string;
	siteCodes:[];
	loggedinUser: string;
	siteCodesSecondarySite:[];
	copyGroupName: boolean;
	groupName: string;
	toggleFrom: string;
	onDestroy$: Subject<void> = new Subject();
	constructor(
		private menubarService: MenubarService,
		private userGroupService: UserGroupsService,
		private changeDetectorRef: ChangeDetectorRef,
		public dialogService: DialogService,
		private translate:TranslateService,
		private adminFilter:AdminFilterPipe,
		private userService:UserIdService,
		private messageService:MessageService
	) {
		this.menubarService.isCollapsed$
			.pipe(takeUntil(this.destroy$))
			.subscribe((isCollapsed) => {
				this.isCollapsed = isCollapsed;
				this.changeDetectorRef.markForCheck();
			});
			this.userGroupService.getTransferRefresh().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
				if (data) {
				  this.getUsersForSiteCodes(this.baseUserGroupModel);
				// this.initializeActions()
				}
			  });
			this.userGroupService.fetchBranchList()
				.pipe(
					tap((response: any[]) => console.log("TOP GROUP", response)),
					map((responseArray: any[]) => responseArray.map((response) => ({
						name: response?.branchName,
						code: response?.branchCode
					})))
				)
				.subscribe((data: Branch[]) => {
				this.branchList = data;
				this.changeDetectorRef.markForCheck();
			})
	}

	ngOnInit() {
		this.userService.getUserId().pipe(takeUntil(this.onDestroy$)).subscribe((data:string) => {
      this.loggedinUser = data;
    });
		this.tabs =[];
		this.cols = [
			{field: 'userId', header: 'User ID'},
			{field: 'firstName', header: 'First Name'},
			{field: 'lastName', header: 'Last Name'},
		];

		this.site = [
			{sitecodetype: 'fordasiapacific'},
			{sitecodetype: 'fordeuropecode'}
		];
		this.customerMember = [{title: 'Active Members', dataList: [],ttitle:'title.activeMembers'}, {
			title: 'Inactive Members',
			dataList: [],
			ttitle:'title.inactiveMembers'
		}];
		this.initializeActions();
	}
	breadCrumbSelect(event: BreadcrumbItemClickEvent) {
		this.selectedNodes = event.item as TreeNode;
		this.getLabel(this.selectedNodes);
	}

	private initializeTabs({ siteCodeType, market, siteCode }: BaseUserGroupModel) {
		const defaultTabs: TabModel[] = [];
		if (siteCodeType)
			defaultTabs.push({ title: 'Admin', content: 'Tab 2 Report Content', ttitle: 'title.admin', order: 2,pageType:PageType.ADMINISTRATORS });

		if (market)
			defaultTabs.push(
				{ title: 'Audit', content: 'Tab 4 Audit Content', ttitle: 'label.audit', order: 3 ,pageType:PageType.AUDIT },
				{ title: 'Support Center', content: 'Support Center', ttitle: 'title.supportCenter', order: 4 ,pageType: PageType.SUPPORT_CENTER}
			);

		if (siteCode)
			defaultTabs.push({ title: 'Members', content: 'Tab 1 Content', ttitle: 'title.members', order: 1 ,pageType: PageType.MEMBERS});

		if (defaultTabs.length === 0) {
			this.tabs = [];
			return;
		}
		if (this.tabs.length !== defaultTabs.length) {
			this.tabs = defaultTabs.sort((a, b) => a.order - b.order);
		}
		this.tabs = this.adminFilter.transform(this.tabs,this.baseUserGroupModel);
	}

	public getJobRoles(): Promise<JobRole[]> {
		const branchCode = this.branchList.find(item => item.name === this.displayNodes[0]?.label)?.code;
		const requestBody = {
			branchCode: branchCode,
			siteCodeType: this.displayNodes[1]?.label,
			marketName: this.displayNodes[2]?.label || 'ALL'
		};

		return new Promise<JobRole[]>((resolve, reject) => {
			this.userGroupService.getJobRoles(requestBody).subscribe({
				next: data => {
					const jobRoles = data?.jobRoleResponseList?.map(item => ({
						jobRoleCode: item.jobRoleCode ?? null,
						jobRoleName: item.jobRoleName ?? null
					})) || []; // Handle null or undefined jobRoleResponseList

					const sortedJobRoles = jobRoles.sort((a, b) => (a.jobRoleName ?? '').localeCompare(b.jobRoleName ?? ''));
					this.jobroleData = sortedJobRoles;
					resolve(sortedJobRoles);
				},
				error: error => {
					console.error('Error fetching job roles:', error);
					reject(new Error('Failed to fetch job roles. Please check your network connection and try again.'));
				}
			});
		});
	}

	getLanguageData(): Promise<Language[]> {
		return new Promise<Language[]>((resolve, reject) => {
			this.userGroupService.getLanguageData().subscribe({
				next: data => {
					//Handle potential null or undefined data
					const languages = data ? data.map(item => {
						//Handle potential null or undefined properties in each item
						return {
							languageCode: item.languageCode ?? null,
							languageName: item.languageName ?? null
						};
					}) : [];


					const sortedLanguages = languages.sort((n1, n2) =>
						(n1.languageName ?? '').localeCompare(n2.languageName ?? '')
					);

					this.language = sortedLanguages;
					resolve(sortedLanguages);
				},
				error: error => {
					console.error("Error fetching language data:", error);
					reject(new Error("Failed to fetch language data. Please check your network connection and try again."));
				}
			});
		});
	}

	openTransferModal() {
		this.siteCodes=[];
		this.loadSiteCodes();
		this.changeDetectorRef.markForCheck();
		this.openTransfer = true;
		this.userGroupService.createTransferUser(this.openTransfer);
	}

	removeUser(data: { selectedAudits: MemberTableModel[], titleTab: string }) {
		if (data.titleTab === "Active Members") {
			this.activeMembers = data.selectedAudits;
		}
		if (data.titleTab === "Inactive Members") {
			this.InactiveMembers = data.selectedAudits;
		}
		this.selectedMembersForAction = Array.from(new Set([...this.activeMembers, ...this.InactiveMembers]));
	}


	async createUser() {
    this.loadingButton = true; // Show loading indicator

    try {
      const [jobRoles, languageData]= await Promise.all([this.getJobRoles(), this.getLanguageData()]);
      this.jobroleData = jobRoles;
      this.language = languageData;
      if (this.language.length) {
				let jobRoleRequired = this.jobroleData?.length > 0;
				this.loadingButton = false;
				this.changeDetectorRef.markForCheck();
        this.ref = this.dialogService.open(CreateNewUserComponent, {
          header: this.translate.instant('button.createNewUser'),
          width: '100%',
          data: {
            jobroleData: this.jobroleData,
            language: this.language,
            multiselect: this.multiselect,
            baseUserGroupModel: this.baseUserGroupModel,
						jobRoleRequired: jobRoleRequired
          }
        });
				this.ref.onClose.subscribe((data) => {
					if (data?.close) {
						this.changeDetectorRef.markForCheck();
						this.getUsersForSiteCodes(this.baseUserGroupModel);
				}});
      } else {
        console.error('getJobRoles or getLanguageData returned unexpected data or null.');
        // Handle the error appropriately (e.g., show an error message to the user)
				// if (!this.jobroleData.length){this.responseMessage="No job role found for given criteria. Please create job role for this combination and then try to create new user."}
				if (!this.language.length){this.responseMessage="No language data found for given criteria. Please create language data for this combination and then try to create new user."}
				this.loadingButton = false;
				this.changeDetectorRef.markForCheck();
				this.enableDialog = true;
				this.responseModalTitle = "message.errorResponse";
				this.changeDetectorRef.markForCheck();

      }
    } catch (error) {
			this.loadingButton = false;
			this.changeDetectorRef.markForCheck();
			console.error('Error creating user:', error);

			// Access and display the API error response
			if (error && error?.message){
					this.responseMessage = error?.message;
			}else if(error?.message){
					this.responseMessage = error?.message;
			} else {
					this.responseMessage = 'An unexpected error occurred.';
			}


			this.enableDialog = true;
			this.responseModalTitle = "message.errorResponse";
			this.changeDetectorRef.markForCheck();
		} finally {
			this.loadingButton = false;
			this.changeDetectorRef.markForCheck();
		}
	}

	copyGroup(){
    this.messageService.add({severity:'success', summary:'Success', detail:'Copied to dashboard!'});
  }

	removeMembers() {
		this.ref = this.dialogService.open(DynamicDialogActionsComponent,
			{
				header: this.translate.instant('header.removeMemberConfirmation'), width: "50%",
				data: {
					selectedItems: this.selectedMembersForAction,
					cols: this.cols,
					siteCode: this.baseUserGroupModel.siteCode,
					actionType:"RemoveMembers",
					dialogMessageHead: 'message.removeUserHead',
					dialogMessageBody: 'message.removeUserBody',
					dialogMessageFoot: 'message.removeUserFoot'
				}
			});
			this.ref.onClose.subscribe((data) => {
				if (data?.close) {
					this.confirmDelete();
				}
			});
	}

  validateUserId(): string {
    const regex = new RegExp(this.keyFilter);
    if (!regex.test(this.userIdSecondary)) {
      return 'Only alphanumeric characters and hyphens are allowed.';
    }
    return ''; // No error
  }

	addSecondaryUser(){
		this.secondaryLoader=true;
		this.userGroupService.addSecondaryUser(this.baseUserGroupModel.branch,this.baseUserGroupModel.siteCodeType,this.baseUserGroupModel.market,this.baseUserGroupModel.siteCode,this.userIdSecondary).subscribe({
			next: data => {
				this.secondaryUserResponse=data?.value;
				this.respMessageClass='message-success';
				this.secondaryLoader = false;
				this.changeDetectorRef.markForCheck();
			},
			error: error => {
				this.secondaryUserResponse=error?.message;
				this.secondaryLoader = false;
				this.respMessageClass='message-failure';
				this.changeDetectorRef.markForCheck();

			}
		});
	}
	onChangeSecInput(){
		this.secondaryUserResponse="";
		this.userIdSecondaryError = this.validateUserId();
	}
	showSecondaryUserError(){
		return this.userIdSecondary?.length>0 && this.secondaryUserResponse?.length > 0;
	}
	clearInputData() {
		this.userIdSecondary = '';
		this.secondaryUserResponse = '';
		this.changeDetectorRef.markForCheck();
	}
	closeOP(){
		this.userIdSecondary?this.getUsersForSiteCodes(this.baseUserGroupModel):"";
		this.userIdSecondary = '';
		this.secondaryUserResponse = '';
		this.op.hide(); // HINT: Close the overlay panel
	}
	addToSecondarySite() {
		this.ref = this.dialogService.open(DynamicDialogActionsComponent,
			{
				header: this.translate.instant('header.addMemberSecondarySite'), width: "50%",
				data: {
					selectedItems: this.selectedMembersForAction,
					cols: this.cols,
					siteCode: this.baseUserGroupModel.siteCode,
					actionType:"AddSecondarySite",
					dialogMessageHead: 'message.addSecondarySiteHead',
					dialogMessageFoot: 'message.addSecondarySiteFoot',
					siteCodeSecondarySite: this.siteCodes
				}
			});
		this.ref?.onClose.subscribe((data) => {
			if (data?.close) {
				this.memberLoader=true;
				this.changeDetectorRef.markForCheck();
				this.confirmAddSecondarySite(data?.siteCodeSecondary,data?.siteCodeCountry,data?.siteSubGroup);
			}
		});
	}
	submitAuditAttestation() {
		this.ref = this.dialogService.open(DynamicDialogActionsComponent,
			{
				header: this.translate.instant('header.submitAuditAttestation'), width: "50%",
				data: {
					selectedItems: this.selectedMembersForAction,
					cols: this.cols,
					siteCode: this.baseUserGroupModel.siteCode,
					actionType:"SubmitAttestation",
					dialogMessageHead: 'message.submitAttestationHead',
					dialogMessageFoot: 'message.submitAttestationFoot'
				}
			});
		this.ref.onClose.subscribe((data) => {
			if (data?.close) {
				this.memberLoader=true;
				this.changeDetectorRef.markForCheck();
				this.confirmSubmitAuditAttestation();
			}
		});

	}

	confirmSubmitAuditAttestation(){
		let request={
			"branch":this.baseUserGroupModel.branch,
			"fordSiteCodeType":this.baseUserGroupModel.siteCodeType,
			"country":this.baseUserGroupModel.market,
			"siteCode":this.baseUserGroupModel.siteCode,
			"userCDSId":this.selectedMembersForAction.map(item => item.userId),
			"auditedById":this.loggedinUser,
			"auditDueDate":this.overallAuditDate
		}
		this.userGroupService.submitAuditAttestation(request).subscribe({
			next: data => {
				this.getUsersForSiteCodes(this.baseUserGroupModel);
				this.memberLoader = false;
				this.responseModalTitle = "Submit Audit Attestation Response";
				this.responseMessage = "User Audit verification has been recorded successfully.";
				this.enableDialog = true;
				this.selectedMembersForAction=[];
				setTimeout(()=> this.auditcomp?.updateAuditStatus(this.baseUserGroupModel),100);
				this.changeDetectorRef.markForCheck();
			},
			error: error => {
				this.memberLoader = false;
				this.responseModalTitle="Submit Audit Attestation Response";
				this.responseMessage="Submit Audit verification request failed. - "+error?.message;
				this.enableDialog = true;
				this.selectedMembersForAction=[];
				this.changeDetectorRef.markForCheck();
			}
		});
	}
	disableSecondarySite(){
		return !this.disableSecondaryUser;
	}
	confirmAddSecondarySite(siteCodeSecondary:string,siteCodeCountry:string,siteSubGroup:string) {
		this.memberLoader = true;
			this.userIdSecondarySite = this.selectedMembersForAction.map(item => item.userId).join(',');
			this.userGroupService.addSecondaryUser(this.baseUserGroupModel.branch,siteSubGroup,siteCodeCountry,siteCodeSecondary,this.userIdSecondarySite).subscribe({
				next: data => {
					this.memberLoader = false;
					this.responseModalTitle = "message.addSecondarySiteRequest";
					this.responseMessage = "When operation is complete, the secondary site will be reflected in the Groups profile of the user."
					this.enableDialog = true;
					this.selectedMembersForAction=[];
					this.changeDetectorRef.markForCheck();
				},
				error: error => {
					this.memberLoader = false;
					this.responseModalTitle="message.errorResponse";
					this.responseMessage="Add to Secondary Site request failed. - "+error?.message;
					this.enableDialog = true;
					this.selectedMembersForAction=[];
					this.changeDetectorRef.markForCheck();

				}
			});
	}

	loadSiteCodes(){
    this.userGroupService.getSiteCodes(this.baseUserGroupModel?.branch,this.baseUserGroupModel?.market,this.baseUserGroupModel?.siteCodeType).subscribe({
      next: (data: any) => {
        this.siteCodes = data.siteCodes.sort().map((siteCode: any) => {
          return {
            value: siteCode,
            disabled: siteCode?.localeCompare(this.baseUserGroupModel.siteCode) == 0 ? true : false
            }
      });
				this.changeDetectorRef.markForCheck();
      },
      error: error => {
        console.log("Error",error);
      }
    });
  }

	checkJobRoleSelection() {
		if (this.site.find(item => item.sitecodetype === this.displayNodes[1]?.label)) {
			this.multiselect = true;
		} else {
			this.multiselect = false;
		}
	}

	private initializeActions() {
		this.updateActions();
	}

	private updateActions() {
		this.actions = [
			{
				label: this.translate.instant('label.removeMembers'), icon: 'pi pi-users', command: () => {
					this.removeMembers();
				}
			},
			{
				label: this.translate.instant('button.transfer'), icon: 'pi pi-file-export', command: () => {
					this.openTransferModal();
				}
			},
			...(this.disableSecondaryUser ? [{
				label: this.translate.instant('label.addToSecondarySite'), icon: 'pi pi-plus', command: () => {
					this.addToSecondarySite();
				}
			}] : []),
			...(this.overallAuditStatus == 'INPROGRESS' || this.overallAuditStatus == 'OVERDUE' ? [{
				label: this.translate.instant('label.submitAuditVerification'), icon: 'pi pi-file', command:()=>this.submitAuditAttestation()}
			]:[]),
		];
		this.changeDetectorRef.markForCheck();
		this.userGroupService.updateTransferRefresh(false);
	}

	updateSelection(node: TreeNode) {
		this.displayNodes = [];
		let currentNode: TreeNode | null = node;
		while (currentNode) {
			this.displayNodes.unshift(currentNode);
			currentNode = currentNode.parent;
		}
		this.changeDetectorRef.markForCheck();
	}
	getGroupName(baseGroupModel:BaseUserGroupModel){
		this.groupName=Object.values(baseGroupModel).filter(e=>!!e).join('-');
	}

	expandCollapse(){
		this.toggleFrom=PageType.TREE_STRUCTURE;
		this.menubarService.getToggleMenu().pipe(takeUntil(this.onDestroy$)).subscribe(data => {
			if (data) {
				return;
			}else if(this.toggleFrom===PageType.TREE_STRUCTURE){
				this.menubarService.toggleMenu();
				this.toggleFrom="";
			}
			});
	}
	getLabel(node: TreeNode) {
		this.selectedNodes = node;
		this.updateSelection(node);
		this.baseUserGroupModel = new BaseUserGroupModel(this.displayNodes[0].label, this.displayNodes[1]?.label, this.displayNodes[2]?.label, this.displayNodes[3]?.label);
		this.disableSecondaryUser = this.disabledSiteCodeType.some(item => item === this.displayNodes[1]?.label);
		this.disableCreateUser = this.baseUserGroupModel?.siteCodeType === CommonConstants.FLEET;
		this.updateActions();
		this.getDescription(this.baseUserGroupModel);
		this.getGroupName(this.baseUserGroupModel);
		this.isSiteCode = !this.selectedNodes?.children && !this.selectedNodes?.leaf;
		this.initializeTabs(this.baseUserGroupModel);
		setTimeout(()=> this.auditcomp?.updateAuditStatus(this.baseUserGroupModel),100)
		this.isSiteCode && this.getUsersForSiteCodes(this.baseUserGroupModel);
		this.isSiteCode && this.loadSiteCodes();
		this.isSiteCode &&this.expandCollapse();
		setTimeout(()=> this.adminUserGroupsComponent?.getAdminUserList(this.baseUserGroupModel),100);
		// this.getJobRoles();
		this.checkJobRoleSelection();
		this.changeDetectorRef.markForCheck();
		this.supportCenterNotifier.next({
			siteCodeType: this.displayNodes[1]?.label,
			marketCode: this.displayNodes[2]?.label,
			dealerCode: !node.children && !node.leaf,
		});
	}

	getDescription(baseGroupModel:BaseUserGroupModel){
		if(baseGroupModel?.siteCode){
			this.getSelectedDesc(baseGroupModel?.siteCode);
		}
		else if(baseGroupModel?.market){
			this.getSelectedMarketDesc(baseGroupModel);
		}
		else if(baseGroupModel?.siteCodeType){
			this.siteCodeDescription='NA';
		}
	}
	getSelectedDesc(sitecode){
		this.siteCodeDescription = 'NA'
		this.userGroupService.getSiteCodeDescription(sitecode).subscribe(data=>{
			const descriptionObject = data.hierarchies.find(hierarchy => hierarchy.description);

			this.siteCodeDescription = descriptionObject ? descriptionObject.description : this.siteCodeDescription;
			this.changeDetectorRef.markForCheck();

		})
	}

	getSelectedMarketDesc(baseGroupModel:BaseUserGroupModel){
		this.siteCodeDescription = 'NA';
		this.userGroupService.getMarketDescription(baseGroupModel).subscribe(data=>{
			const descriptionObject = data.marketDetails.find(hierarchy => hierarchy.description);
			this.siteCodeDescription = descriptionObject ? descriptionObject.description : this.siteCodeDescription;
			this.changeDetectorRef.markForCheck();
		})
	}
	getBranchCode(branchName: string) {
		return this.branchList.find((branch: Branch) => branch.name === branchName)?.code;
	}

	getUsersForSiteCodes(baseUserGroupModel:BaseUserGroupModel): void {
		this.memberLoader = true;
		this.activeMembers = [];
		this.InactiveMembers = [];
		this.selectedMembersForAction = [];
		this.customerMember[0].dataList = this.customerMember[1].dataList = [];
		this.userGroupService.getUsersforGroupsV2(baseUserGroupModel,PageType.MEMBERS).pipe(
			tap((resp) => {
				console.log(resp)
			}),
			map((response: any) => response?.userAuditStatusResponseList?.map(result => {
					let passwordStatus = PasswordStatus.EXPIRED;
					let date: Date = null;

					//HINT: Check if the password last set date is valid and not "0"
					if (result.passwordLastSet !== "0" && UtilityCommon.isValidDate(result.passwordLastSet)) {
						const [day, month, year] = result.passwordLastSet.split('/').map(Number);
						date = new Date(year, month - 1, day);
						passwordStatus = UtilityCommon.getDateDiff(date) <= 371 ? PasswordStatus.ACTIVE : PasswordStatus.EXPIRED;
						if(passwordStatus === PasswordStatus.ACTIVE)
							date.setDate(date.getDate() + 371);
						if(passwordStatus === PasswordStatus.EXPIRED)
							date.setDate(date.getDate() + 371);
					}
					const lastAuditDate = UtilityCommon.isValidDateAudit(result.lastAuditedDate) ? new Date(result.lastAuditedDate) : null;
					return {
						userId: result.userId || null,
						firstName: result.firstName || null,
						lastName: result.lastName || null,
						location: result.location || null,
						primaryLocation: result.userPrimaryLocation || null,
						passwordDate: date,
						passwordSetStatus: passwordStatus,
						status: result.status === "on" ? Status.ACTIVE : Status.INACTIVE,
						lastAuditedBy: result.lastAuditedBy,
						lastAuditedDate: lastAuditDate
					};
				})
			),
			finalize(() => {
				this.memberLoader = false;
				this.changeDetectorRef.markForCheck();
			})
		).subscribe((data: MemberTableModel[]) => {
			this.customerMember[0].dataList = (data || []).filter((a: MemberTableModel) => a.status === Status.ACTIVE || !this.customerMember[1].dataList.push(a));
			this.userGroupService.updateTransferRefresh(false);
		});
	}

	getOverallAudit(event){
		this.overallAuditStatus = event.auditStatus || null;
		this.overallAuditDate = event.auditDate || null;
		this.updateActions();
	}
	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	onTabChange(event: TabViewChangeEvent,tabView: TabView) {
    this.activeItem = event?.index;
	this.activeTabHeader = tabView.tabs[event.index].header;
}
	protected readonly open = open;

	confirmDelete() {
		this.memberLoader=true;
		this.changeDetectorRef.markForCheck();
		//fetch items from selectedMembersForAction where location not equal to secondary
		// let deleteUsers=this.selectedMembersForAction.filter(item=>item.location!=="Secondary");
		let request = {
			"userId":this.selectedMembersForAction.map(item => item.userId),
			"fordSiteCode":this.baseUserGroupModel.siteCode,
			"lastModifiedBy":"SPSHELP"
		}
		this.userGroupService.deleteUserService(request).subscribe({
			next: data => {
				this.getUsersForSiteCodes(this.baseUserGroupModel);
				this.responseModalTitle = "message.removeUserRequest";
				this.responseMessage = "Please be patient! This request will take some time to complete. You can check the progress by verifying the users are no longer listed on the group Members tab."
				this.enableDialog = true;
				this.selectedMembersForAction=[];
				this.changeDetectorRef.markForCheck();
			},
			error: error => {
				this.responseModalTitle = "message.errorResponse";
				this.responseMessage = "Remove User(s) request failed. Please try again later."
				this.enableDialog = true;
				this.memberLoader=false;
				this.selectedMembersForAction=[];
				this.changeDetectorRef.markForCheck();
			}
		});
	}

	protected readonly PageType = PageType;
	protected readonly CommonConstants = CommonConstants;

	editUser($event: MemberTableModel) {
		this.getUsersForSiteCodes(this.baseUserGroupModel);
	}
}
