import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ManageTemporaryIdService } from '../../services/manage.temporary.id.service';
import { EditTempIdComponent } from '../edit-temp-id/edit-temp-id.component';
import { FormBuilder } from '@angular/forms';
import {TemporaryUserModel} from '../temp-members-table/temp-member.model'
import { Table, TableRowSelectEvent, TableSelectAllChangeEvent } from 'primeng/table';
import { MemberTableTempModel, PasswordStatus } from 'src/app/user-groups/members-table/member-user.model';
import { DatePipe } from '@angular/common';
import {EnvConstants} from "../../../common/env.constants";

@Component({
  selector: 'temp-members-table',
  templateUrl: './temp-members-table.component.html',
  styleUrls: ['./temp-members-table.component.scss'],
	providers:[DatePipe]
})

export class TempMembersTableComponent{
  customers!: any[];
	filteredTableRecords: any[] = [];
	showFilter : boolean = true;
	filterUserId: string = '';
	openEditDialog: boolean = false;
	@Output() private recordsCount = new EventEmitter<any>();
	@Input() members: MemberTableTempModel[];
	@Output() removeTempUser = new EventEmitter<{ selectedIds: MemberTableTempModel[]}>();

	selectedIds: MemberTableTempModel[] = [];
	constructor(private datePipe: DatePipe,private manageTempService: ManageTemporaryIdService) { }

	ngOnInit() {
		this.recordsCount.emit([this.members?.length, this.members?.length]);
	}
	protected readonly PasswordStatus = PasswordStatus;

  public onFilter(event) {
    this.filteredTableRecords = event.filteredValue;
  }

	public onSorting(event: SortEvent) {
		event.data.sort((d1, d2) => {
			let v1 = d1[event.multiSortMeta[1].field];
			let v2 = d2[event.multiSortMeta[1].field];
			return event.multiSortMeta[1].order === -1 ? v1.localeCompare(v2) : v2.localeCompare(v1);
		});
	}

	getPasswordStatus(member:MemberTableTempModel){
		return member.passwordSetStatus === PasswordStatus.ACTIVE ? member.passwordSetStatus+ ' until '+ this.datePipe.transform(member.passwordDate,"dd-MMM-yy") : member.passwordDate? member.passwordSetStatus+ ' on '+ this.datePipe.transform(member.passwordDate,"dd-MMM-yy"): member.passwordSetStatus
	}
  editTempId(editableRecord: any){
		this.manageTempService.updateEditTempData(editableRecord);
		this.openEditDialog=true;
    this.manageTempService.openEditTemporaryId(this.openEditDialog);
  }
	onRowSelect(event: TableRowSelectEvent) {

    this.removeTempUser.emit({selectedIds: this.selectedIds});
  }

  // Function to handle row unselection
  onRowUnselect(event: Event) {

    this.removeTempUser.emit({selectedIds: this.selectedIds});
  }
	onTableSelectAll(event: TableSelectAllChangeEvent) {
    this.selectedIds = event.checked ? [...this.members] : [];
    this.removeTempUser.emit({selectedIds: this.selectedIds});
  }

	deleteTempId(){
		this.removeTempUser.emit({selectedIds:this.selectedIds})
	}

    protected readonly EnvConstants = EnvConstants;
}
